import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getCountryListByStatusRequest, getLawCategoryListByStatusRequest, getStateListByCountryRequest } from 'store/actions';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import { withRouter } from "react-router-dom"
import { ACTIVE, CLEAR, SEARCH } from 'constants/commonConstants';
import { Field, Formik } from 'formik';
import __t from 'i18n/translator';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { animatedComponents, MultiValue, Option } from 'helpers/projectUtils';
import Select from "react-select"
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });

const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});

const ComplianceGeneratorHeader = [
    {
        Header: 'Compliance Code',
        accessor: 'compliance_code',
    },
    {
        Header: 'Compliance Title',
        accessor: 'compliance_title',
    },
    {
        Header: 'Company Group',
        accessor: 'company_full_name',
    },
    {
        Header: 'Entity',
        accessor: 'entity_name',
    },
    {
        Header: 'Country',
        accessor: 'country',
    },
    {
        Header: 'State/Province',
        accessor: 'state',
    },
    {
        Header: 'City/Location',
        accessor: 'city',
    },
    {
        Header: 'Short Name of Act/Regulation',
        accessor: 'short_name_of_act_regulation'
    },

    {
        Header: 'Classification',
        accessor: 'classification',
    },
];
class ComplianceDiscoveryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            pageCount: 10,
            totalRecords: 0,
            complianceList: []
        }
    }
    render() {
        const data = {
            columns: ComplianceGeneratorHeader,
            rows: this.state.complianceList,
        };
        return <div>
            <CustomisedCollapseForOne
                className="accordion"
                viewDialog={false}
                dialogName={__t('page_headers.user_filter')}
            >
                <Formik
                    initialValues={{
                        countryId: "",
                        stateId: [],
                        lawCategoryId: [],
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="px-4 pt-4 pb-2">
                            <Row className="filter-form">
                                <React.Fragment>
                                    <FormGroup className="col-lg-3">
                                        <Field
                                            component={Select}
                                            name="countryId"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedCountryListDropdown
                                            }
                                            styles={{
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.value
                                                    : [];
                                                setFieldValue(
                                                    'countryId',
                                                    selectedValues
                                                );
                                                setFieldValue('stateId', []);
                                                setFieldValue('cityId', []);
                                                this.getStateListByCountry(
                                                    [selectedValues]
                                                );
                                            }}
                                            value={this.props.sortedCountryListDropdown?.filter(
                                                (option) =>
                                                    values.countryId == option.value
                                            )}
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            Account Name
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <Field
                                            component={Select}
                                            name="countryId"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedCountryListDropdown
                                            }
                                            styles={{
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.value
                                                    : [];
                                                setFieldValue(
                                                    'countryId',
                                                    selectedValues
                                                );
                                                setFieldValue('stateId', []);
                                                setFieldValue('cityId', []);
                                                this.getStateListByCountry(
                                                    [selectedValues]
                                                );
                                            }}
                                            value={this.props.sortedCountryListDropdown?.filter(
                                                (option) =>
                                                    values.countryId == option.value
                                            )}
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.country'
                                            )}{' '}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <Field
                                            component={Select}
                                            name="countryId"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedCountryListDropdown
                                            }
                                            styles={{
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.value
                                                    : [];
                                                setFieldValue(
                                                    'countryId',
                                                    selectedValues
                                                );
                                                setFieldValue('stateId', []);
                                                setFieldValue('cityId', []);
                                                this.getStateListByCountry(
                                                    [selectedValues]
                                                );
                                            }}
                                            value={this.props.sortedCountryListDropdown?.filter(
                                                (option) =>
                                                    values.countryId == option.value
                                            )}
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            Entity Name
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="stateId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedStateListDropdown?.filter((opt) => opt.value !== -1)
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'stateId',
                                                    selectedValues
                                                );
                                                setFieldValue('cityId', []);
                                                if (selectedValues.length > 0) {
                                                    this.getCityListByState(
                                                        selectedValues
                                                    );
                                                } else {
                                                    this.setState({
                                                        sortedCityListDropdown: []
                                                    })
                                                }
                                            }}
                                            value={this.props.sortedStateListDropdown?.filter(
                                                (option) =>
                                                    values.stateId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="stateId"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.state'
                                            )}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <Field
                                            component={Select}
                                            name="countryId"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedCountryListDropdown
                                            }
                                            styles={{
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.value
                                                    : [];
                                                setFieldValue(
                                                    'countryId',
                                                    selectedValues
                                                );
                                                setFieldValue('stateId', []);
                                                setFieldValue('cityId', []);
                                                this.getStateListByCountry(
                                                    [selectedValues]
                                                );
                                            }}
                                            value={this.props.sortedCountryListDropdown?.filter(
                                                (option) =>
                                                    values.countryId == option.value
                                            )}
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            User
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="lawCategoryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedLawCategoryListDropdown
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'lawCategoryId',
                                                    selectedValues
                                                );
                                                this.getLawsByFilters({
                                                    ...values,
                                                    lawCategoryId:
                                                        selectedValues,
                                                });
                                            }}
                                            value={this.props.sortedLawCategoryListDropdown?.filter(
                                                (option) =>
                                                    values.lawCategoryId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="lawCategoryId"
                                        />
                                        <Label className="form-label">
                                            Practice Area
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-6 d-flex justify-content-end align-items-end">
                                        <div className='d-flex'>
                                            <Button className='mx-1'>{CLEAR}</Button>
                                            <Button className='mx-1' type="button" color="primary" onClick={() => {
                                                this.formSubmit(values, true)
                                            }}>{SEARCH}</Button>
                                        </div>
                                    </FormGroup>
                                </React.Fragment>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </CustomisedCollapseForOne>

            <Row>
                <Col lg="12">
                    <Card className="mt-3">
                        <CardBody className="custom_table table-responsive">
                            <CustomisedReactTableWithPagination
                                className="table table-bordered table-striped"
                                displayEntries={
                                    true
                                }
                                searching={true}
                                currentPage={
                                    this.state.currentPage
                                }

                                pageCount={
                                    this.state.pageCount
                                }
                                totalRecords={this.state.totalRecords}
                                preFilledSearchText={
                                    this.state.searchFormData
                                        ?.searchText
                                }
                                tableData={data}
                            />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </div>
    }
}


const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
});

const withConnect = connect(mapStatetoProps, {
    getStateListByCountryRequest,
    getCountryListByStatusRequest,
    getLawCategoryListByStatusRequest,
});

export default compose(
    withConnect,
    withStateReducer,
    withStateSaga,
    withLawCategorySaga,
    withLawCategoryReducer,
    withCountryReducer,
    withCountrySaga,
    withRouter
)(ComplianceDiscoveryDetails);