import React, { Component } from 'react'
import QuestionnairesFilter from './components/QuestionnairesFilter';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { selectSortedEntityTypeList, selectSortedFacilityTypeList } from 'store/Masters/Law/selector';
import stateReducer from 'store/Masters/State/reducer';
import cityReducer from 'store/Masters/City/reducer';
import industryReducer from 'store/Masters/Industry/reducer';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import { getAllEntityTypeListRequest, getAllFacilityTypeListRequest, getApplicabilityListByStatusRequest, getCityListByStateRequest, getCountryListByStatusRequest, getIndustryListByStatusRequest, getLawCategoryListByStatusRequest, getLawListRequest, getStateListByCountryRequest, getSubApplicabilityListByApplicabilityRequest, getSubIndustryListByIndustryRequest } from 'store/actions';
import { withRouter } from "react-router-dom"
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import stateSaga from 'store/Masters/State/saga';
import citySaga from 'store/Masters/City/saga';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import industrySaga from 'store/Masters/Industry/saga';
import { ACTIVE, DEFAULT_PAGE_SIZE_OPTIONS, SUCCESS } from 'constants/commonConstants';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import { Button, Card, CardHeader, Col, Form, FormGroup, Label, Row, Collapse, CardBody, Spinner } from 'reactstrap';
import { Field, Formik } from 'formik';
import __t from 'i18n/translator';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, animatedComponents, MultiValue } from 'helpers/projectUtils';
import { getQuestionMasterRequest, getSingleQuestionMasterRequest, postQuestionMasterRequest, putQuestionMasterInactiveRequest, putQuestionMasterUpdateRequest } from 'store/Lite/actions';
import { makeApplicabilitySelectField, selectSortedApplicabilityList, selectSortedSubApplicabilityList } from 'store/Masters/Applicability/selector';
import applicabilityReducer from 'store/Masters/Applicability/reducer';
import applicabilitySaga from 'store/Masters/Applicability/saga';
import SimpleReactValidator from 'simple-react-validator';
import { makeLiteClientRegistrationSelectField } from 'store/Lite/selector';
import { toastrMessage } from 'helpers/messageHelper';
import Switch from 'react-switch';
import QuestionAccordion from './components/QuestionAccordian';
import Select from 'react-select';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';


const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });

const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
const applicabilityKey = 'applicability';
const withApplicabilityReducer = injectReducer({
    key: applicabilityKey,
    reducer: applicabilityReducer,
});
const withApplicabilitySaga = injectSaga({ key: applicabilityKey, saga: applicabilitySaga });

const initialValue = {
    id: 1,
    questionText: "",
    isMultipleSelection: 0,
    selectedVersion: null,
    options: [
        {
            optionId: 1,
            answerText: "",
            applicabilityId: [],
            subApplicabilityId: []
        }
    ]
}
class QuestionMaster extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            profileSideMenu: false,
            pageSize: 10,
            isEdit: false,
            removedQuestionOptionIds: [],
            versionChanged: [],
            subApplicabilityListAsPer: [],
            QuestionMasterLoader:true,
            filterValues:null,
            isQuestionLoader:false,
            formikInitialValue: {
                countryId: "",
                stateId: [],
                cityId: [],
                industryId: [],
                subIndustryId: [],
                lawCategoryId: [],
                facilityTypeId: [],
                entityTypeId: [],
                questions: [initialValue]
            },
            pageIndex: 0,
            totalRecords: 0,
            viewDialog: false,
        }
        this.toggle = this.toggle.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    // componentDidMount() {
    //     if (this.props.activeTab == "question_master") {
    //         // this.props.getCountryListByStatusRequest(ACTIVE);
    //         // this.props.getIndustryListByStatusRequest(ACTIVE);
    //         // this.props.getLawCategoryListByStatusRequest(ACTIVE);
    //         // this.props.getAllFacilityTypeListRequest();
    //         // this.props.getAllEntityTypeListRequest();
    //         // this.props.getQuestionMasterRequest({
    //         //     page: this.state.pageIndex + 1,
    //         //     limit: this.state.pageSize
    //         // });
    //         // this.props.getApplicabilityListByStatusRequest(ACTIVE);
    //     }
    // }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.activeTab && this.props.activeTab !== nextProps.activeTab && nextProps.activeTab == "question_master"
        ) {
            this.props.getCountryListByStatusRequest(ACTIVE);
            this.props.getIndustryListByStatusRequest(ACTIVE);
            this.props.getLawCategoryListByStatusRequest(ACTIVE);
            this.props.getAllFacilityTypeListRequest();
            this.props.getAllEntityTypeListRequest();
            this.props.getQuestionMasterRequest({
                page: this.state.pageIndex + 1,
                limit: this.state.pageSize
            });
            this.props.getApplicabilityListByStatusRequest(ACTIVE);
        }
        if (nextProps.addQuestionApiResponse && nextProps.addQuestionApiResponse !== this.props.addQuestionApiResponse) {
            if (nextProps.addQuestionApiResponse.responseType == SUCCESS) {
                this.setState({
                    formikInitialValue: {
                        countryId: "",
                        stateId: [],
                        cityId: [],
                        industryId: [],
                        subIndustryId: [],
                        lawCategoryId: [],
                        facilityTypeId: [],
                        entityTypeId: [],
                        questions: [initialValue]
                    }
                });
                this.toggleProfileSideMenu();
                this.props.getQuestionMasterRequest({
                    page: this.state.pageIndex + 1,
                    limit: this.state.pageSize,
                    filter:this.state.filterValues
                });
            }
            this.setState({
                isQuestionLoader:false
            })
            toastrMessage(nextProps.addQuestionApiResponse.message, nextProps.addQuestionApiResponse.responseType)
        }
        if (nextProps.inActiveQuestionApiResponse && nextProps.inActiveQuestionApiResponse !== this.props.inActiveQuestionApiResponse) {
            this.props.getQuestionMasterRequest({
                page: this.state.pageIndex + 1,
                limit: this.state.pageSize,
                filter:this.state.filterValues
            });
            toastrMessage(nextProps.inActiveQuestionApiResponse.message, nextProps.inActiveQuestionApiResponse.responseType);
        }
        if (nextProps.singleQuestionApiResponse && nextProps.singleQuestionApiResponse !== this.props.singleQuestionApiResponse) {
            const optionArrayObject = nextProps.singleQuestionApiResponse.liteQuestionOptionList && nextProps.singleQuestionApiResponse.liteQuestionOptionList.length > 0 && nextProps.singleQuestionApiResponse.liteQuestionOptionList.map((opt) => {
                return {
                    optionId: opt.id,
                    answerText: opt.answerText,
                    applicabilityId: opt.applicabilityId,
                    subApplicabilityId: opt.liteOptionsAttachmentList && opt.liteOptionsAttachmentList.length > 0 ? opt.liteOptionsAttachmentList.map((element) => element.subApplicabilityId) : []
                }
            });
            const getTheApplicabilityId = optionArrayObject && optionArrayObject.length > 0 && optionArrayObject.map((ele) => {
                return ele.applicabilityId
            }).filter((ele) => ele)

            const optionObject = {
                id: nextProps.singleQuestionApiResponse.id,
                questionText: nextProps.singleQuestionApiResponse.questionText,
                isMultipleSelection: nextProps.singleQuestionApiResponse.isMultipleSelection,
                options: optionArrayObject ? [
                    ...optionArrayObject
                ] : []
            }
            const locationSingleQuestion = nextProps.singleQuestionApiResponse?.liteQuestionPropertyList?.map((element) => {
                if (element.type == 1) {
                    return {
                        stateId: element.stateId,
                        cityId: element.cityId
                    }
                }
                return null
            })?.filter((ele) => ele);
            const SingleQuestionFormikValue = {
                countryId: nextProps.singleQuestionApiResponse?.country.id,
                stateId: nextProps.singleQuestionApiResponse?.liteQuestionPropertyList.map((ele) => ele.type == 1 ? ele.stateId : null).filter((stateId) => stateId),
                cityId: nextProps.singleQuestionApiResponse?.liteQuestionPropertyList.map((ele) => ele.type == 1 ? ele.cityId : null).filter((cityId) => cityId),
                industryId: nextProps.singleQuestionApiResponse?.liteQuestionPropertyList.map((ele) => ele.type == 3 ? ele?.industryId : null).filter((industry) => industry),
                subIndustryId: nextProps.singleQuestionApiResponse?.liteQuestionPropertyList.map((ele) => ele.type == 4 ? ele?.subIndustryId : null).filter((subIndustry) => subIndustry),
                lawCategoryId: nextProps.singleQuestionApiResponse?.liteQuestionPropertyList.map((ele) => ele.type == 2 ? ele.lawCategoryId : null).filter((practiceArea) => practiceArea),
                facilityTypeId: nextProps.singleQuestionApiResponse?.liteQuestionPropertyList.map((ele) => ele.type == 6 ? ele.facilityTypeId : null).filter((facilityId) => facilityId),
                entityTypeId: nextProps.singleQuestionApiResponse?.liteQuestionPropertyList.map((ele) => ele.type == 5 ? ele.entityTypeId : null).filter((entityTypeId) => entityTypeId),
                questions: [
                    optionObject
                ],
                locations: locationSingleQuestion
            }
            if (SingleQuestionFormikValue.countryId) {
                this.getStateListByCountry(
                    [SingleQuestionFormikValue.countryId]
                );
            }
            if (SingleQuestionFormikValue.stateId) {
                this.getCityListByState(
                  [...new Set(SingleQuestionFormikValue.stateId)]    
                );
            }
            if (SingleQuestionFormikValue.industryId) {
                this.props.getSubIndustryListByIndustryRequest(
                    SingleQuestionFormikValue.industryId
                );
            }
            if (getTheApplicabilityId) {
                this.getSubApplicabilityListByApplicability(
                    [...new Set(getTheApplicabilityId)]
                );
                const theApplicabilityId = [...new Set(getTheApplicabilityId)]
                this.setState({
                    applicabilityId: [...getTheApplicabilityId],
                    subApplicabilityListAsPer: theApplicabilityId && theApplicabilityId.length > 0 && theApplicabilityId.map((element) => {
                        return {
                            applicabilityId: element
                        }
                    })
                })

            }
            this.setState({
                isEdit: true,
                formikInitialValue: SingleQuestionFormikValue
            })
        }
        if (nextProps.updateQuestionApiResponse && nextProps.updateQuestionApiResponse !== this.props.updateQuestionApiResponse) {
            if (nextProps.updateQuestionApiResponse.responseType == SUCCESS) {
                this.toggleProfileSideMenu();
                this.setState({
                    removedQuestionOptionIds: []
                })
                this.props.getQuestionMasterRequest({
                    page: this.state.pageIndex + 1,
                    limit: this.state.pageSize,
                    filter:this.state.filterValues
                });
            }
            toastrMessage(nextProps.updateQuestionApiResponse.message, nextProps.updateQuestionApiResponse.responseType);
        }
        if (nextProps.listOfQuestionApiResponse && nextProps.listOfQuestionApiResponse !== this.props.listOfQuestionApiResponse) {
            const manipulateSelectVersion = nextProps.listOfQuestionApiResponse.list && nextProps.listOfQuestionApiResponse.list.length > 0 && nextProps.listOfQuestionApiResponse.list.map((option) => {
                return {
                    id: option?.childQuestionList?.length > 0 ? option?.childQuestionList[0]?.id : option?.id,
                    parentId: option.id,
                    versionNumber: option?.childQuestionList?.length > 0 ? option?.childQuestionList[0]?.versionNumber : option.versionNumber
                }
            });
            this.setState({
                QuestionMasterLoader:false,
                versionChanged: manipulateSelectVersion,
                totalRecords: nextProps.listOfQuestionApiResponse.totalRecords,
            })
        }
        if (nextProps.sortedSubApplicabilityListDropDownList && nextProps.sortedSubApplicabilityListDropDownList !== this.props.sortedSubApplicabilityListDropDownList) {
            let findApplicabilityId = this.state.subApplicabilityListAsPer && this.state.subApplicabilityListAsPer.length > 0 && this.state.subApplicabilityListAsPer.map((element) => {
                if (this.state.applicabilityId.includes(element.applicabilityId)) {
                    return {
                        ...element,
                        subApplicabilityList: nextProps.sortedSubApplicabilityListDropDownList.filter((ele)=>ele.applicabilityId == element.applicabilityId)?.map((opt)=>{
                            return {
                                value: opt.id,
                                label: opt.subApplicabilityName
                            }
                        })
                    }
                } else {
                    return element
                }
            })
            this.setState({
                subApplicabilityListAsPer: findApplicabilityId
            })
        }
    }
    toggleProfileSideMenu() {
        this.setState((prevState) => ({
            profileSideMenu: !prevState.profileSideMenu,
            isEdit: prevState.profileSideMenu ? prevState.profileSideMenu : false,
            subApplicabilityListAsPer: [],
            formikInitialValue: !prevState.profileSideMenu ? {
                countryId: "",
                stateId: [],
                cityId: [],
                industryId: [],
                subIndustryId: [],
                lawCategoryId: [],
                facilityTypeId: [],
                entityTypeId: [],
                questions: [initialValue]
            } : prevState.formikInitialValue
        }));
    }
    getSubApplicabilityListByApplicability(applicabilityId) {
        this.props.getSubApplicabilityListByApplicabilityRequest(applicabilityId);
    }

    addQuestion(values, setFieldValue) {
        const QuestionArray = [...values.questions];
        QuestionArray.push({ ...initialValue, id: (new Date().getTime()).toString(36) });
        setFieldValue("questions", QuestionArray);
    }

    removeQuestion(id, values, setFieldValue) {
        const QuestionDuplicate = [...values.questions];
        const filterQuestion = QuestionDuplicate && QuestionDuplicate.length > 0 && QuestionDuplicate.filter((element) => element.id !== id);
        setFieldValue("questions", filterQuestion)
    }
    addOption(parentId, values, setFieldValue) {
        const QuestionDuplicate = [...values.questions];
        const ManipulateQuestion = QuestionDuplicate && QuestionDuplicate.length > 0 && QuestionDuplicate.map((question) => {
            if (question.id == parentId) {
                const OptionDuplicate = [...question.options];
                OptionDuplicate.push({
                    optionId: (new Date().getTime()).toString(36),
                    answerText: "",
                    applicability: [],
                    subApplicabilityId: []
                });
                return {
                    ...question,
                    options: OptionDuplicate
                }
            } else {
                return question
            }
        });
        setFieldValue("questions", ManipulateQuestion)

    }

    toggle = () => {
        this.setState({
            viewDialog: !this.state.viewDialog,
        })
    }
    DeleteOption(parentId, optionId, values, setFieldValue) {
        const QuestionDuplicate = [...values.questions];
        const ManipulateQuestion = QuestionDuplicate && QuestionDuplicate.length > 0 && QuestionDuplicate.map((question) => {
            if (question.id == parentId) {
                const FilterOption = question?.options && question?.options?.length > 0 ? question.options.filter((option) => option.optionId != optionId) : [];
                return {
                    ...question,
                    options: FilterOption
                }
            } else {
                return question
            }
        });
        if (this.state.isEdit) {
            const removeOptionsIds = [...this.state.removedQuestionOptionIds];
            removeOptionsIds.push(optionId)
            this.setState({
                removedQuestionOptionIds: removeOptionsIds
            })
        }
        setFieldValue("questions", ManipulateQuestion)
    }
    formSubmit(values, isDraft) {
        this.setState({
            isQuestionLoader:true
        })
        if (this.validator.allValid()) {
            if (this.state.isEdit) {
                values = {
                    ...values,
                    isDraft: isDraft ? 1 : 0,
                    question: values?.questions[0] && {
                        ...values?.questions[0],
                        options: values?.questions[0]?.options?.map((element) => {
                            if (typeof element.optionId == "string") {
                                delete element.optionId;
                            }
                            const optionManipulate = {
                                ...element,
                                id: element.optionId,
                            }
                            delete optionManipulate.optionId
                            return optionManipulate
                        })
                    }
                }
                delete values.cityId;
                delete values.stateId;
                delete values.questions;
                this.props.putQuestionMasterUpdateRequest({ ...values, id: values.question.id, removedQuestionOptionIds: this.state.removedQuestionOptionIds })
            } else {
                values = {
                    ...values,
                    isDraft: isDraft ? 1 : 0
                }
                delete values.cityId;
                delete values.stateId;
                this.props.postQuestionMasterRequest(values)
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    getStateListByCountry(countryId) {
        this.props.getStateListByCountryRequest(countryId);
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest(stateId);
    }
    getSubIndustryListByIndustry(industryId) {
        this.props.getSubIndustryListByIndustryRequest(industryId);
    }
    onStatusChange(checked, id) {
        this.props.putQuestionMasterInactiveRequest(
            {
                questionId: id,
                status: checked,
            }
        );
    }
    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    getTotalPages() {
        return Math.ceil(this.state.totalRecords / this.state.pageSize);
    }
    previousPage = () => {
        if (this.state.pageIndex > 0) {
            this.setState(
                prevState => ({ pageIndex: prevState.pageIndex - 1,QuestionMasterLoader:true }),
            );
        }
    };
    nextPage = () => {
        if (this.state.pageIndex < this.getTotalPages() - 1) {
            this.setState(
                prevState => ({ pageIndex: prevState.pageIndex + 1,QuestionMasterLoader:true }),
            );
        }
    };
    gotoPage = (pageIndex) => {
        this.setState(
            { pageIndex,QuestionMasterLoader:true },
            () => {
                this.props.getQuestionMasterRequest({
                    page: this.state.pageIndex + 1,
                    limit: this.state.pageSize,
                    filter:this.state.filterValues
                });
            }
        );
    };
    render() {

        const { pageIndex } = this.state;
        const totalPages = this.getTotalPages();
        const pageOptions = Array.from({ length: totalPages }, (_, index) => index); // Array of page indices
        const bottomLimit = pageIndex * this.state.pageSize + 1;
        const topLimit = Math.min((pageIndex + 1) * this.state.pageSize, this.state.totalRecords);
        return <div className='d-flex gap-4 flex-column'>
            <CustomisedCollapseForOne
                className="accordion"
                viewDialog={false}
                dialogName={__t('page_headers.user_filter')}
            >
                <QuestionnairesFilter
                    sortedCountryListDropdown={this.props.sortedCountryListDropdown}
                    sortedStateListDropdown={this.props.sortedStateListDropdown}
                    sortedCityListDropdown={this.props.sortedCityListDropdown}
                    sortedIndustryListDropdown={this.props.sortedIndustryListDropdown}
                    sortedSubIndustryListDropdown={this.props.sortedSubIndustryListDropdown}
                    sortedEntityTypeListDropDown={this.props.sortedEntityTypeListDropDown}
                    sortedFacilityTypeListDropDown={this.props.sortedFacilityTypeListDropDown}
                    sortedLawCategoryListDropdown={this.props.sortedLawCategoryListDropdown}
                    getStateListByCountryRequest={this.props.getStateListByCountryRequest}
                    getCityListByStateRequest={this.props.getCityListByStateRequest}
                    getSubIndustryListByIndustryRequest={this.props.getSubIndustryListByIndustryRequest}
                    handleReset={() => {
                        this.props.getQuestionMasterRequest({
                            page: this.state.pageIndex + 1,
                            limit: this.state.pageSize,
                        })
                    }}
                    FilterFormSubmit={(values) => {
                        this.setState({
                            filterValues:values
                        })
                        this.props.getQuestionMasterRequest({
                            page: this.state.pageIndex + 1,
                            limit: this.state.pageSize,
                            filter: {
                                ...values
                            }
                        });
                    }}
                />
            </CustomisedCollapseForOne>
            <Row className='question-accordion'>
                <Col lg="12">
                    <Card className="mb-2">
                        <CardHeader>
                            <div className="page-header">
                                <h4>{__t("lite.question_master_module.heading")}</h4>
                            </div>
                            <div className="page-action">
                                <Button color='primary' size='sm' onClick={() => this.toggleProfileSideMenu()}><i className='fas fa-plus'></i>{__t("lite.question_master_module.add_question_btn")}</Button>
                            </div>
                        </CardHeader>
                        <CardBody className='custom_table'>
                            {
                                this.props.listOfQuestionApiResponse?.list && this.props.listOfQuestionApiResponse.list?.length > 0 && <Row className='pb-3 d-flex align-items-center'>
                                    <Col lg="6">
                                        {"Show entries"}
                                        <select
                                            className='ml-2'
                                            value={this.state.pageSize}
                                            onChange={(e) => {
                                                this.setState({
                                                    pageSize: Number(e.target.value),
                                                    QuestionMasterLoader:true
                                                },()=>{
                                                    this.props.getQuestionMasterRequest({
                                                        page: 1,
                                                        limit: this.state.pageSize,
                                                        filter:this.state.filterValues
                                                    });
                                                })
                                            }}
                                        >
                                            {DEFAULT_PAGE_SIZE_OPTIONS.map((pageSize) => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                </Row>
                            }

                            {
                                this.state.QuestionMasterLoader ? <div className='d-flex justify-content-center w-100'><Spinner /></div> :this.props.listOfQuestionApiResponse?.list && this.props.listOfQuestionApiResponse.list?.length > 0 && this.props.listOfQuestionApiResponse?.list.map((question, index) => {
                                    const findTheVersionNumber = this.state.versionChanged && this.state.versionChanged.length > 0 && this.state.versionChanged.find((ele) => ele.parentId == question.id);
                                    const childQuestion = findTheVersionNumber ? findTheVersionNumber.parentId == findTheVersionNumber.id ? question : question.childQuestionList.find((ele) => ele.id == findTheVersionNumber.id) : question?.childQuestionList[0] ? question?.childQuestionList[0] : question;
                                    const createVersion = question.childQuestionList && question.childQuestionList.length > 0 ? question.childQuestionList.map((ele) => {
                                        return {
                                            label: `V${ele.versionNumber}`,
                                            value: ele.id,
                                            parentId: question.id
                                        }
                                    }) : [];
                                    createVersion.push({
                                        label: `V${question.versionNumber}`,
                                        value: question.id,
                                        parentId: question.id
                                    });
                                    return <div key={index} className='question-item'>
                                        
                                        <QuestionAccordion
                                            className="accordion"
                                            viewDialog={false}
                                            dialogName={childQuestion?.questionText}>
                                            <div>

                                                <div className='input-group'>
                                                    {childQuestion?.liteQuestionOptionList && childQuestion?.liteQuestionOptionList.length > 0 && childQuestion?.liteQuestionOptionList?.map((option, optionIndex) => {
                                                        return <div className='' key={optionIndex}>
                                                            <input className="cl-custom-check" id={option.answerText} type="checkbox" disabled={true} />
                                                            <label className="cl-custom-check-label" htmlFor={option.answerText} title={option.answerText}>{option.answerText}</label>
                                                            {/* {option.answerText} */}
                                                        </div>
                                                    })
                                                    }
                                                </div>
                                                <p className='verison-info'>{__t("lite.question_master_module.version")}: {childQuestion?.versionNumber}
                                                    {/* <span className='text-danger'>{childQuestion?.isCurrentVersion}</span> */}
                                                </p>
                                            </div>
                                        </QuestionAccordion>
                                        <div className='question-action'>
                                            <Select
                                                name="version"
                                                className="form-control selectbox"
                                                options={createVersion}
                                                menuPortalTarget={document.body}
                                                onChange={(opt) => {
                                                    const manipulateSelectedVersion = this.state.versionChanged && this.state.versionChanged.length > 0 && this.state.versionChanged.map((option) => {
                                                        if (option.parentId == opt.parentId) {
                                                            return {
                                                                id: opt.value,
                                                                versionNumber: opt.label.split("V")[1],
                                                                parentId: opt.parentId
                                                            }
                                                        } else {
                                                            return option
                                                        }
                                                    })
                                                    this.setState({
                                                        versionChanged: manipulateSelectedVersion
                                                    })
                                                }}
                                                // style={{
                                                //     menu: (provided) => ({
                                                //         ...provided,
                                                //         zIndex: 9999, // Ensure the dropdown appears above other elements
                                                //     }),
                                                // }}
                                                value={createVersion && createVersion.length > 0 && createVersion.filter((ele) => {
                                                    const findSelectedVersion = this.state.versionChanged.find((element) => element.id == ele.value);
                                                    return findSelectedVersion
                                                })}
                                                closeMenuOnSelect={true}
                                                isClearable={true}
                                            />
                                            <Switch
                                                className="switch-sm"
                                                onColor="#9BD732"
                                                id={String(childQuestion?.id)}
                                                onChange={(checked) => this.onStatusChange(checked, childQuestion?.id)}
                                                checked={childQuestion?.isActive ? true : false}
                                            />
                                            {
                                                childQuestion.isEditable ? childQuestion.isDraft ?
                                                    <Button color="orange" onClick={() => {
                                                        this.props.getSingleQuestionMasterRequest({
                                                            question_id: childQuestion.id
                                                        })
                                                        this.toggleProfileSideMenu();
                                                    }} type='button' className='bg-orange rounded-circle' title='Saved As Draft'>
                                                        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.72729 12.4371C2.72729 12.1547 2.95624 11.9258 3.23866 11.9258H8.6932C8.97561 11.9258 9.20457 12.1547 9.20457 12.4371C9.20457 12.7196 8.97561 12.9485 8.6932 12.9485H3.23866C2.95624 12.9485 2.72729 12.7196 2.72729 12.4371Z" fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.72729 14.1442C2.72729 13.8617 2.95624 13.6328 3.23866 13.6328C3.52107 13.6328 6.98866 13.6328 6.98866 13.6328C7.27107 13.6328 7.50002 13.8617 7.50002 14.1442C7.50002 14.4266 7.27107 14.6555 6.98866 14.6555H3.23866C2.95624 14.6555 2.72729 14.4266 2.72729 14.1442Z" fill="white" />
                                                            <path d="M14.3179 6.76101L14.318 9.90603C14.318 13.2808 14.318 14.9683 13.3195 16.0167C12.321 17.0651 10.7139 17.0651 7.49982 17.0651C4.28569 17.0651 2.67863 17.0651 1.68014 16.0167C0.681633 14.9683 0.681641 12.2324 0.681641 8.8576C0.681641 5.48277 0.681563 2.77263 1.68006 1.72421C2.67856 0.675781 4.28562 0.675781 7.49975 0.675781H8.52248" stroke="white" stroke-width="1.02273" stroke-linecap="round" />
                                                            <path d="M10.6718 1.66787L11.1142 1.22549C11.8472 0.492545 13.0355 0.492545 13.7685 1.22549C14.5014 1.95845 14.5014 3.14679 13.7685 3.87974L13.326 4.32211M10.6718 1.66787C10.6718 1.66787 10.7271 2.60791 11.5566 3.43736C12.386 4.26682 13.326 4.32211 13.326 4.32211M10.6718 1.66787L6.60487 5.73483C6.32941 6.01029 6.19168 6.14802 6.07323 6.29988C5.93349 6.47903 5.8137 6.67286 5.71596 6.87794C5.6331 7.0518 5.5715 7.23658 5.44831 7.60619L5.0539 8.78942M13.326 4.32211L9.25914 8.38905C8.98368 8.66451 8.84596 8.80224 8.69405 8.92074C8.51493 9.06044 8.32109 9.18024 8.116 9.27801C7.94214 9.36085 7.75737 9.42242 7.38775 9.54562L6.20452 9.94005M5.0539 8.78942L4.79869 9.55503C4.73807 9.73694 4.7854 9.9374 4.92096 10.073C5.05652 10.2086 5.25704 10.2559 5.4389 10.1953L6.20452 9.94005M5.0539 8.78942L6.20452 9.94005" stroke="white" stroke-width="1.02273" />
                                                        </svg>
                                                    </Button> : <Button color="primary" onClick={() => {
                                                        this.props.getSingleQuestionMasterRequest({
                                                            question_id: childQuestion.id
                                                        })
                                                        this.toggleProfileSideMenu();
                                                    }} type='button' className='rounded-circle' title='Edit'>
                                                        <i className='fas fa-pencil-alt m-0'></i>
                                                    </Button> : null
                                            }
                                        </div>
                                    </div>
                                })
                            }
                            {
                                this.props.listOfQuestionApiResponse?.list && this.props.listOfQuestionApiResponse.list?.length > 0 && <Row className='pt-3 d-flex align-items-center'>
                                    <Col lg="6">
                                        {"Showing " + bottomLimit + " to " + topLimit + " of " + this.state.totalRecords + " entries"}
                                    </Col>
                                    <Col lg="6">
                                        <ul className='pagination float-right m-0'>
                                            <li
                                                key="pre"
                                                onClick={this.previousPage}
                                                className={pageIndex === 0 ? "disabled page-item" : "page-item"}
                                            >
                                                <p aria-label="Previous" className="page-link">
                                                    <span>{__t("lite.question_master_module.previous")}</span>
                                                </p>
                                            </li>

                                            {totalPages > 5 ? (
                                                <>
                                                    {pageIndex >= 4 && (
                                                        <li
                                                            onClick={() => this.gotoPage(0)}
                                                            className="page-item"
                                                        >
                                                            <p className="page-link">...</p>
                                                        </li>
                                                    )}
                                                    {pageOptions.map((pageKey) => {
                                                        const pageNumber = pageKey + 1;
                                                        return (
                                                            pageKey >= pageIndex - 2 && pageKey <= pageIndex + 2 && (
                                                                <li
                                                                    key={pageKey}
                                                                    onClick={() => this.gotoPage(pageKey)}
                                                                    className={pageKey === pageIndex ? "active page-item" : "page-item"}
                                                                >
                                                                    <p className="page-link">{pageNumber}</p>
                                                                </li>
                                                            )
                                                        );
                                                    })}
                                                    {pageIndex < totalPages - 5 && (
                                                        <li
                                                            onClick={() => this.gotoPage(totalPages - 1)}
                                                            className="page-item"
                                                        >
                                                            <p className="page-link">...</p>
                                                        </li>
                                                    )}
                                                </>
                                            ) : (
                                                pageOptions.map((pageKey) => (
                                                    <li
                                                        key={pageKey}
                                                        onClick={() => this.gotoPage(pageKey)}
                                                        className={pageKey === pageIndex ? "active page-item" : "page-item"}
                                                    >
                                                        <p className="page-link">{pageKey + 1}</p>
                                                    </li>
                                                ))
                                            )}

                                            <li
                                                key="next"
                                                onClick={this.nextPage}
                                                className={pageIndex === totalPages - 1 ? "disabled page-item" : "page-item"}
                                            >
                                                <p aria-label="Next" className="page-link">
                                                    <span>{__t("lite.question_master_module.next")}</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <CustomisedSideModal
                modalSize={
                    'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider question_master_modal'
                }
                modalName={this.state.isEdit ? 'Edit Questions' : 'Add New Questions'}
                isModalOpen={this.state.profileSideMenu}
                onModalDismiss={() => {
                    this.toggleProfileSideMenu();
                }}
            >
                <Formik initialValues={this.state.formikInitialValue} enableReinitialize={true}>
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row className="filter-form px-1 pt-4 pb-2 mx-0">
                                <React.Fragment>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="countryId"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedCountryListDropdown
                                            }
                                            styles={{
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.value
                                                    : [];
                                                setFieldValue(
                                                    'countryId',
                                                    selectedValues
                                                );
                                                setFieldValue('stateId', []);
                                                setFieldValue('cityId', []);
                                                setFieldValue("locations", [])
                                                this.getStateListByCountry(
                                                    [selectedValues]
                                                );
                                            }}
                                            value={this.props.sortedCountryListDropdown?.filter(
                                                (option) =>
                                                    values.countryId == option.value
                                            )}
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'lite.entity.edit.country'
                                            )}{' '}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.allocation.country'
                                            ),
                                            values.countryId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.allocation.country_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="stateId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedStateListDropdown?.filter((opt) => opt.value !== -1)
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'stateId',
                                                    selectedValues
                                                );
                                                setFieldValue('cityId', []);
                                                if (selectedValues.length > 0) {
                                                    this.getCityListByState(
                                                        selectedValues
                                                    );
                                                } else {
                                                    this.setState({
                                                        sortedCityListDropdown: []
                                                    })
                                                }
                                            }}
                                            value={this.props.sortedStateListDropdown?.filter(
                                                (option) =>
                                                    values.stateId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="stateId"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.state'
                                            )}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t('form_labels.masters.state'),
                                            values.stateId,
                                            'required', {
                                            messages: {
                                                required: __t('validations.masters.state_required')
                                            }
                                        })}
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="cityId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedCityListDropdown?.filter((opt) => opt.value !== -1)
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'cityId',
                                                    selectedValues
                                                );
                                                let StateCityArray = values.locations.length > 0 ? [...values.locations] : [];
                                                StateCityArray = opt ? opt.map((x) => {
                                                    return {
                                                        stateId: x.stateId,
                                                        cityId: x.value
                                                    }
                                                }) : [];
                                                setFieldValue("locations", StateCityArray)
                                            }}
                                            value={this.props.sortedCityListDropdown?.filter(
                                                (option) =>
                                                    values.cityId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="cityId"
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.city')}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.entity.city'
                                            ),
                                            values.cityId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.entity.city_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="industryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedIndustryListDropdown?.filter((opt) => opt.value !== -1)
                                            }
                                            onChange={(opt) => {
                                                let industry = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'industryId',
                                                    industry
                                                );
                                                setFieldValue(
                                                    'subIndustryId',
                                                    []
                                                );
                                                if (industry.length > 0) {
                                                    this.props.getSubIndustryListByIndustryRequest(
                                                        industry
                                                    );
                                                } else {
                                                    this.setState({
                                                        sortedSubIndustryListDropdown: []
                                                    })
                                                }
                                            }}
                                            value={this.props.sortedIndustryListDropdown?.filter(
                                                (option) =>
                                                    values.industryId.includes(option.value)
                                            )}
                                            isClearable={true}
                                            isMulti
                                            allowSelectAll={true}
                                            closeMenuOnSelect={false}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.statutory.industry')}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.laws.industry'
                                            ),
                                            values.industryId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.laws.industry_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="subIndustryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedSubIndustryListDropdown
                                            }
                                            onChange={(opt) => {
                                                let subIndustry = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'subIndustryId',
                                                    subIndustry
                                                );
                                            }}
                                            value={this.props.sortedSubIndustryListDropdown?.filter(
                                                (option) =>
                                                    values.subIndustryId.includes(option.value)
                                            )}
                                            isClearable={true}
                                            isMulti
                                            allowSelectAll={true}
                                            closeMenuOnSelect={false}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.statutory.sub_industry')}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.laws.sub_industry'
                                            ),
                                            values.subIndustryId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.laws.sub_industry_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="lawCategoryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedLawCategoryListDropdown
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'lawCategoryId',
                                                    selectedValues
                                                );
                                            }}
                                            value={this.props.sortedLawCategoryListDropdown?.filter(
                                                (option) =>
                                                    values.lawCategoryId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="lawCategoryId"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.law_category'
                                            )}{' '}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.laws.law_category'
                                            ),
                                            values.lawCategoryId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.laws.law_category_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className='col-lg-4'>
                                        <Field
                                            component={CustomMultiSelect}
                                            name="facilityTypeId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedFacilityTypeListDropDown?.filter((opt) => opt.value !== -1)
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'facilityTypeId',
                                                    selectedValues
                                                );
                                            }}
                                            value={this.props.sortedFacilityTypeListDropDown?.filter(
                                                option => values.facilityTypeId?.includes(option.value)
                                            )}
                                            isMulti
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.laws.facility_types'
                                            )}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.laws.facility_types'
                                            ),
                                            values.facilityTypeId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.laws.facility_types_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={
                                                CustomMultiSelect
                                            }
                                            name="entityTypeId[]"
                                            className="form-control selectbox"
                                            options={this.props.sortedEntityTypeListDropDown?.filter((opt) => opt.value !== -1)}
                                            onChange={(opt) => {
                                                let entityTypeId =
                                                    opt
                                                        ? opt.map(
                                                            (x) =>
                                                                x.value
                                                        )
                                                        : [];
                                                setFieldValue(
                                                    'entityTypeId',
                                                    entityTypeId
                                                );
                                            }}
                                            value={this.props.sortedEntityTypeListDropDown?.filter(
                                                (option) =>
                                                    values.entityTypeId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={
                                                false
                                            }
                                            hideSelectedOptions={
                                                false
                                            }
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.laws.entity_types'
                                            )}{' '}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.laws.entity_types'
                                            ),
                                            values.entityTypeId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.laws.entity_types_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                </React.Fragment>
                            </Row>
                            <div className='question-wrapper'>
                                {values.questions && values.questions.length > 0 && values.questions.map((element, ind) => {

                                    return <div className='question-container' key={ind}>
                                        <div className='d-flex'>
                                            <FormGroup className='position-relative question-input'>
                                                <Field
                                                    name={`questions.${ind}.questionText`}
                                                    className="form-control"
                                                    placeholder="Enter Question"
                                                />
                                                <Label className="form-label">
                                                    Question
                                                    <span className="text-danger required">*</span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'lite.question.question_text'
                                                    ),
                                                    values.questions[ind].questionText,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'lite.question.question_text'
                                                            ),
                                                        },
                                                    }
                                                )}
                                                {!this.state.isEdit && values.questions && values.questions.length > 1  ? <Button color='link' className='text-danger p-0 delete-btn' onClick={() => this.removeQuestion(element.id, values, setFieldValue)}><i className="fas fa-trash-alt m-0"></i></Button> : null}
                                            </FormGroup>
                                            <FormGroup className='multi-selection-input'>
                                                <Field
                                                    type="checkbox"
                                                    id={
                                                        `questions.${ind}.isMultipleSelection`
                                                    }
                                                    className="form-check-input"
                                                    name={
                                                        `questions.${ind}.isMultipleSelection`
                                                    }
                                                    onChange={(
                                                        e
                                                    ) => {
                                                        setFieldValue(`questions.${ind}.isMultipleSelection`, e.target.checked ? 1 : 0)
                                                    }}
                                                    checked={values.questions[ind].isMultipleSelection == 1 ? true : false}
                                                />
                                                <Label
                                                    className="form-check-label m-0"
                                                    for={`questions.${ind}.isMultipleSelection`}
                                                >
                                                    {__t(
                                                        'lite.isMultipleSelection'
                                                    )}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                        <div className='question-options'>
                                            {
                                                element.options && element.options.length > 0 && element.options.map((option, index) => {
                                                    return <div className='d-flex row' key={index}>
                                                        <FormGroup className='col pr-2'>
                                                            <Field
                                                                name={`questions.${ind}.options.${index}.answerText`}
                                                                className="form-control"
                                                                placeholder="Enter Option"
                                                            />
                                                            <Label className="form-label">
                                                                Option
                                                                <span className="text-danger required">*</span>
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'lite.question.option_text'
                                                                ),
                                                                values.questions[ind].options[index].answerText,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required: __t(
                                                                            'lite.question.option_text'
                                                                        ),
                                                                    },
                                                                }
                                                            )}

                                                        </FormGroup>
                                                        <FormGroup className="col-lg-3 px-2">
                                                            <Field
                                                                component={
                                                                    Select
                                                                }
                                                                name={`questions.${ind}.options.${index}.applicabilityId`}
                                                                className="form-control selectbox"
                                                                options={this.props.sortedApplicabilityListDropDown}
                                                                onChange={(opt) => {
                                                                    let applicabilityId =
                                                                        opt
                                                                            ? opt.value
                                                                            : [];
                                                                    setFieldValue(
                                                                        `questions.${ind}.options.${index}.applicabilityId`,
                                                                        applicabilityId
                                                                    );
                                                                    const findAlreadyExist = this.state.subApplicabilityListAsPer && this.state.subApplicabilityListAsPer.length > 0 && this.state.subApplicabilityListAsPer.find((ele) => ele.applicabilityId == applicabilityId);
                                                                    const createSubApplicability = this.state.subApplicabilityListAsPer ? [...this.state.subApplicabilityListAsPer] : [];
                                                                    if (!findAlreadyExist) {
                                                                        createSubApplicability.push({
                                                                            applicabilityId,
                                                                        })
                                                                        const stateApplicabilityId = this.state.subApplicabilityListAsPer ? [...this.state.subApplicabilityListAsPer] : [];
                                                                        if (!stateApplicabilityId.find((ele) => ele.applicabilityId == applicabilityId)) {
                                                                            stateApplicabilityId.push(applicabilityId);
                                                                        }
                                                                        this.setState({
                                                                            subApplicabilityListAsPer: createSubApplicability,
                                                                            applicabilityId: stateApplicabilityId
                                                                        })
                                                                        this.getSubApplicabilityListByApplicability(
                                                                            [applicabilityId]
                                                                        );
                                                                    }
                                                                    setFieldValue(`questions.${ind}.options.${index}.subApplicabilityId`, [])
                                                                }}
                                                                value={this.props.sortedApplicabilityListDropDown?.filter(
                                                                    (opt) =>
                                                                        option.applicabilityId ==
                                                                        opt.value
                                                                )}
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.laws.applicabilities'
                                                                )}{' '}
                                                                <span className="text-danger required">*</span>
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'lite.question.applicability'
                                                                ),
                                                                values.questions[ind].options[index].applicabilityId,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required: __t(
                                                                            'lite.question.applicability'
                                                                        ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup className="col-lg-3 px-2">
                                                            <Field
                                                                component={
                                                                    CustomMultiSelect
                                                                }
                                                                name={`questions.${ind}.options.${index}.subApplicabilityId`}
                                                                className="form-control selectbox"
                                                                options={this.state.subApplicabilityListAsPer ? this.state.subApplicabilityListAsPer?.find((ele) => ele.applicabilityId == option.applicabilityId)?.subApplicabilityList ?? [] : []}
                                                                onChange={(opt) => {
                                                                    let subApplicabilityId =
                                                                        opt
                                                                            ? opt.map(
                                                                                (x) =>
                                                                                    x.value
                                                                            )
                                                                            : [];
                                                                    setFieldValue(
                                                                        `questions.${ind}.options.${index}.subApplicabilityId`,
                                                                        subApplicabilityId
                                                                    );
                                                                }}
                                                                value={this.state.subApplicabilityListAsPer && this.state.subApplicabilityListAsPer.length > 0 ? this.state.subApplicabilityListAsPer?.find((ele) => ele.applicabilityId == option.applicabilityId)?.subApplicabilityList?.filter((opt) => {
                                                                    return element.options[index].subApplicabilityId?.includes(
                                                                        opt.value
                                                                    )
                                                                }) : null}
                                                                isMulti
                                                                closeMenuOnSelect={
                                                                    false
                                                                }
                                                                hideSelectedOptions={
                                                                    false
                                                                }
                                                                allowSelectAll={true}
                                                                components={{
                                                                    Option,
                                                                    MultiValue,
                                                                    animatedComponents,
                                                                }}
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.laws.sub_applicabilities'
                                                                )}{' '}
                                                                <span className="text-danger required">*</span>
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'lite.question.subApplicability'
                                                                ),
                                                                values.questions[ind].options[index].subApplicabilityId,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required: __t(
                                                                            'lite.question.subApplicability'
                                                                        ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                        <div className='pl-2 pr-3'>
                                                            {element.options.length <= 1 ? null : <Button type='button' color='danger' className='mr-2' onClick={() => this.DeleteOption(element.id, option.optionId, values, setFieldValue)}><i className='fas fa-minus m-0'></i></Button>
                                                            }
                                                            {index == (element.options.length - 1) ? <Button type='button' color='primary' onClick={() => this.addOption(element.id, values, setFieldValue)}><i className='fas fa-plus m-0'></i></Button>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                                }
                            </div>
                            <div className='d-flex justify-content-between question-submit px-3 mb-3'>
                                {!this.state.isEdit ? <div>
                                    <Button color='link' className='text-primary px-0' onClick={() => this.addQuestion(values, setFieldValue)}><i className='fas fa-plus'></i>{__t("lite.question_master_module.add_new_question")}</Button>
                                </div> : null}
                                <div className='d-flex'>
                                    <Button color='secondary' className='mx-1' type="reset" onClick={() => this.toggleProfileSideMenu()}>Cancel</Button>
                                    <Button color='primary' outline className='mx-1' type="button" disabled={this.state.isQuestionLoader} onClick={() => {
                                        this.formSubmit(values, true)
                                    }}>{__t("lite.question_master_module.save_as_draft")}</Button>

                                    <Button color='primary' type="button" disabled={this.state.isQuestionLoader} onClick={() => {
                                        this.formSubmit(values, false)
                                    }} className="ml-1">{this.state.isEdit ? __t("lite.question_master_module.update") : __t("lite.question_master_module.submit")}</Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CustomisedSideModal>
            {/* {
                this.props.listOfQuestionApiResponse?.list && this.props.listOfQuestionApiResponse.list?.length > 0 && this.props.listOfQuestionApiResponse?.list.map((question, index) => {
                    const findTheVersionNumber = this.state.versionChanged && this.state.versionChanged.length > 0 && this.state.versionChanged.find((ele) => ele.parentId == question.id);
                    const childQuestion = findTheVersionNumber ? findTheVersionNumber.parentId == findTheVersionNumber.id ? question : question.childQuestionList.find((ele) => ele.id == findTheVersionNumber.id) : question?.childQuestionList[0] ? question?.childQuestionList[0] : question;
                    const createVersion = question.childQuestionList && question.childQuestionList.length > 0 ? question.childQuestionList.map((ele) => {
                        return {
                            label: `V${ele.versionNumber}`,
                            value: ele.id,
                            parentId: question.id
                        }
                    }) : [];
                    createVersion.push({
                        label: `V${question.versionNumber}`,
                        value: question.id,
                        parentId: question.id
                    });
                    return <div key={index} className='d-flex flex-column'>
                        <QuestionAccordion
                            className="accordion"
                            viewDialog={false}
                            dialogName={childQuestion?.questionText}>
                            <div>
                                <Select
                                    name="version"
                                    className="form-control selectbox"
                                    options={createVersion}
                                    onChange={(opt) => {
                                        const manipulateSelectedVersion = this.state.versionChanged && this.state.versionChanged.length > 0 && this.state.versionChanged.map((option) => {
                                            if (option.parentId == opt.parentId) {
                                                return {
                                                    id: opt.value,
                                                    versionNumber: opt.label.split("V")[1],
                                                    parentId: opt.parentId
                                                }
                                            } else {
                                                return option
                                            }
                                        })
                                        this.setState({
                                            versionChanged: manipulateSelectedVersion
                                        })
                                    }}
                                    value={createVersion && createVersion.length > 0 && createVersion.filter((ele) => {
                                        const findSelectedVersion = this.state.versionChanged.find((element) => element.id == ele.value);
                                        return findSelectedVersion
                                    })}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                />
                                <p>V{childQuestion?.versionNumber}</p>
                                <p className='text-danger'>{childQuestion?.isCurrentVersion}</p>
                                {
                                    childQuestion?.liteQuestionOptionList && childQuestion?.liteQuestionOptionList.length > 0 && childQuestion?.liteQuestionOptionList?.map((option, optionIndex) => {
                                        return <div key={optionIndex}>
                                            {option.answerText}
                                        </div>
                                    })
                                }
                                <Button onClick={() => {
                                    this.props.getSingleQuestionMasterRequest({
                                        question_id: childQuestion.id
                                    })
                                    this.toggleProfileSideMenu();
                                }} color='link' type='button' className='text-success p-1' title='Edit'>
                                    <i className='fas fa-pencil-alt'></i>
                                </Button>
                                <Switch
                                    className="switch-sm"
                                    onColor="#9BD732"
                                    id={String(childQuestion?.id)}
                                    onChange={(checked) => this.onStatusChange(checked, childQuestion?.id)}
                                    checked={childQuestion?.isActive ? true : false}
                                />
                            </div>
                        </QuestionAccordion>
                    </div>
                })
            } */}
        </div >
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedEntityTypeListDropDown: selectSortedEntityTypeList(),
    sortedFacilityTypeListDropDown: selectSortedFacilityTypeList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    sortedApplicabilityListDropDown: selectSortedApplicabilityList(),
    sortedSubApplicabilityListDropDown: selectSortedSubApplicabilityList(),
    sortedSubApplicabilityListDropDownList: makeApplicabilitySelectField('subApplicabilityList'),
    addQuestionApiResponse: makeLiteClientRegistrationSelectField("addQuestionApiResponse"),
    listOfQuestionApiResponse: makeLiteClientRegistrationSelectField("listOfQuestionApiResponse"),
    inActiveQuestionApiResponse: makeLiteClientRegistrationSelectField("inActiveQuestionApiResponse"),
    singleQuestionApiResponse: makeLiteClientRegistrationSelectField("singleQuestionApiResponse"),
    updateQuestionApiResponse: makeLiteClientRegistrationSelectField("updateQuestionApiResponse")
});

const withConnect = connect(mapStatetoProps, {
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getAllEntityTypeListRequest,
    getAllFacilityTypeListRequest,
    getCountryListByStatusRequest,
    getLawCategoryListByStatusRequest,
    getQuestionMasterRequest,
    getApplicabilityListByStatusRequest,
    getSubApplicabilityListByApplicabilityRequest,
    postQuestionMasterRequest,
    getLawListRequest,
    putQuestionMasterInactiveRequest,
    getSingleQuestionMasterRequest,
    putQuestionMasterUpdateRequest
});

export default compose(
    withConnect,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withLawCategorySaga,
    withCitySaga,
    withLawCategoryReducer,
    withIndustryReducer,
    withIndustrySaga,
    withLawReducer,
    withLawSaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withCountryReducer,
    withCountrySaga,
    withApplicabilityReducer,
    withApplicabilitySaga,
    withRouter
)(QuestionMaster);