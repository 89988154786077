import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { LITE_PLAN_SELECTION_REQUEST, LITE_PRICING_LIST_REQUEST } from "./actionTypes";
import { handleApiCallException } from "store/Shared/actions";
import { getLitePricingResponse, getPlanSelectionResponse } from "./actions";
import { invokeApi } from "helpers/axiosHelper";
import { PRICING_LIST, SELECT_CLIENT_PLAN } from "helpers/apiConstants";

function* getLitePricing() {
    try {
        const response = yield call(invokeApi, 'get', PRICING_LIST, {});
        if (response.status) {
            yield put(getLitePricingResponse(response.data.planData));
        } else {
            yield put(getLitePricingResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* selectedPlanRequest({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${SELECT_CLIENT_PLAN}/${data.plan}`, data);
        if (response.status) {
            yield put(getPlanSelectionResponse(response));
        } else {
            yield put(getPlanSelectionResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


export function* watchLitePricing() {
    yield takeEvery(LITE_PRICING_LIST_REQUEST, getLitePricing);
    yield takeEvery(LITE_PLAN_SELECTION_REQUEST, selectedPlanRequest);
}
function* LitePricingSaga() {
    yield all([fork(watchLitePricing)]);
}
export default LitePricingSaga;