import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    CardFooter,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import {
    getEntityListByCompanyGroupIdRequest,
    getLawCompliancesForAllocationRequest,
    allocateCompliancesToEntityRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeEntitySelectField } from 'store/Entity/selector';
import { DANGER, RESET, SUCCESS } from 'constants/commonConstants';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import { toastrMessage } from 'helpers/messageHelper';
import ReactTooltip from 'react-tooltip';
import AllocationFilterForm from './AllocationFilterForm';
import __t from 'i18n/translator';
import {
    initialAllocationDataState,
    listColumns1,
} from './CommonFunctions';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import EventComplianceList from './EventComplianceList';
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawComplianceKey = 'lawCompliance';
const withComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            pageType: 'event',
            pageHeaderName: __t('page_headers.event_allocation'),
            allocationFilterData: initialAllocationDataState(),
            eventAllocationData: [],
            entityList: [],
            entityListData: [],
            isTable: false,
            selectedEventData: {},
            showComplianceList: false,
            groupedEvents: [],
        };
    }
    formSubmit = (values) => {
        this.setState(
            {
                allocationFilterData: values,
                isTable: true,
            },
            () => {
                this.props.getEntityListByCompanyGroupIdRequest([
                    values.companyGroupId,
                ]);
                this.props.getLawCompliancesForAllocationRequest({
                    type: this.state.pageType,
                    filter: {
                        ...this.state.allocationFilterData,
                        industryId: this.state.allocationFilterData.industryId
                            ? [this.state.allocationFilterData.industryId]
                            : [],
                        subIndustryId: this.state.allocationFilterData
                            .subIndustryId
                            ? [this.state.allocationFilterData.subIndustryId]
                            : [],
                        eventId: this.state.allocationFilterData.eventId
                            ? this.state.allocationFilterData.eventId
                            : [],
                    },
                });
            }
        );
    };
    formSubmitForAssign = () => {
        let compliances = [];
        this.state.eventAllocationData.forEach((opt) => {
            compliances.push(...opt.compliances);
        });
        this.props.allocateCompliancesToEntityRequest({
            compliances: compliances,
        });
    };
    resetForm = (values) => {
        this.setState({
            allocationFilterData: {
                ...values,
                companyGroupId: '',
                countryId: [],
                stateId: [],
                cityId: [],
                industryId: '',
                subIndustryId: '',
                lawCategoryId: [],
                lawId: [],
                eventId: [],
            },
            selectedEventData: {},
            showComplianceList: false,
            groupedEvents: [],
            eventList: [],
            isTable: false,
        });
    };
    resetAllForm = (values) => {
        this.setState(
            {
                allocationData: {
                    compliances: [],
                },
                selectedEventData: {},
                showComplianceList: false,
                groupedEvents: [],
                eventList: [],
                isTable: false,
            },
            () => {
                this.setState({
                    allocationFilterData: initialAllocationDataState(),
                });
            }
        );
    };
    getComplianceByEvent = (eventData) => {
        let preSelected = {
            compliances: [],
        };
        if (
            this.state.eventAllocationData.find(
                (opt) => opt.eventId == eventData.eventId
            )
        ) {
            preSelected = this.state.eventAllocationData.find(
                (opt) => opt.eventId == eventData.eventId
            );
        }
        this.setState({
            selectedEventData: { ...eventData, preSelected: preSelected },
            showComplianceList: true,
        });
    };
    closeComplianceList = () => {
        this.setState(
            {
                eventList: this.generateTableForEventGrid(
                    this.state.groupedEvents
                ),
            },
            () => {
                this.setState({
                    selectedEventData: {},
                    showComplianceList: false,
                });
            }
        );
    };
    storeEventComplianceData = (eventId, allotedCompliances) => {
        let eventAllocationData = this.state.eventAllocationData;
        if (eventAllocationData.find((opt) => opt.eventId === eventId)) {
            eventAllocationData = eventAllocationData.map((opt) =>
                opt.eventId === eventId
                    ? {
                        ...opt,
                        compliances: allotedCompliances.compliances,
                    }
                    : opt
            );
        } else {
            eventAllocationData.push({
                eventId: eventId,
                compliances: allotedCompliances.compliances,
            });
        }
        this.setState(
            {
                eventAllocationData: eventAllocationData.filter(
                    (opt) => opt.compliances?.length > 0
                ),
            },
            () => {
                this.closeComplianceList();
            }
        );
    };
    generateTableForEventGrid = (records) => {
        let eventRecords = [];
        eventRecords = records.map((eventData, i) => {
            let eventRecordSelected = this.state.eventAllocationData?.find(
                (opt) => opt.eventId == eventData.eventId
            );
            let alreadySelectedEntities =
                eventRecordSelected?.compliances || [];
            let complianceAction = (
                <React.Fragment key={eventData.eventId}>
                    <a
                        href={() => false}
                        className="btn btn-link tbl-action text-primary"
                        title={
                            eventData.isSequenceUpdated
                                ? __t('common.compliance_hierarchy')
                                : __t(
                                    'form_labels.allocation.submit_compliances_order'
                                )
                        }
                        onClick={() => {
                            if (!eventData.isSequenceUpdated) {
                                toastrMessage(
                                    __t('alerts.submit_compliances_order'),
                                    DANGER
                                );
                            } else {
                                this.getComplianceByEvent(eventData);
                            }
                        }}
                        disabled={
                            !eventData.isSequenceUpdated ? true : false
                        }
                    >
                        <svg
                            width="17"
                            height="20"
                            viewBox="0 0 17 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="16"
                                y="7"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(90 16 7)"
                                fill="#7E869E"
                                fillOpacity="0.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            ></rect>
                            <rect
                                x="16"
                                y="15"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(90 16 15)"
                                fill="#7E869E"
                                fillOpacity="0.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            ></rect>
                            <rect
                                x="1"
                                y="5"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(-90 1 5)"
                                fill="#7E869E"
                                fillOpacity="1"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            ></rect>
                            <path
                                d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            ></path>
                            <path
                                d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            ></path>
                        </svg>
                    </a>
                    {alreadySelectedEntities.length > 0 && (
                        <span className="text-success">
                            <i className="fas fa-check-circle"></i>
                        </span>
                    )}
                </React.Fragment>
            );
            return {
                id: eventData.eventId,
                country: eventData?.country?.countryName,
                eventName: eventData?.eventName,
                action: complianceAction,
            };
        });
        return eventRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawComplianceList &&
            this.props.lawComplianceList !== nextprops.lawComplianceList
        ) {
            if (nextprops.lawComplianceList.length === 0) {
                toastrMessage(__t('alerts.no_compliances_found'), DANGER);
            }
            const groupedEvents = nextprops.lawComplianceList.reduce(
                (acc, obj) => {
                    // Group initialization
                    if (!acc[obj.event?.id]) {
                        acc[obj.event?.id] = {
                            eventId: obj.event?.id,
                            eventName: obj.event?.eventName,
                            isSequenceUpdated: obj.event?.isSequenceUpdated,
                            country: obj.event?.country,
                            compliances: [],
                        };
                    }
                    acc[obj.event?.id].compliances.push(obj.id);
                    return acc;
                },
                []
            );
            this.setState({
                groupedEvents: groupedEvents,
                eventList: this.generateTableForEventGrid(groupedEvents),
            });
        }
        if (
            nextprops.allocationApiResponse &&
            this.props.allocationApiResponse !== nextprops.allocationApiResponse
        ) {
            toastrMessage(
                nextprops.allocationApiResponse.message,
                nextprops.allocationApiResponse.responseType
            );
            if (nextprops.allocationApiResponse.responseType === SUCCESS) {
                this.resetAllForm();
            }
        }
    }
    render() {
        const data = {
            columns: listColumns1,
            rows: this.state.eventList,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'event-allocation'} />
                    </Col>
                    <Col lg="12">
                        <Card className="mb-2">
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        <a
                                            href={() => false}
                                            className="dripicons-arrow-thin-left"
                                            onClick={() => {
                                                this.props.history.push(
                                                    '/allocations/users'
                                                );
                                            }}
                                        >
                                            { }
                                        </a>
                                        {this.state.pageHeaderName}
                                    </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <AllocationFilterForm
                                    allocationFilterData={
                                        this.state.allocationFilterData
                                    }
                                    operationType={this.state.operationType}
                                    entityData={this.state.entityData}
                                    pageType={this.state.pageType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={(values) => {
                                        this.resetForm(values);
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    {this.state.isTable === true && (
                        <React.Fragment>
                            <Col
                                lg="12"
                                className={
                                    this.state.showComplianceList
                                        ? 'd-none'
                                        : ''
                                }
                            >
                                <Card>
                                    <CardBody className="custom_table table-responsive">
                                        <Button
                                            className="btn btn-info bulk_btn"
                                            onClick={() => alert('Coming Soon')}
                                        >
                                            Bulk Select
                                        </Button>
                                        <CustomisedDatatable
                                            className="table table-bordered table-striped"
                                            tableRecords={data}
                                            entries={50}
                                        />
                                    </CardBody>
                                    <CardFooter>
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="mr-2"
                                                onClick={
                                                    this.formSubmitForAssign
                                                }
                                            >
                                                {__t(
                                                    'form_labels.allocation.allocate_to_entity'
                                                )}
                                            </Button>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                className="mr-2"
                                                onClick={() => {
                                                    this.resetAllForm(
                                                        this.state
                                                            .allocationFilterData
                                                    );
                                                }}
                                            >
                                                {RESET}
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </React.Fragment>
                    )}
                </Row>
                {this.state.showComplianceList && (
                    <EventComplianceList
                        allocationFilterData={this.state.allocationFilterData}
                        entityList={this.props.entityList}
                        eventId={this.state.selectedEventData.eventId}
                        formEventData={this.state.selectedEventData}
                        hideComplianceList={() => {
                            this.closeComplianceList();
                        }}
                        onFormSubmitForAssignEvent={(
                            eventId,
                            allocatedEventCmps
                        ) => {
                            this.storeEventComplianceData(
                                eventId,
                                allocatedEventCmps
                            );
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    entityList: makeEntitySelectField('entityList'),
    lawComplianceList: makeLawComplianceSelectField('lawComplianceList'),
    allocationApiResponse: makeAllocationSelectField('allocationApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getEntityListByCompanyGroupIdRequest,
    getLawCompliancesForAllocationRequest,
    allocateCompliancesToEntityRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withComplianceReducer,
    withComplianceSaga,
    withAllocationReducer,
    withAllocationSaga,
    withRouter
)(Index);
