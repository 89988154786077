import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { addLiteEntityRequest, generateLiteComplianceRequest, getLiteCountriesEntityRequest, getLiteCountriesEntityResponse, getLiteCountriesListRequest, postAddLiteEntitySetupRequest, postInActiveLiteEntityRequest } from 'store/Lite/actions';
import RegisteredAccount from 'store/Lite/reducer';
import LiteRegistrationSaga from 'store/Lite/saga';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import { getAllEntityTypeListRequest, getAllFacilityTypeListRequest, getCityListByStateRequest, getEntityListByCompanyGroupIdRequest, getIndustryListByStatusRequest, getStateListByCountryRequest, getSubIndustryListByIndustryRequest } from 'store/actions';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedStateList } from 'store/Masters/State/selector';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import { SUCCESS } from 'constants/commonConstants';
import { Row, FormGroup, Label, Button, Col, Card, CardHeader, CardBody, Badge, Spinner } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import __t from 'i18n/translator';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import SimpleReactValidator from 'simple-react-validator';
import lawSaga from 'store/Masters/Law/saga';
import lawReducer from 'store/Masters/Law/reducer';
import { selectSortedEntityTypeList, selectSortedFacilityTypeList } from 'store/Masters/Law/selector';
import ReactTooltip from 'react-tooltip';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import spain_flag from './../../images/flags/spain_flag.jpg';
import Switch from 'react-switch';
import { makeLiteClientRegistrationSelectField, selectLiteEntityList } from 'store/Lite/selector';
import LiteEntityForm from './components/LiteEntityForm';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { liteEntityFormInitialValue } from './components/commonFunc';
import { Link } from "react-router-dom";
import * as authConstants from 'constants/authConstants';
import { decryptData, encryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import { ROLE_COMPANY_GROUP_ADMIN } from 'constants/databaseConstants';

const countriesEntityList = 'RegisteredAccount';
const withLiteCountriesListReducer = injectReducer({
    key: countriesEntityList,
    reducer: RegisteredAccount,
});
const withLiteCountriesListSaga = injectSaga({ key: countriesEntityList, saga: LiteRegistrationSaga });

const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
class LiteEntityAddModule extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            sortedSubIndustryListDropdown: [],
            countryList: [],
            entityActive: false,
            sortedCityListDropdown: [],
            entityListLoader:true,
            entityAddLoader:false,
            checkEntity:[],
            liteEntityInitialValue: liteEntityFormInitialValue(this.props?.match?.params?.countriesId)
        }
    }
    componentDidMount() {
        const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)));
        const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
        if (isLite.isLite == 1) {
            if (currentRole[0].roleId !== ROLE_COMPANY_GROUP_ADMIN) {
                window.location.pathname = '/home';
            }
        } else {
            window.location.pathname = '/home';
        }
        this.props.getLiteCountriesListRequest();
    }

    addEntitySetupApiCall(value) {
        this.props.addLiteEntityRequest({ ...value });
    }
    addLiteEntitySetup(value) {
        this.props.postAddLiteEntitySetupRequest({ ...value });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.liteCountriesList?.data?.list && nextprops.liteCountriesList?.data?.list !== this.props.liteCountriesList?.data?.list) {
            const findCountry = nextprops.liteCountriesList?.data?.list && nextprops.liteCountriesList?.data?.list?.length > 0 && nextprops.liteCountriesList?.data?.list?.find((element) => element.id == this.props.match.params.countriesId);
            if (!findCountry) {
                window.location.pathname = "/countries-list"
            }
            this.setState({
                countryList: nextprops.liteCountriesList?.data?.list
            })
        }
        if(nextprops.listOfEntityInLite && nextprops.listOfEntityInLite !== this.props.listOfEntityInLite){
            this.setState({
                entityListLoader:false,
            })
        }
        if (nextprops.liteEntityAdded && nextprops.liteEntityAdded !== this.props.liteEntityAdded) {
            if (nextprops.liteEntityAdded.responseType == SUCCESS) {
                this.setState({
                    liteEntityInitialValue: liteEntityFormInitialValue(this.props?.match?.params?.countriesId),
                    sortedCityListDropdown: [],
                    sortedSubIndustryListDropdown: [],
                })
                this.props.getLiteCountriesEntityRequest({ countryId: this.props?.match?.params?.countriesId });
                const checkLocalStorage = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
                if (!checkLocalStorage?.isEntityConfigured) {
                    const updateLocalStorage = {
                        ...checkLocalStorage,
                        isEntityConfigured: 1,
                    }
                    localStorage.setItem(authConstants.IS_LITE, updateLocalStorage ? encryptData(JSON.stringify(updateLocalStorage)) : null)
                }
            }
            this.setState({
                entityAddLoader:false
            })
            toastrMessage(nextprops.liteEntityAdded.message, nextprops.liteEntityAdded.responseType);
        }
        if (nextprops.inActiveEntity && nextprops.inActiveEntity !== this.props.inActiveEntity) {
            if (nextprops.inActiveEntity.responseType == SUCCESS) {
                this.props.getLiteCountriesEntityRequest({ countryId: this.props?.match?.params?.countriesId });
            }
            toastrMessage(nextprops.inActiveEntity.message, nextprops.inActiveEntity.responseType);
        }
        if (nextprops.sortedCityListDropdown && nextprops.sortedCityListDropdown !== this.props.sortedCityListDropdown) {
            this.setState({
                sortedCityListDropdown: nextprops.sortedCityListDropdown
            })
        }
        if (nextprops.sortedSubIndustryListDropdown && nextprops.sortedSubIndustryListDropdown !== this.props.sortedSubIndustryListDropdown) {
            this.setState({
                sortedSubIndustryListDropdown: nextprops.sortedSubIndustryListDropdown
            })
        }
        if(nextprops.liteComplianceGenerateApiResponse && nextprops.liteComplianceGenerateApiResponse !== this.props.liteComplianceGenerateApiResponse){
            toastrMessage(nextprops.liteComplianceGenerateApiResponse.message, nextprops.liteComplianceGenerateApiResponse.responseType);
        }
    }
    SubmitEntityAddForm(values){
        let apiRequestValue = {
            ...values
        }
        if (values.entityNewValue) {
            apiRequestValue.entityName = apiRequestValue.entityId;
            delete apiRequestValue.entityId;
        } else {
            apiRequestValue.entityId = apiRequestValue.entityId;
            delete apiRequestValue?.entityName;
        }
        if (values.managementNewValue) {
            apiRequestValue.managementUser = apiRequestValue.managementUser;
        } else {
            apiRequestValue.managementUserId = apiRequestValue.managementUser;
            delete apiRequestValue?.managementUser;
        }
        delete apiRequestValue.cityId;
        delete apiRequestValue.stateId;
        delete apiRequestValue.entityNewValue;
        delete apiRequestValue.managementNewValue;
        this.setState({
            liteEntityInitialValue: values,
            entityAddLoader:true
        })
        if (values.entityNewValue) {
            this.addEntitySetupApiCall(apiRequestValue);
        } else {
            this.addLiteEntitySetup(apiRequestValue)
        }
    }
    render() {
        const findCountry = this.state?.countryList && this.state?.countryList?.length > 0 && this.state?.countryList.find((element) => element.id == this.props.match.params.countriesId)
        return <>
            <ReactTooltip />
            <Row>
                <Col lg="12">
                    <CommonBreadcrum type={'countries-list-entity'} />
                </Col>
                <Col lg="12">
                    <Card className="mb-2">
                        <CardHeader>
                            <div className="page-header">
                                <h4 className="with-back-btn">
                                    <p
                                        className="dripicons-arrow-thin-left my-0"
                                        onClick={() => {
                                            this.props.getLiteCountriesEntityResponse({ list: null });
                                            this.props.history.push(
                                                '/countries-list'
                                            );
                                        }}
                                    >
                                        { }
                                    </p>
                                    {__t("lite.add_new_entity")}
                                </h4>
                                {
                                    findCountry && <span className='event_country'>
                                        <img src={`/data/flags/${findCountry.isoCode2}.svg`} alt={findCountry.countryName} width={20} height={15} />
                                        <span className='mt-1'>{findCountry?.countryName}</span>
                                    </span>
                                }

                            </div>
                        </CardHeader>
                        <LiteEntityForm
                            sortedSubIndustryListDropdown={this.state.sortedSubIndustryListDropdown}
                            sortedStateListDropdown={this.props.sortedStateListDropdown}
                            sortedCityListDropdown={this.state.sortedCityListDropdown}
                            sortedIndustryListDropdown={this.props.sortedIndustryListDropdown}
                            sortedEntityTypeListDropDown={this.props.sortedEntityTypeListDropDown}
                            sortedFacilityTypeListDropDown={this.props.sortedFacilityTypeListDropDown}
                            sortedEntityListDropDown={this.props.sortedEntityListDropDown}
                            match={this.props.match}
                            listOfEntityInLite={this.props.listOfEntityInLite}
                            getSubIndustryListByIndustryRequest={this.props.getSubIndustryListByIndustryRequest}
                            getLiteCountriesEntityRequest={this.props.getLiteCountriesEntityRequest}
                            listOfLiteEntity={this.props.listOfLiteEntity}
                            getStateListByCountryRequest={this.props.getStateListByCountryRequest}
                            getCityListByStateRequest={this.props.getCityListByStateRequest}
                            getIndustryListByStatusRequest={this.props.getIndustryListByStatusRequest}
                            getAllEntityTypeListRequest={this.props.getAllEntityTypeListRequest}
                            getAllFacilityTypeListRequest={this.props.getAllFacilityTypeListRequest}
                            getEntityListByCompanyGroupIdRequest={this.props.getEntityListByCompanyGroupIdRequest}
                            addLiteEntityRequest={this.props.addLiteEntityRequest}
                            liteEntityInitialValue={this.state.liteEntityInitialValue}
                            entityAddLoader={this.state.entityAddLoader}
                            isEditEntity={false}
                            onFormSubmit={async(values) => {
                                if(values.entityNewValue){
                                    const response = await commonConfirmBox(
                                        __t('common.are_you_sure'),
                                        `${values?.managementUser} will get onboarding email for first time registration.`
                                    );
                                    if(response){
                                        this.SubmitEntityAddForm(values);
                                    }
                                }else{
                                    this.SubmitEntityAddForm(values);
                                }
                            }}
                        />
                    </Card>
                </Col>
                <Col lg="12">
                    {
                        this.props.listOfEntityInLite && this.props.listOfEntityInLite.length > 0 ? <div className='page-header'>
                            <h5 className='section-header'>{__t("form_labels.lite_entity.entity_setup.configure_entity")}</h5>
                        </div> : null
                    }
                    <Formik>
                        <Form>
                            <Row className='entity-list'>
                                {this.state.entityListLoader ? <div className='d-flex justify-content-center'><Spinner /></div> :this.props.listOfEntityInLite && this.props.listOfEntityInLite.length > 0 && this.props.listOfEntityInLite.map((element, index) => {
                                    // const StateName = element.liteEntitySetupList && element.liteEntitySetupList[0]?.liteEntityLocationList && element.liteEntitySetupList[0]?.liteEntityLocationList.length > 0 && element.liteEntitySetupList[0]?.liteEntityLocationList.map((location) => {
                                    //     return location?.state?.stateName
                                    // })
                                    // const CityName = element.liteEntitySetupList && element.liteEntitySetupList[0]?.liteEntityLocationList && element.liteEntitySetupList[0]?.liteEntityLocationList.length > 0 && element.liteEntitySetupList[0]?.liteEntityLocationList.map((location) => {
                                    //     return location?.city?.cityName
                                    // })
                                    return <Col lg="6" key={index}>
                                        <Card>
                                            <CardBody>
                                                <Label className='position-absolute' style={{width:"0px",padding:"0px"}}>
                                                    <Field type="checkbox" name="toggle" checked={this.state.checkEntity.includes(element.id)} onChange={()=>{
                                                        const alreadyCheckEntity = this.state.checkEntity ? [...this.state.checkEntity]:[];
                                                        const isExist = alreadyCheckEntity  && alreadyCheckEntity.length > 0 && alreadyCheckEntity.find((ele)=> ele == element.id);
                                                        if(isExist){
                                                            const filterEntity  = alreadyCheckEntity  && alreadyCheckEntity.length > 0 && alreadyCheckEntity.filter((ele)=> ele !== element.id);
                                                            this.setState({
                                                                checkEntity:filterEntity
                                                            })
                                                        }else{
                                                            alreadyCheckEntity.push(element.id)
                                                            this.setState({
                                                                checkEntity:alreadyCheckEntity
                                                            })
                                                        }
                                                    }} />
                                                </Label>
                                                <Label>
                                                    <div className='configure-entity-main'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className='configure-entity-status'>
                                                                        {
                                                                            findCountry && <span className='flag'>
                                                                                <img src={`/data/flags/${findCountry.isoCode2}.svg`} alt={findCountry.countryName} />
                                                                                <span className='mt-1'>{findCountry?.countryName}</span>
                                                                            </span>
                                                                        }
                                                                        {/* <Badge color='primary'>
                                                                            Compliance Generated
                                                                        </Badge>
                                                                        <Badge color='warning' className='bg-orange'>
                                                                            User Mapped
                                                                        </Badge>
                                                                        <Badge color='info'>
                                                                            Schedule Started
                                                                        </Badge> */}
                                                                    </div>
                                                                    <div className='configure-entity-date'>
                                                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15.1251 2H13.5001V3H15.0001V14H1.00013V3H2.50013V2H0.875129C0.758247 2.00195 0.642895 2.02691 0.535662 2.07345C0.428428 2.11999 0.331414 2.1872 0.250159 2.27125C0.168905 2.35529 0.105002 2.45451 0.0621014 2.56325C0.0192006 2.67199 -0.00185798 2.78812 0.000128526 2.905V14.095C-0.00185798 14.2119 0.0192006 14.328 0.0621014 14.4367C0.105002 14.5455 0.168905 14.6447 0.250159 14.7288C0.331414 14.8128 0.428428 14.88 0.535662 14.9265C0.642895 14.9731 0.758247 14.998 0.875129 15H15.1251C15.242 14.998 15.3574 14.9731 15.4646 14.9265C15.5718 14.88 15.6688 14.8128 15.7501 14.7288C15.8314 14.6447 15.8953 14.5455 15.9382 14.4367C15.9811 14.328 16.0021 14.2119 16.0001 14.095V2.905C16.0021 2.78812 15.9811 2.67199 15.9382 2.56325C15.8953 2.45451 15.8314 2.35529 15.7501 2.27125C15.6688 2.1872 15.5718 2.11999 15.4646 2.07345C15.3574 2.02691 15.242 2.00195 15.1251 2Z" fill="currentColor" />
                                                                            <path d="M3.00098 6H4.00098V7H3.00098V6Z" fill="currentColor" /><path d="M6 6H7V7H6V6Z" fill="currentColor" /><path d="M9 6H10V7H9V6Z" fill="currentColor" /><path d="M12.001 6H13.001V7H12.001V6Z" fill="currentColor" /><path d="M3.00098 8.5H4.00098V9.5H3.00098V8.5Z" fill="currentColor" /><path d="M6 8.5H7V9.5H6V8.5Z" fill="currentColor" /><path d="M9 8.5H10V9.5H9V8.5Z" fill="currentColor" /><path d="M12.001 8.5H13.001V9.5H12.001V8.5Z" fill="currentColor" /><path d="M3.00098 11H4.00098V12H3.00098V11Z" fill="currentColor" /><path d="M6 11H7V12H6V11Z" fill="currentColor" /><path d="M9 11H10V12H9V11Z" fill="currentColor" /><path d="M12.001 11H13.001V12H12.001V11Z" fill="currentColor" /><path d="M4 4C4.13261 4 4.25979 3.94732 4.35355 3.85355C4.44732 3.75979 4.5 3.63261 4.5 3.5V0.5C4.5 0.367392 4.44732 0.240215 4.35355 0.146447C4.25979 0.0526784 4.13261 0 4 0C3.86739 0 3.74021 0.0526784 3.64645 0.146447C3.55268 0.240215 3.5 0.367392 3.5 0.5V3.5C3.5 3.63261 3.55268 3.75979 3.64645 3.85355C3.74021 3.94732 3.86739 4 4 4Z" fill="currentColor" />
                                                                            <path d="M12 4C12.1326 4 12.2598 3.94732 12.3536 3.85355C12.4473 3.75979 12.5 3.63261 12.5 3.5V0.5C12.5 0.367392 12.4473 0.240215 12.3536 0.146447C12.2598 0.0526784 12.1326 0 12 0C11.8674 0 11.7402 0.0526784 11.6464 0.146447C11.5527 0.240215 11.5 0.367392 11.5 0.5V3.5C11.5 3.63261 11.5527 3.75979 11.6464 3.85355C11.7402 3.94732 11.8674 4 12 4Z" fill="currentColor" /><path d="M5.5 2H10.5V3H5.5V2Z" fill="currentColor" />
                                                                        </svg>
                                                                        {element?.entityFinancialYears[0]?.startMonth} to {element?.entityFinancialYears[0]?.endMonth}
                                                                    </div>
                                                                </div>
                                                                <h5>{element?.entityName}</h5>
                                                                <div className='configure-location'>
                                                                    <p>Location: {element.liteEntitySetupList ? element.liteEntitySetupList?.length:0 }/5</p>
                                                                    {/* <p><span>{__t("lite.state")} :</span>{StateName && StateName.length > 0 && [...new Set(StateName)].join(",")}</p> */}
                                                                    {/* <p><span>{__t("lite.city")} :</span>{CityName && CityName.length > 0 && [...new Set(CityName)].join(",")}</p> */}
                                                                </div>
                                                    </div>
                                                </Label>
                                                <div className='btn-group'>
                                                <Link to={`/countries-list/${findCountry?.id}/entity/${element.id}/configured-entity/${element.liteEntitySetupList[0].id}`}>
                                                    <Button type='button' color='info' className='px-1 py-1 rounded-circle d-flex align-items-center justify-content-center'>
                                                        <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.41176" y="1.91176" width="20.1765" height="13.1765" rx="6.17647" stroke="currentColor" strokeWidth="0.823529" /><circle cx="11.4997" cy="8.50261" r="3.84314" stroke="currentColor" strokeWidth="1.64706" /><path d="M21.341 7.61396C21.657 8.01392 21.8151 8.2139 21.8151 8.5C21.8151 8.7861 21.657 8.98608 21.341 9.38604C19.8529 11.2694 16.0082 15.5 11.5 15.5C6.99178 15.5 3.14715 11.2694 1.65899 9.38604C1.34295 8.98608 1.18493 8.7861 1.18493 8.5C1.18493 8.2139 1.34295 8.01392 1.65899 7.61396C3.14715 5.73062 6.99178 1.5 11.5 1.5C16.0082 1.5 19.8529 5.73062 21.341 7.61396Z" stroke="currentColor" strokeWidth="1.64706" /></svg>
                                                    </Button>
                                                </Link>
                                                    <Switch
                                                        className="switch-sm"
                                                        onColor="#9BD732"
                                                        id="Abc"
                                                        checked={element.isActive}
                                                        onChange={(val) => {
                                                            this.props.postInActiveLiteEntityRequest({ id: element.id, status: val })
                                                        }}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                })}
                            </Row>
                            {
                                this.props.listOfEntityInLite && this.props.listOfEntityInLite.length > 0 ? <FormGroup className="col-12 mb-0 d-flex justify-content-end mt-2">
                                    <Button
                                        type="button"
                                        onClick={()=>{
                                            this.props.generateLiteComplianceRequest({
                                                entity:this.state.checkEntity
                                            })
                                        }}
                                        color="primary"
                                        className="mr-1"
                                    >{__t("lite.map_users")}</Button>
                                </FormGroup> : null
                            }
                        </Form>
                    </Formik>

                </Col>
            </Row >
        </>
    }
}


const mapStatetoProps = createStructuredSelector({
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedEntityTypeListDropDown: selectSortedEntityTypeList(),
    sortedFacilityTypeListDropDown: selectSortedFacilityTypeList(),
    sortedEntityListDropDown: selectSortedEntityList(),
    liteCountriesList: makeLiteClientRegistrationSelectField("liteCountriesList"),
    liteEntityAdded: makeLiteClientRegistrationSelectField("addLiteEntity"),
    listOfEntityInLite: makeLiteClientRegistrationSelectField("listOfLiteEntity"),
    listOfLiteEntity: selectLiteEntityList(),
    inActiveEntity: makeLiteClientRegistrationSelectField("inActiveEntity"),
    liteComplianceGenerateApiResponse:makeLiteClientRegistrationSelectField("liteComplianceGenerateApiResponse")
});
const withConnect = connect(mapStatetoProps, {
    getLiteCountriesEntityRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getAllEntityTypeListRequest,
    getAllFacilityTypeListRequest,
    getEntityListByCompanyGroupIdRequest,
    getLiteCountriesListRequest,
    addLiteEntityRequest,
    postInActiveLiteEntityRequest,
    postAddLiteEntitySetupRequest,
    getLiteCountriesEntityResponse,
    generateLiteComplianceRequest
});
export default compose(
    withConnect,
    withLiteCountriesListReducer,
    withLiteCountriesListSaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withEntityReducer,
    withEntitySaga,
    withLawReducer,
    withLawSaga,
    withRouter
)(LiteEntityAddModule);
