import React, { Component } from 'react';
import {
    Row, Col, CardBody, Card, CardHeader, NavLink,
    NavItem,
    TabContent,
    TabPane,
    Nav
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ADD_NEW, ENABLE_DELETE, NONE } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllEntityListRequest,
    addEntityRequest,
    updateEntityRequest,
    updateEntityStatusByEntityIdRequest,
    deleteEntityStatusByEntityIdRequest,
    getAllEntityLocationListRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import entityLocationReducer from 'store/Masters/EntityLocation/reducer';
import entityLocationSaga from 'store/Masters/EntityLocation/saga';
import { makeEntitySelectField } from 'store/Entity/selector';
import { listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import { makeEntityLocationSelectField } from 'store/Masters/EntityLocation/selector';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import EntityLocation from './../EntityLocation/Index';
import {
    MODULE_ENTITY,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
    PERMISSION_VIEW,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import classnames from 'classnames';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const entityLocationKey = 'entityLocation';
const withEntityLocationReducer = injectReducer({
    key: entityLocationKey,
    reducer: entityLocationReducer,
});
const withEntityLocationSaga = injectSaga({
    key: entityLocationKey,
    saga: entityLocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entityList: [],
            exportEntityList: [],
            entityLocationList: [],
            isAuditModalOpen: false,
            auditId: '',
            activeTab: 'entityTab',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve entities list
        this.props.getAllEntityListRequest();
        this.props.getAllEntityLocationListRequest();
    }
    editCompanyDetails = (record) => {
        this.props.history.push('/entities/manage/edit/' + record.id);
    };
    viewCompanyDetails = (record) => {
        this.props.history.push('/entities/manage/view/' + record.id);
    };
    addCompanyDetails = () => {
        this.props.history.push('/entities/manage/add');
    };
    onStatusChange(checked, e, id) {
        this.props.updateEntityStatusByEntityIdRequest(id, checked);
    }
    deleteCompany = async (companyId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_delete')
        );
        if (response) {
            this.props.deleteEntityStatusByEntityIdRequest(companyId);
        }
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let companyRecords = [];
        companyRecords = records.map((entityData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_ENTITY, PERMISSION_EDIT) ===
                        true &&
                        entityData.id !== NONE && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() =>
                                    this.editCompanyDetails(entityData)
                                }
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {checkModulePermission(MODULE_ENTITY, PERMISSION_EDIT) ===
                        false && checkModulePermission(MODULE_ENTITY, PERMISSION_VIEW) ===
                        true && (
                            <a
                                href={() => false}
                                title={__t('common.view')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() =>
                                    this.viewCompanyDetails(entityData)
                                }
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('entity') &&
                        checkModulePermission(
                            MODULE_ENTITY,
                            PERMISSION_DELETE
                        ) === true &&
                        entityData.id !== NONE && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteCompany(entityData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(entityData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(entityData.id)}
                        checked={entityData.isActive ? true : false}
                        disabled={!checkModulePermission(MODULE_ENTITY, PERMISSION_EDIT)}
                    />
                    &nbsp;
                </div>
            );
            return {
                entityName: (entityData.entityName) ? entityData.entityName : '',
                companyGroupName: entityData?.companyGroup?.companyGroupName,
                email: (entityData.email) ? entityData.email : '',
                contactNo: entityData.contactNo ? entityData.contactNo : '',
                isActive: entityData.id !== NONE ? isActive : '-',
                actions: actions,
            };
        });
        return companyRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.entityList &&
            this.props.entityList !== nextprops.entityList
        ) {
            this.setState({
                exportEntityList: nextprops.entityList,
                entityList: this.generateTable(nextprops.entityList),
            });
        }
        if (
            nextprops.entityLocationList &&
            this.props.entityLocationList !== nextprops.entityLocationList
        ) {
            this.setState({
                entityLocationList: nextprops.entityLocationList.map(
                    (entityLocationData, i) => ({
                        companyGroupName:
                            entityLocationData.entity?.companyGroup
                                ?.companyGroupName,
                        entityName: entityLocationData.entity?.entityName,
                        countryName: entityLocationData.country?.countryName,
                        stateName: entityLocationData.state?.stateName,
                        cityName: entityLocationData.city?.cityName,
                        pincode: entityLocationData.pincode,
                        isActive: entityLocationData.isActive,
                    })
                ),
            });
        }
        if (
            nextprops.deleteEntityApiResponse &&
            this.props.deleteEntityApiResponse !==
            nextprops.deleteEntityApiResponse
        ) {
            toastrMessage(
                nextprops.deleteEntityApiResponse.message,
                nextprops.deleteEntityApiResponse.responseType
            );
        }
        if (
            nextprops.entityStatusUpdateApiResponse &&
            this.props.entityStatusUpdateApiResponse !==
            nextprops.entityStatusUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.entityStatusUpdateApiResponse.message,
                nextprops.entityStatusUpdateApiResponse.responseType
            );
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.entityList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={this.state.activeTab == 'entityTab' ? 'entity' : 'entity-location'} />
                    </Col>

                    <Col lg="12" className='viewlaws_tabs tabs-main'>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab === 'entityTab',
                                    })}
                                    onClick={() => {
                                        this.setState({
                                            activeTab: 'entityTab'
                                        },()=>{
                                            this.props.getAllEntityListRequest();
                                        });
                                    }}
                                >
                                    Entity
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            'entityLocationTab',
                                    })}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                activeTab: 'entityLocationTab'

                                            }
                                        );
                                    }}
                                >
                                    Entity Location
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane className="laws_tab" tabId="entityTab">
                                <Row>
                                    <Col lg="12">
                                        <Card className="mb-2">
                                            <CardHeader>
                                                <div className="page-header">
                                                    <h4>{__t('page_headers.entity_list')}</h4>
                                                </div>
                                                <div className="page-action">
                                                    {(checkModulePermission(
                                                        MODULE_ENTITY,
                                                        PERMISSION_ADD
                                                    ) === true) && (
                                                            <a
                                                                href={() => false}
                                                                className="btn btn-primary waves-effect waves-light float-right ml-2"
                                                                onClick={() => {
                                                                    this.addCompanyDetails();
                                                                }}
                                                            >
                                                                <i className="fas fa-plus"> </i>{' '}
                                                                {ADD_NEW}
                                                            </a>
                                                        )}
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <CustomisedMDBDatatablePage
                                                    activepage={this.state.activePage}
                                                    onHandlePageChange={this.handlePageChange}
                                                    tableRecords={data}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane
                                className="laws_tab"
                                tabId="entityLocationTab"
                            >
                                <Row>
                                    <EntityLocation />
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
                <AuditLogModal
                    exportBtn={true}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entity'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    entityList: makeEntitySelectField('entityList'),
    entityApiResponse: makeEntitySelectField('entityApiResponse'),
    entityStatusUpdateApiResponse: makeEntitySelectField(
        'entityStatusUpdateApiResponse'
    ),
    deleteEntityApiResponse: makeEntitySelectField('deleteEntityApiResponse'),
    entityLocationList: makeEntityLocationSelectField('entityLocationList'),
});
const withConnect = connect(mapStatetoProps, {
    getAllEntityListRequest,
    addEntityRequest,
    updateEntityRequest,
    updateEntityStatusByEntityIdRequest,
    deleteEntityStatusByEntityIdRequest,
    getAllEntityLocationListRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withEntityLocationReducer,
    withEntityLocationSaga,
    withRouter
)(Index);
