import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => {
    return state.RegisteredAccount || initialState;
}
const makeLiteClientRegistrationSelectField = field =>
    createSelector(reducerState, registrationState => registrationState[field]);

const selectLiteEntityList = () =>
    createSelector(makeLiteClientRegistrationSelectField('listOfLiteEntity'), (listOfLiteEntity) => {
        return listOfLiteEntity?.map((opt) => ({
            value: opt.id,
            label: opt.entityName,
        }))
    });
const selectLiteCityList = () =>
    createSelector(makeLiteClientRegistrationSelectField('liteCityListApiResponse'), (listOfCity) => {
        return listOfCity?.map((opt) => ({
            value: opt.id,
            label: opt.cityName,
        }))
    });
export {
    makeLiteClientRegistrationSelectField,
    selectLiteEntityList,
    selectLiteCityList
};
