import axios from 'axios';
import { removeLoggedInUserDetails, getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { toastrMessage } from './messageHelper';
import __t from 'i18n/translator';
import { DANGER } from 'constants/commonConstants';
const invokeApi = (method, url, data, headers) => {
    const baseUrl = getBaseUrl();
    if (getValueByKey(authConstants.LOGIN_TYPE) === "SSO") {
        headers = { ...headers, "logintype": "SSO" };
    } else {
        headers = { ...headers, "logintype": "LC" };
    }
    headers = {
        ...headers,
        'x-content-type-options': 'nosniff',
        'x-frame-options': 'SAMEORIGIN',
        'x-xss-protection': '1; mode=block',
        'Strict-Transport-Security': 'max-age',
        'content-security-policy': "default-src 'self'; script-src 'self'; style-src 'self';",
        'Cache-Control': 'no-cache, private',
        Pragma: 'no-cache',
        'Access-Control-Allow-Origin': baseUrl,
        'Access-Control-Allow-Credentials': false,
    };
    url = baseUrl + url;
    return axios({
        method: method,
        url: url,
        headers: {
            ...headers,
            Authorization: 'Bearer ' + getValueByKey(authConstants.TOKEN),
        },
        data: data,
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};
const getBaseUrl = () => {
    let baseHost = window.location.hostname;
    let baseUrl;
    switch (baseHost) {
        case 'localhost':
            // baseUrl = 'http://localhost:3001';
            baseUrl = 'https://gt1.hcshub.in/gt1-api';
            break;
        case 'gt1.hcshub.in':
            baseUrl = window.location.origin + '/gt1-api'
            break;
        default:
            baseUrl = window.location.origin + '/api';// client URL
            break;
    }
    return baseUrl;
};

const getBaseEndPoint = () => {
    let baseEndpoint = window.location.origin; // gt1 URL
    return baseEndpoint;
};

const getSocketOptions = () => {
    let baseEndpoint = window.location.hostname;
    let options = {};
    switch (baseEndpoint) {
        case 'localhost':
            options = { path: '/gt1-api/socket.io/' } // gt1
            break;
        case 'gt1.hcshub.in':
            options = { path: '/gt1-api/socket.io/' } // gt1
            break;
        default:
            options = { path: '/api/socket.io/' }
            break;
    }
    return options;
};
//Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        if (
            error.response.status === 401 &&
            window.location.pathname !== '/' &&
            window.location.pathname !== '/login'
        ) {
            removeLoggedInUserDetails();
            window.location.href = '/';
        }
        if (
            error.response.status === 403 &&
            window.location.pathname !== '/' &&
            window.location.pathname !== '/login'
        ) {
            toastrMessage(__t('alerts.permission_denied'), DANGER);
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export const ALLOWED_IP = process.env.REACT_APP_ALLOWED_IP.split(',')

export const TAWK_CLIENT_UAT_URL = "https://embed.tawk.to/654b2092a84dd54dc489b939/1hemnefif";
export const TAWK_DEV_URL = 'https://embed.tawk.to/6540bcdda84dd54dc4870945/1he2e3gfd';

export { invokeApi, getBaseUrl, getBaseEndPoint, getSocketOptions };


