import { LITE_ACCOUNT_DETAILS_ENTITY_REQUEST, LITE_ACCOUNT_DETAILS_ENTITY_RESPONSE, LITE_ACCOUNT_DETAILS_REQUEST, LITE_ACCOUNT_DETAILS_RESPONSE, LITE_ACCOUNT_ENTITY_NAME_UPDATE_REQUEST, LITE_ACCOUNT_ENTITY_NAME_UPDATE_RESPONSE, LITE_ACCOUNT_UPDATE_REQUEST, LITE_ACCOUNT_UPDATE_RESPONSE, LITE_COUNTRY_LIST_WISE_ENTITY_REQUEST, LITE_COUNTRY_LIST_WISE_ENTITY_RESPONSE, LITE_ENTITY_DETAILS_BY_ENTITY_ID_REQUEST, LITE_ENTITY_DETAILS_BY_ENTITY_ID_RESPONSE, LITE_ENTITY_STATE_LIST_REQUEST, LITE_ENTITY_STATE_LIST_RESPONSE, LITE_ENTITY_UPDATE_BY_ID_REQUEST, LITE_ENTITY_UPDATE_BY_ID_RESPONSE, LITE_ENTITY_WISE_QUESTION_LIST_REQUEST, LITE_ENTITY_WISE_QUESTION_LIST_RESPONSE, LITE_SUSPEND_ACCOUNT_REQUEST, LITE_SUSPEND_ACCOUNT_RESPONSE } from "./actionTypes";

export const getAccountDetailsRequest = data => {
    return {
        type: LITE_ACCOUNT_DETAILS_REQUEST,
        payload: { data }
    };
};

export const getAccountDetailsResponse = data => {
    return {
        type: LITE_ACCOUNT_DETAILS_RESPONSE,
        payload: { data }
    };
};



export const getLiteAccountDetailsRequest = (data) => {
    return {
        type: LITE_ACCOUNT_DETAILS_ENTITY_REQUEST,
        payload: { data },
    };
}

export const getLiteAccountDetailsResponse = (data) => {
    return {
        type: LITE_ACCOUNT_DETAILS_ENTITY_RESPONSE,
        payload: { data },
    };
}


export const getCountryListWithEntityRequest = (data) => {
    return {
        type: LITE_COUNTRY_LIST_WISE_ENTITY_REQUEST,
        payload: { data },
    };
}

export const getCountryListWithEntityResponse = (data) => {
    return {
        type: LITE_COUNTRY_LIST_WISE_ENTITY_RESPONSE,
        payload: { data },
    };
}

export const liteAccountEntityNameUpdateRequest = (data) => {
    return {
        type: LITE_ACCOUNT_ENTITY_NAME_UPDATE_REQUEST,
        payload: { data },
    };
}

export const liteAccountEntityNameUpdateResponse = (data) => {
    return {
        type: LITE_ACCOUNT_ENTITY_NAME_UPDATE_RESPONSE,
        payload: { data },
    };
}


export const liteEntityStateListRequest = (data) => {
    return {
        type: LITE_ENTITY_STATE_LIST_REQUEST,
        payload: { data },
    };
}

export const liteEntityStateListResponse = (data) => {
    return {
        type: LITE_ENTITY_STATE_LIST_RESPONSE,
        payload: { data },
    };
}


export const LiteAccountUpdateRequest = (data) => {
    return {
        type: LITE_ACCOUNT_UPDATE_REQUEST,
        payload: { data },
    };
}

export const LiteAccountUpdateResponse = (data) => {
    return {
        type: LITE_ACCOUNT_UPDATE_RESPONSE,
        payload: { data },
    };
}


export const LiteEntityDetailsByEntityIdRequest = (data) => {
    return {
        type: LITE_ENTITY_DETAILS_BY_ENTITY_ID_REQUEST,
        payload: { data },
    };
}

export const LiteEntityDetailsByEntityIdResponse = (data) => {
    return {
        type: LITE_ENTITY_DETAILS_BY_ENTITY_ID_RESPONSE,
        payload: { data },
    };
}

export const LiteEntityUpdateByIdRequest = (data) => {
    return {
        type: LITE_ENTITY_UPDATE_BY_ID_REQUEST,
        payload: { data },
    };
}

export const LiteEntityUpdateByIdResponse = (data) => {
    return {
        type: LITE_ENTITY_UPDATE_BY_ID_RESPONSE,
        payload: { data },
    };
}


export const getEntityWiseQuestionListRequest = (data) => {
    return {
        type: LITE_ENTITY_WISE_QUESTION_LIST_REQUEST,
        payload: { data },
    };
}

export const getEntityWiseQuestionListResponse = (data) => {
    return {
        type: LITE_ENTITY_WISE_QUESTION_LIST_RESPONSE,
        payload: { data },
    };
}

export const suspendLiteAccountRequest = (data) => {
    return {
        type: LITE_SUSPEND_ACCOUNT_REQUEST,
        payload: { data },
    };
}

export const suspendLiteAccountResponse = (data) => {
    return {
        type: LITE_SUSPEND_ACCOUNT_RESPONSE,
        payload: { data },
    };
}