import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Nav, NavItem, NavLink, Progress, Row, Spinner, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import __t from "i18n/translator";
import { withRouter } from 'react-router-dom'
import { compose } from "redux";
import { selectSortedStateList } from "store/Masters/State/selector";
import { selectSortedCityList } from "store/Masters/City/selector";
import { selectSortedIndustryList } from "store/Masters/Industry/selector";
import { selectSortedSubIndustryList } from "store/Masters/SubIndustry/selector";
import { selectSortedEntityTypeList, selectSortedFacilityTypeList } from "store/Masters/Law/selector";
import { selectSortedEntityList } from "store/Entity/selector";
import { makeLiteClientRegistrationSelectField, selectLiteEntityList } from "store/Lite/selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { getEntityDetailsRequest, getEntitySetupStateAndCityRequest, getLiteCityListRequest, getLiteComplianceListRequest, getLiteCountriesEntityRequest, getLiteCountriesListRequest, getLiteQuestionRequest, postClientQuestionRequest, putLiteEntityRequest, setResetQuestionnaireRequest } from "store/Lite/actions";
import { getAllEntityTypeListRequest, getAllFacilityTypeListRequest, getCityListByStateRequest, getEntityListByCompanyGroupIdRequest, getIndustryListByStatusRequest, getLawCategoryListByStatusRequest, getStateListByCountryRequest, getSubIndustryListByIndustryRequest } from "store/actions";
import injectReducer from "helpers/injectReducer";
import injectSaga from "helpers/injectSaga";
import lawReducer from 'store/Masters/Law/reducer';
import entityReducer from 'store/Entity/reducer';
import stateReducer from 'store/Masters/State/reducer';
import cityReducer from 'store/Masters/City/reducer';
import industryReducer from 'store/Masters/Industry/reducer';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from "store/Masters/SubIndustry/saga";
import lawSaga from "store/Masters/Law/saga";
import entitySaga from "store/Entity/saga";
import industrySaga from "store/Masters/Industry/saga";
import citySaga from "store/Masters/City/saga";
import stateSaga from "store/Masters/State/saga";
import LiteRegistrationSaga from "store/Lite/saga";
import LiteEntityForm from "./components/LiteEntityForm";
import registeredAccountReducer from "store/Lite/reducer";
import CommonBreadcrum from "components/Application/CommonBreadcrum";
import { Link } from "react-router-dom"
import { ACTIVE, DEFAULT_PAGE_LENGTH, SUCCESS } from "constants/commonConstants";
import { commonConfirmBox, toastrMessage } from "helpers/messageHelper";
import * as authConstants from 'constants/authConstants';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import { ROLE_COMPANY_GROUP_ADMIN } from 'constants/databaseConstants';
import CustomisedReactTableWithPagination from "components/Application/CustomisedReactTableWithPagination";
import Select from 'react-select';
import { selectSortedLawCategoryList } from "store/Masters/LawCategory/selector";
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import QuestionAccordion from "./components/QuestionAccordian";
import StateAccordion from "./components/StateAccordian";
import { createLoadingSelector } from "store/Shared/selector";
import CustomisedModal from "components/Application/CustomisedModal";

const countriesEntityList = 'RegisteredAccount';
const withLiteCountriesListReducer = injectReducer({
    key: countriesEntityList,
    reducer: registeredAccountReducer,
});
const withLiteCountriesListSaga = injectSaga({ key: countriesEntityList, saga: LiteRegistrationSaga });

const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
const ComplianceGeneratorHeader = [
    {
        Header: 'Compliance Code',
        accessor: 'complianceCode',
    },
    {
        Header: 'Compliance Title',
        accessor: 'complianceTitle',
    },
    {
        Header: 'Company Group',
        accessor: 'companyGroupName',
    },
    {
        Header: 'Entity',
        accessor: 'entityName',
    },
    {
        Header: 'Country',
        accessor: 'countryName',
    },
    {
        Header: 'State/Province',
        accessor: 'stateName',
    },
    // {
    //     Header: 'City/Location',
    //     accessor: 'city',
    // },
    {
        Header: 'Classification',
        accessor: 'complianceCategoryName',
    },
];
class LiteEntityEditModule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: "",
            stateTab: null,
            pageHeaderName: __t('page_headers.lite_entity_details'),
            isEdit: false,
            countryList: [],
            entitySetupData: {},
            entityExtraData: {},
            entitySetupId: null,
            editEntityLoader:true,
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            totalRecords: 0,
            entityAddLoader:false,
            questionAnswerLoader:false,
            searchFormData: {},
            liteEntityInitialValue: {},
            filterCityId: null,
            liteComplianceList: null,
            liteComplianceListTable: [],
            isModalOpen: false,
            answerPracticeArea: [],
            liteQuestionList: null,
            currentQuestionPage: 1,
            itemsPerPage: 5,
            liteQuestionAnswer: [],
            cityLoader:true,
            entitySetupCity:[]

        }
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)));
        const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
        if (isLite.isLite == 1) {
            if (currentRole[0].roleId !== ROLE_COMPANY_GROUP_ADMIN) {
                window.location.pathname = '/home';
            }
        } else {
            window.location.pathname = '/home';
        }
        this.props.getLiteCountriesListRequest();
        // this.props.getLiteCountriesEntityRequest({ countryId: this.props?.match?.params?.countriesId });
        this.props.getEntityDetailsRequest({ countryId: this.props?.match?.params?.countriesId, entitySetupId: this.props?.match?.params?.entitySetupId })
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
    }

    putEntityUpdate(value) {
        this.props.putLiteEntityRequest({ ...value });
    }

    generateTable(records) {
        let liteComplianceListArray = [];
        liteComplianceListArray = records && records?.list && records?.list?.length > 0 ? records?.list.map((compliance, i) => {
            return {
                complianceCode: compliance.complianceCode,
                complianceTitle: compliance.complianceTitle,
                companyGroupName: this.state.entityExtraData.companyGroup.companyGroupName,
                entityName: this.state?.entityExtraData?.entityName,
                countryName: compliance.law.country.countryName,
                stateName: compliance.law.state.stateName,
                complianceCategoryName: compliance?.complianceCategory.complianceCategoryName,
            };
        }) : [];
        return liteComplianceListArray;
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.liteCountriesList?.data?.list && nextprops.liteCountriesList?.data?.list !== this.props.liteCountriesList?.data?.list) {
            const findCountry = nextprops.liteCountriesList?.data?.list && nextprops.liteCountriesList?.data?.list?.length > 0 && nextprops.liteCountriesList?.data?.list?.find((element) => element.id == this.props.match.params.countriesId);
            if (!findCountry) {
                window.location.pathname = "/countries-list"
            }
            this.setState({
                countryList: nextprops.liteCountriesList?.data?.list
            })
        }
        if (nextprops.entityDetails?.list && nextprops.entityDetails?.list !== this.props.entityDetails?.list) {
            if (nextprops.entityDetails?.list?.length <= 0) {
                window.location.pathname = `/countries-list/${this.props.match.params.countriesId}`
                return;
            }
            // const cityCondition = this.state.entitySetupId ? nextprops.entityDetails && nextprops.entityDetails[0]?.liteEntitySetupList?.length > 0 && nextprops.entityDetails[0]?.liteEntitySetupList.find((element) => element.id == this.state.entitySetupId) : nextprops?.entityDetails[0]?.liteEntitySetupList[0];
            // const cityArray = cityCondition.liteEntityLocationList?.length > 0 && cityCondition.liteEntityLocationList.map((location) => {
            //     return { id: location?.city?.id, name: location?.city?.cityName, stateId: location.state?.id }
            // });
            // const filterCity = cityArray && cityArray.length > 0 && cityArray.filter((element) => {
            //     const isSetupIdExist = this.state.entitySetupId ? nextprops.entityDetails && nextprops.entityDetails[0]?.liteEntitySetupList?.length > 0 && nextprops.entityDetails[0]?.liteEntitySetupList.find((element) => element.id == this.state.entitySetupId) : nextprops.entityDetails[0].liteEntitySetupList[0];
            //     return isSetupIdExist.liteEntityLocationList.find((ele) => ele.state.id == element.stateId)
            // }).map((city) => city.id);
            this.setState({
                entitySetupData: this.state.entitySetupId ? nextprops.entityDetails?.list && nextprops.entityDetails?.list[0]?.liteEntitySetupList?.length > 0 && nextprops.entityDetails?.list[0]?.liteEntitySetupList.find((element) => element.id == this.state.entitySetupId) : nextprops.entityDetails?.list[0].liteEntitySetupList[0],
                entityExtraData: nextprops.entityDetails?.list[0],
                // filterCityId: filterCity,
                editEntityLoader:false,
                activeTab: this.state.activeTab ? this.state.activeTab : `location${nextprops.entityDetails?.list[0]?.liteEntitySetupList[0]?.id}`,
                entitySetupId: this.state.entitySetupId ?? nextprops.entityDetails?.list[0]?.liteEntitySetupList[0]?.id
            })
            this.props.getLiteComplianceListRequest({
                page: this.state.currentPage,
                limit: this.state.pageSize,
                orderBy: this.state.sortBy,
                filter: {
                    countryId: parseInt(this.props.match.params.countriesId),
                    stateId: nextprops.entityDetails?.stateList && nextprops.entityDetails?.stateList.length > 0 && nextprops.entityDetails?.stateList.map((location) => location?.id),
                    // cityId: nextprops.entityDetails?.list[0]?.liteEntitySetupList[0]?.liteEntityLocationList.map((location) => location?.city?.id),
                    industryId: [nextprops.entityDetails?.list[0]?.liteEntitySetupList[0].industryId],
                    subIndustryId: nextprops.entityDetails?.list[0]?.liteEntitySetupList[0]?.liteEntityPropertyList.map((element) => {
                        if (element.type == 2) {
                            return element.subIndustryId
                        }
                        return null
                    }).filter((ele) => ele),
                    entityTypeId: [nextprops.entityDetails?.list[0]?.liteEntitySetupList[0].entityTypeId],
                    facilityTypeId: nextprops.entityDetails?.list[0]?.liteEntitySetupList[0]?.liteEntityPropertyList.map((element) => {
                        if (element.type == 1) {
                            return element.facilityTypeId
                        }
                        return null
                    }).filter((ele) => ele),
                    entityId: parseInt(this.props?.match?.params?.entityId),
                    entitySetUpId: this.state.entitySetupId ? this.state.entitySetupId : nextprops.entityDetails?.list[0]?.liteEntitySetupList[0]?.id,
                    lawCategoryId: [],
                    applicabilityId: [],
                    subApplicabilityId: []
                }
            })
            // this.props.getLiteCityListRequest({
            //     filter: {
            //         countryId: parseInt(this.props.match.params.countriesId),
            //         stateId: nextprops.entityDetails[0]?.liteEntitySetupList[0]?.liteEntityLocationList.map((location) => location?.state?.id),
            //         cityId: nextprops.entityDetails[0]?.liteEntitySetupList[0]?.liteEntityLocationList.map((location) => location?.city?.id),
            //     }
            // })
        }
        if (nextprops.updateEntityApiResponse && nextprops.updateEntityApiResponse !== this.props.updateEntityApiResponse) {
            if (nextprops.updateEntityApiResponse.responseType == SUCCESS) {
                this.props.getEntityDetailsRequest({ countryId: this.props?.match?.params?.countriesId, entitySetupId: this.props?.match?.params?.entitySetupId })
                this.setState({
                    isEdit: false,
                })
            }
            this.setState({
                entityAddLoader:false
            })
            toastrMessage(nextprops.updateEntityApiResponse.message, nextprops.updateEntityApiResponse.responseType)
        }
        if (nextprops.listComplianceApiResponse && nextprops.listComplianceApiResponse !== this.props.listComplianceApiResponse) {
            const activeListOfQuestion = this.state.liteComplianceList?.questionnaireList && this.state.liteComplianceList?.questionnaireList.length > 0 && this.state.liteComplianceList?.questionnaireList.map((element) => {
                return {
                    id: element.liteQuestionId,
                    isMultipleSelection:element?.liteQuestion?.isMultipleSelection,
                    questionText: element.liteQuestion.questionText,
                    liteQuestionOptionList: element.liteQuestion.liteQuestionOptionList,
                    liteClientResponselist: [
                        {
                            liteQuestionId: element.liteQuestionId,
                            liteClientAnswerList: element.liteClientAnswerList && element.liteClientAnswerList.length > 0 ? element.liteClientAnswerList.map((opt) => {
                                return {
                                    liteQuestionOptionId: opt.liteQuestionOptionId
                                }
                            }) : []
                        }
                    ]
                }
            });
            const liteQuestionAnswerMap = this.state.liteComplianceList?.questionnaireList && this.state.liteComplianceList?.questionnaireList.length > 0 && this.state.liteComplianceList?.questionnaireList.map((element)=>{
                return {
                    liteQuestionId: element.liteQuestionId,
                    questionOptionId: element.liteClientAnswerList && element.liteClientAnswerList.length > 0 ? element.liteClientAnswerList.map((opt) => {
                        return opt.liteQuestionOptionId
                    }) : []
                }
            })
            this.setState({
                liteComplianceList: nextprops.listComplianceApiResponse.data,
                liteComplianceListTable: this.generateTable(nextprops.listComplianceApiResponse.data),
                totalRecords:nextprops.listComplianceApiResponse.data.totalRecords,
                currentPage:nextprops.listComplianceApiResponse.data.currentPage,
                questionAnswerLoader:false,
                liteQuestionList: activeListOfQuestion,
                liteQuestionAnswer:liteQuestionAnswerMap,
                pageCount:nextprops.listComplianceApiResponse.data.totalPages

            })
        }
        if (nextprops.liteOfQuestionAsPerPracticeAreaApiResponse && nextprops.liteOfQuestionAsPerPracticeAreaApiResponse !== this.props.liteOfQuestionAsPerPracticeAreaApiResponse) {
            this.setState({
                liteQuestionList: nextprops.liteOfQuestionAsPerPracticeAreaApiResponse.list,
                questionAnswerLoader:false
            })
        }
        if (nextprops.liteClientQuestionResponseApiResponse && nextprops.liteClientQuestionResponseApiResponse !== this.props.liteClientQuestionResponseApiResponse) {
            if (nextprops.liteClientQuestionResponseApiResponse.responseType == SUCCESS) {
                this.setState({
                    liteQuestionList: null,
                    isModalOpen: false,
                    answerPracticeArea: null
                })
                this.props.getEntityDetailsRequest({ countryId: this.props?.match?.params?.countriesId, entitySetupId: this.props?.match?.params?.entitySetupId })
            }
            toastrMessage(nextprops.liteClientQuestionResponseApiResponse.message, nextprops.liteClientQuestionResponseApiResponse.responseType)
        }
        if (nextprops.resetQuestionApiResponse && nextprops.resetQuestionApiResponse !== this.props.resetQuestionApiResponse) {
            toastrMessage(nextprops.resetQuestionApiResponse.message, nextprops.resetQuestionApiResponse.responseType)
        }
        if(nextprops.liteEntitySetupStateCityApiResponse && nextprops.liteEntitySetupStateCityApiResponse !== this.props.liteEntitySetupStateCityApiResponse){
            this.setState({
                cityLoader:false,
                entitySetupCity: nextprops.liteEntitySetupStateCityApiResponse?.citiList,
                liteEntityInitialValue:{
                    ...this.state.liteEntityInitialValue,
                    cityId: nextprops.liteEntitySetupStateCityApiResponse?.citiList && nextprops.liteEntitySetupStateCityApiResponse?.citiList.length > 0 && [...new Set(nextprops.liteEntitySetupStateCityApiResponse?.citiList.map((ele)=>ele.cityId))],
                }
            })
        }
    }

    toggle(tab) {
        if (this.state.stateTab !== tab) {
            this.setState({
                stateTab: tab,
            }, () => {
                // const cityArray = this.state.entitySetupData && this.state.entitySetupData?.liteEntityLocationList && this.state.entitySetupData?.liteEntityLocationList?.length > 0 && this.state.entitySetupData?.liteEntityLocationList.map((location) => {
                //     return { id: location?.city?.id, name: location?.city?.cityName, stateId: location.state?.id }
                // });
                // const filterCity = cityArray && cityArray.length > 0 && cityArray.filter((element) => {
                //     return element.stateId == tab
                // }).map((city) => city.id);
                // this.setState({
                //     filterCityId: filterCity
                // })
                this.props.getEntitySetupStateAndCityRequest({stateId:tab,entitySetupId:this.state.entitySetupId});
            });
        }
    }

    getLiteComplianceList = (limit, page, sortBy, values = {}) => {
        let apiData = {
            limit: limit,
            page: page,
            filters: values,
            orderBy: sortBy,
            filter: {
                entitySetUpId: this.state.entitySetupId,
                entityId: parseInt(this.props?.match?.params?.entityId),
                countryId: parseInt(this.props.match.params.countriesId),
                stateId: this.props.entityDetails.stateList && this.props.entityDetails.stateList.length > 0 && this.props.entityDetails.stateList.map((location) => location?.id),
                // cityId: this.state.entitySetupData.liteEntityLocationList && this.state.entitySetupData.liteEntityLocationList.length > 0 && this.state.entitySetupData.liteEntityLocationList.map((location) => location?.city?.id),
                industryId: [this.state.entitySetupData.industryId],
                subIndustryId: this.state.entitySetupData?.liteEntityPropertyList && this.state.entitySetupData?.liteEntityPropertyList.length > 0 && this.state.entitySetupData?.liteEntityPropertyList.map((element) => {
                    if (element.type == 2) {
                        return element.subIndustryId
                    }
                    return null
                }).filter((ele) => ele),
                entityTypeId: [this.state.entitySetupData.entityTypeId],
                facilityTypeId: this.state.entitySetupData.liteEntityPropertyList && this.state.entitySetupData.liteEntityPropertyList.length > 0 && this.state.entitySetupData.liteEntityPropertyList.map((element) => {
                    if (element.type == 1) {
                        return element.facilityTypeId
                    }
                    return null
                }).filter((ele) => ele),
                lawCategoryId: [],
                applicabilityId: [],
                subApplicabilityId: []
            }
        };
        this.props.getLiteComplianceListRequest(apiData);
    };

    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getLiteComplianceList(data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData)
                }
            );
        }
    }
    handleSearch(data) {
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                if (data.globalFilter) {
                    this.getLiteComplianceList(this.state.pageSize,
                        this.state.currentPage,
                        this.state.sortBy,
                        this.state.searchFormData)
                }
            }
        );
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getLiteComplianceList(data.pageSize, data.pageIndex + 1, { key: data.sortBy[0]?.id, value: sortOrder }, this.state.searchFormData)
                }
            );
        }
    }

    prevPage = () => {
        const { currentQuestionPage } = this.state;
        if (currentQuestionPage > 1) {
            this.setState({ currentQuestionPage: currentQuestionPage - 1 });
        }
    };
    goToPage = (page) => {
        this.setState({ currentQuestionPage: page });
    };
    nextPage = () => {
        const { currentQuestionPage, itemsPerPage } = this.state;
        const totalPages = Math.ceil(this.state.liteQuestionList?.length / itemsPerPage);
        if (currentQuestionPage < totalPages) {
            this.setState({ currentQuestionPage: currentQuestionPage + 1 });
        }
    };
    render() {
        const findCountry = this.state?.countryList && this.state?.countryList?.length > 0 && this.state?.countryList.find((element) => element.id == this.props.match.params.countriesId);
        // const StateName = this.state.entitySetupData && this.state.entitySetupData?.liteEntityLocationList && this.state.entitySetupData?.liteEntityLocationList?.length > 0 && this.state.entitySetupData?.liteEntityLocationList.map((location) => {
        //     return { id: location?.state?.id, name: location?.state?.stateName }
        // })
        // const CityName = this.state.entitySetupData && this.state.entitySetupData?.liteEntityLocationList && this.state.entitySetupData?.liteEntityLocationList?.length > 0 && this.state.entitySetupData?.liteEntityLocationList.map((location) => {
        //     return { id: location?.city?.id, name: location?.city?.cityName, stateId: location.state?.id }
        // })
        const facilityType = this.state.entitySetupData && this.state.entitySetupData?.liteEntityPropertyList && this.state.entitySetupData?.liteEntityPropertyList?.length > 0 && this.state.entitySetupData?.liteEntityPropertyList.map((facility) => {
            if (facility?.type == 1) {
                return { id: facility?.facilityType?.id, name: facility?.facilityType?.facilityTypeName }
            }
        }).filter((ele) => ele)
        const SubIndustry = this.state.entitySetupData && this.state.entitySetupData?.liteEntityPropertyList && this.state.entitySetupData?.liteEntityPropertyList?.length > 0 && this.state.entitySetupData?.liteEntityPropertyList.map((subindustry) => {
            if (subindustry?.type == 2) {
                return { id: subindustry?.subIndustry?.id, name: subindustry?.subIndustry?.subIndustryName }
            }
        }).filter((ele) => ele)
        const data = {
            columns: ComplianceGeneratorHeader,
            rows: this.state.liteComplianceListTable,
        };
        const { currentQuestionPage, itemsPerPage } = this.state;

        // Calculate the index range for the current page
        const indexOfLastItem = currentQuestionPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const liteQuestionList = this.state.liteQuestionList && this.state.liteQuestionList.length > 0 && this.state.liteQuestionList.slice(indexOfFirstItem, indexOfLastItem);
        // Calculate the total number of pages
        const totalPages = Math.ceil(this.state.liteQuestionList?.length / itemsPerPage);
        return <Row>
            {
                this.state.editEntityLoader ? <div className="d-flex justify-content-center align-items-center w-100"><Spinner /></div> : <>
                    <Col lg="12">
                        <CommonBreadcrum type={'countries-list-entity-edit'} countryId={this.props?.match?.params?.countriesId} entityName={this.state?.entityExtraData?.entityName} />
                    </Col>
                    <Card>
                        <CardHeader>
                            <div className="page-header">
                                <h4 className="with-back-btn">
                                    <Link
                                        to={`/countries-list/${this.props?.match?.params?.countriesId}`}
                                        className="dripicons-arrow-thin-left"
                                    >
                                        { }
                                    </Link>
                                    {this.state?.entityExtraData?.entityName} Details
                                </h4>
                                {
                                    findCountry && <span className='event_country'>
                                        <span className="">
                                            <img src={`/data/flags/${findCountry.isoCode2}.svg`} style={{ height: "1rem" }} />
                                        </span>
                                        <span>{findCountry?.countryName}</span>
                                    </span>
                                }

                            </div>
                        </CardHeader>

                        <CardBody className="tabs-main entity-detil">
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                {
                                    this.props.entityDetails?.liteEntitySetupList && this.props.entityDetails?.liteEntitySetupList?.length > 0 && this.props.entityDetails?.liteEntitySetupList.map((element, index) => {
                                        return <NavItem key={index}>
                                            <NavLink
                                                className={classnames({
                                                    active:
                                                        this.state.activeTab === `location${element.id}`,
                                                })}
                                                onClick={() => {
                                                    this.setState({
                                                        activeTab: `location${element.id}`,
                                                    })
                                                    // window.location.href=`/countries-list/${findCountry?.id}/entity/${element.entityId}/configured-entity/${element.id}`
                                                    // const stateId = [element.liteEntityLocationList[0].state.id];
                                                    // const cityNameExtract = element.liteEntityLocationList && element?.liteEntityLocationList && element?.liteEntityLocationList?.length > 0 && element?.liteEntityLocationList.map((location) => {
                                                    //     return { id: location?.city?.id, name: location?.city?.cityName, stateId: location.state?.id }
                                                    // })
                                                    // const filterCity = cityNameExtract && cityNameExtract.length > 0 && cityNameExtract.filter((city) => {
                                                    //     return stateId.includes(city.stateId);
                                                    // })?.map((ele) => {
                                                    //     return ele.id
                                                    // });
                                                    this.setState({
                                                        activeTab: `location${element.id}`,
                                                        isEdit: false,
                                                        entitySetupData: element,
                                                        // filterCityId: filterCity,
                                                        entitySetupId: element.id,
                                                        currentPage:1,
                                                    })
                                                    // this.props.getLiteComplianceListRequest({
                                                    //     page: this.state.currentPage,
                                                    //     limit: this.state.pageSize,
                                                    //     orderBy: this.state.sortBy,
                                                    //     filter: {
                                                    //         entityId: parseInt(this.props?.match?.params?.entityId),
                                                    //         entitySetUpId: element.id,
                                                    //         countryId: parseInt(this.props.match.params.countriesId),
                                                    //         stateId: this.props.entityDetails?.stateList && this.props.entityDetails?.stateList.length > 0 && this.props.entityDetails?.stateList.map((ele) => ele?.id),
                                                    //         // cityId: element.liteEntityLocationList && element.liteEntityLocationList.length > 0 && element.liteEntityLocationList.map((location) => location?.city?.id),
                                                    //         industryId: [element.industryId],
                                                    //         subIndustryId: element?.liteEntityPropertyList && element?.liteEntityPropertyList.length > 0 && element?.liteEntityPropertyList.map((element) => {
                                                    //             if (element.type == 2) {
                                                    //                 return element.subIndustryId
                                                    //             }
                                                    //             return null
                                                    //         }).filter((ele) => ele),
                                                    //         entityTypeId: [element.entityTypeId],
                                                    //         facilityTypeId: element.liteEntityPropertyList && element.liteEntityPropertyList.length > 0 && element.liteEntityPropertyList.map((element) => {
                                                    //             if (element.type == 1) {
                                                    //                 return element.facilityTypeId
                                                    //             }
                                                    //             return null
                                                    //         }).filter((ele) => ele),
                                                    //         lawCategoryId: [],
                                                    //         applicabilityId: [],
                                                    //         subApplicabilityId: []
                                                    //     }
                                                    // })
                                                    this.props.getEntityDetailsRequest({ countryId: this.props?.match?.params?.countriesId, entitySetupId: element.id })

                                                    this.props.history.push(`/countries-list/${findCountry?.id}/entity/${element.entityId}/configured-entity/${element.id}`)
                                                }}

                                            >
                                                {__t("lite.location")} {index + 1}
                                            </NavLink>
                                            {
                                                this.state.activeTab == `location${element.id}` ? <Button color="link" size="sm" title="Edit Entity Detail" onClick={() => {
                                                    const stateId = this.props.entityDetails?.stateList && this.props.entityDetails?.stateList.length > 0 && [...new Set(this.props.entityDetails?.stateList.map((ele) => ele?.id))]
                                                    this.setState({
                                                        isEdit: true,
                                                        entitySetupId: element.id,
                                                        liteEntityInitialValue: {
                                                            industryId: element?.industryId,
                                                            entityId: element?.entityId,
                                                            managementUser: this.state?.entityExtraData?.userRoles[0]?.user?.email,
                                                            managementUserId: this.state?.entityExtraData?.userRoles[0]?.user?.id,
                                                            subIndustryId: SubIndustry && SubIndustry.length > 0 && SubIndustry.map((ele) => ele.id),
                                                            financialYearStart: this.state?.entityExtraData?.entityFinancialYears && this.state?.entityExtraData?.entityFinancialYears.length > 0 ? this.state?.entityExtraData?.entityFinancialYears[0]?.startMonth : "",
                                                            financialYearEnd: this.state?.entityExtraData?.entityFinancialYears && this.state?.entityExtraData?.entityFinancialYears.length > 0 ? this.state?.entityExtraData?.entityFinancialYears[0]?.endMonth : "",
                                                            entityLocations: this.state.entitySetupData && this.state.entitySetupData?.liteEntityLocationList && this.state.entitySetupData?.liteEntityLocationList.length > 0 && this.state.entitySetupData?.liteEntityLocationList.map((loc) => {
                                                                return {
                                                                    stateId: loc?.state?.id,
                                                                    cityId: loc?.city?.id
                                                                }
                                                            }),
                                                            stateId: stateId,
                                                            // cityId: CityName && CityName.length > 0 && [...new Set(CityName.map((ele) => ele?.id))],
                                                            entityTypeId: element?.entityTypeId,
                                                            facilityTypeId: facilityType && facilityType.length > 0 && facilityType.map((ele) => ele.id),
                                                            managementNewValue: true,
                                                            entityNewValue: false,
                                                        }
                                                    }, () => {
                                                        this.props.getEntitySetupStateAndCityRequest({stateId:0,entitySetupId:element.id});
                                                        this.props.getCityListByStateRequest((stateId) ? stateId : []);
                                                        this.props.getSubIndustryListByIndustryRequest([element?.industryId])
                                                        this.props.getStateListByCountryRequest((this.props?.match?.params?.countriesId) ? [this.props?.match?.params?.countriesId] : []);
                                                        this.props.getAllEntityTypeListRequest();
                                                        this.props.getAllFacilityTypeListRequest();
                                                        this.props.getIndustryListByStatusRequest(ACTIVE);
                                                        this.props.getLiteCountriesEntityRequest({ countryId: this.props?.match?.params?.countriesId });
                                                    })
                                                }}><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3601 3.07866L14.2869 2.15178C15.8226 0.616074 18.3125 0.616074 19.8482 2.15178C21.3839 3.68748 21.3839 6.17735 19.8482 7.71306L18.9213 8.63993M13.3601 3.07866C13.3601 3.07866 13.4759 5.04828 15.2138 6.78618C16.9517 8.52407 18.9213 8.63993 18.9213 8.63993M13.3601 3.07866L4.83882 11.5999C4.26166 12.1771 3.97308 12.4656 3.7249 12.7838C3.43213 13.1592 3.18114 13.5653 2.97634 13.995C2.80273 14.3593 2.67368 14.7465 2.41556 15.5208L1.32181 18.8021M18.9213 8.63993L10.4001 17.1612C9.82294 17.7383 9.53436 18.0269 9.21616 18.2751C8.84082 18.5679 8.43469 18.8189 8.00498 19.0237C7.6407 19.1973 7.25352 19.3263 6.47918 19.5844L3.19792 20.6782M3.19792 20.6782L2.39584 20.9456C2.01478 21.0726 1.59466 20.9734 1.31063 20.6894C1.0266 20.4053 0.927428 19.9852 1.05445 19.6042L1.32181 18.8021M3.19792 20.6782L1.32181 18.8021" stroke="currentColor" strokeWidth="1.5" /></svg></Button> : null
                                            }

                                        </NavItem>
                                    })
                                }
                            </Nav>

                            <TabContent className="card mb-0 overflow-hidden " activeTab={this.state.activeTab}>
                                {
                                    this.props.entityDetails?.list[0] && this.props.entityDetails?.list[0]?.liteEntitySetupList?.length > 0 && this.props.entityDetails?.list[0]?.liteEntitySetupList.map((element, index) => {
                                        return this.state.activeTab == `location${element.id}` ? <TabPane className="card-body view_details country-acc-wrap" tabId={`location${element.id}`} key={index}>
                                            {
                                                this.state.isEdit ?
                                                    <LiteEntityForm
                                                        sortedSubIndustryListDropdown={this.props.sortedSubIndustryListDropdown}
                                                        sortedStateListDropdown={this.props.sortedStateListDropdown}
                                                        sortedCityListDropdown={this.props.sortedCityListDropdown}
                                                        sortedIndustryListDropdown={this.props.sortedIndustryListDropdown}
                                                        sortedEntityTypeListDropDown={this.props.sortedEntityTypeListDropDown}
                                                        sortedFacilityTypeListDropDown={this.props.sortedFacilityTypeListDropDown}
                                                        sortedEntityListDropDown={this.props.sortedEntityListDropDown}
                                                        match={this.props.match}
                                                        getSubIndustryListByIndustryRequest={this.props.getSubIndustryListByIndustryRequest}
                                                        getLiteCountriesEntityRequest={this.props.getLiteCountriesEntityRequest}
                                                        listOfLiteEntity={this.props.listOfLiteEntity}
                                                        getStateListByCountryRequest={this.props.getStateListByCountryRequest}
                                                        getCityListByStateRequest={this.props.getCityListByStateRequest}
                                                        getIndustryListByStatusRequest={this.props.getIndustryListByStatusRequest}
                                                        getAllEntityTypeListRequest={this.props.getAllEntityTypeListRequest}
                                                        getAllFacilityTypeListRequest={this.props.getAllFacilityTypeListRequest}
                                                        getEntityListByCompanyGroupIdRequest={this.props.getEntityListByCompanyGroupIdRequest}
                                                        addLiteEntityRequest={this.props.addLiteEntityRequest}
                                                        liteEntityInitialValue={this.state.liteEntityInitialValue}
                                                        entityAddLoader={this.state.entityAddLoader}
                                                        entityExtraData={this.state.entityExtraData}
                                                        isEditEntity={true}
                                                        isEditEntityCancel={() => {
                                                            this.setState({
                                                                isEdit: false
                                                            })
                                                        }}
                                                        setEditValue={(val) => {
                                                            this.setState({
                                                                liteEntityInitialValue: val
                                                            })

                                                        }}
                                                        onFormSubmit={async(values) => {
                                                            let apiRequestValue = {
                                                                ...values,
                                                                entitySetupId: this.state.entitySetupId,
                                                                countryId: findCountry?.id,
                                                            }
                                                            if (values.entityNewValue) {
                                                                apiRequestValue.entityName = apiRequestValue.entityId;
                                                                delete apiRequestValue.entityId;
                                                            } else {
                                                                apiRequestValue.entityId = apiRequestValue.entityId;
                                                                delete apiRequestValue?.entityName;
                                                            }
                                                            if (values.managementNewValue) {
                                                                apiRequestValue.managementUser = apiRequestValue.managementUser;
                                                            } else {
                                                                apiRequestValue.managementUserId = apiRequestValue.managementUser;
                                                                delete apiRequestValue?.managementUser;
                                                            }
                                                            delete apiRequestValue.cityId;
                                                            delete apiRequestValue.stateId;
                                                            delete apiRequestValue.entityNewValue;
                                                            delete apiRequestValue.managementNewValue;
                                                            this.setState({
                                                                entityAddLoader:true
                                                            })
                                                            this.putEntityUpdate(apiRequestValue);
                                                        }}
                                                    />
                                                    :
                                                    this.state.activeTab == `location${element.id}` ? <> <ul className="basic-details m-0">
                                                        <li>
                                                            <div className="details-info">
                                                                <b>{__t('lite.entity.edit.state')}</b>
                                                                <Nav tabs className="nav-tabs-custom nav-justified task_tabs country-accordian">
                                                                    {
                                                                        this.props.entityDetails?.stateList && this.props.entityDetails?.stateList?.length > 0 && this.props.entityDetails?.stateList?.filter((value, index, self) =>
                                                                            index === self.findIndex((t) => t.id === value.id)
                                                                        ).map((element) => {
                                                                            return <StateAccordion className="accordion"
                                                                                viewDialog={this.state.stateTab == element.id}
                                                                                dataEvent={true}
                                                                                toggleCollapse={(daa) => {
                                                                                    this.toggle(element.id)
                                                                                }}
                                                                                headerComp={<NavItem>
                                                                                    <NavLink
                                                                                        className={classnames({ active: this.state.stateTab == element.id })}
                                                                                        onClick={(e) => {
                                                                                            this.toggle(element.id);
                                                                                        }}
                                                                                    >
                                                                                        {element?.stateName}
                                                                                    </NavLink>
                                                                                </NavItem>}
                                                                            >
                                                                                <ul className="city-list">

                                                                                    {this.state.cityLoader ? <Spinner /> :this.state.entitySetupCity &&
                                                                                        this.state.entitySetupCity.length > 0 &&
                                                                                        this.state.entitySetupCity.sort((a, b) =>  a?.cityName.localeCompare(b?.cityName)) // Sort cities alphabetically
                                                                                            .map((ele, index) => (
                                                                                                <li key={index}>{ele?.cityName}</li>
                                                                                            ))}
                                                                                </ul>
                                                                            </StateAccordion>
                                                                        })
                                                                    }

                                                                </Nav>
                                                                {/* <p className='m-0'>{StateName && StateName.length > 0 && [...new Set(StateName.map((ele) => ele?.name))].join(",")}</p> */}
                                                            </div>
                                                        </li>

                                                    </ul>
                                                        <ul className="basic-details m-0">
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>{__t('lite.entity.edit.management_user')}</b>
                                                                    <p className='m-0'>{this.state?.entityExtraData?.userRoles && this.state?.entityExtraData?.userRoles.length > 0 && this.state?.entityExtraData?.userRoles[0]?.user?.email}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>{__t('lite.entity.edit.financial_year')}</b>
                                                                    <p className='m-0'>{this.state?.entityExtraData?.entityFinancialYears && this.state?.entityExtraData?.entityFinancialYears.length > 0 && `${this.state?.entityExtraData?.entityFinancialYears[0]?.startMonth} - ${this.state?.entityExtraData?.entityFinancialYears[0]?.endMonth}`}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>{__t('lite.entity.edit.entity_type')}</b>
                                                                    <p className='m-0'>{this.state?.entitySetupData?.entityType?.entityTypeName}</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className="basic-details m-0">
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>{__t('lite.entity.edit.facility_type')}</b>
                                                                    <p className='m-0'>{facilityType && facilityType.length > 0 && facilityType.map((ele) => ele.name).join(",")}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>{__t('lite.entity.edit.industry')}</b>
                                                                    <p className='m-0'>{this.state?.entitySetupData?.industry?.industryName}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>{__t('lite.entity.edit.sub_industry')}</b>
                                                                    <p className='m-0'>{SubIndustry && SubIndustry.length > 0 && SubIndustry.map((ele) => ele.name).join(",")}</p>
                                                                </div>
                                                            </li>
                                                        </ul></> : null
                                            }
                                            <Card className="mt-3">
                                                <CardHeader>
                                                    <div className="page-header d-flex justify-content-between w-100">
                                                        <div>
                                                            <h4 className="with-back-btn">{`${this.state?.entityExtraData?.entityName} > Compliance List`}</h4>
                                                        </div>
                                                        <div>
                                                            <Button className="mx-3" disabled={this.state.liteComplianceList?.questionnaireList && this.state.liteComplianceList?.questionnaireList.length <= 0} onClick={() => {
                                                                this.props.setResetQuestionnaireRequest({
                                                                    entityId: parseInt(this.props?.match?.params?.entityId),
                                                                    entity_setup_id: parseInt(this.state.activeTab.split("location")[1])
                                                                })
                                                                this.props.getEntityDetailsRequest({ countryId: this.props?.match?.params?.countriesId, entitySetupId: this.props?.match?.params?.entitySetupId })
                                                            }}>{__t("lite.reset_questionnaire")}</Button>
                                                            {
                                                                this.state.liteComplianceList?.questionnaireList?.length > 0 ? <Button color="success" type="button" onClick={() => {
                                                                    const activeListOfQuestion = this.state.liteComplianceList?.questionnaireList && this.state.liteComplianceList?.questionnaireList.length > 0 && this.state.liteComplianceList?.questionnaireList.map((element) => {
                                                                        return {
                                                                            id: element.liteQuestionId,
                                                                            isMultipleSelection:element?.liteQuestion?.isMultipleSelection,
                                                                            questionText: element.liteQuestion.questionText,
                                                                            liteQuestionOptionList: element.liteQuestion.liteQuestionOptionList,
                                                                            liteClientResponselist: [
                                                                                {
                                                                                    liteQuestionId: element.liteQuestionId,
                                                                                    liteClientAnswerList: element.liteClientAnswerList && element.liteClientAnswerList.length > 0 ? element.liteClientAnswerList.map((opt) => {
                                                                                        return {
                                                                                            liteQuestionOptionId: opt.liteQuestionOptionId
                                                                                        }
                                                                                    }) : []
                                                                                }
                                                                            ]
                                                                        }
                                                                    });
                                                                    const liteQuestionAnswerMap = this.state.liteComplianceList?.questionnaireList && this.state.liteComplianceList?.questionnaireList.length > 0 && this.state.liteComplianceList?.questionnaireList.map((element)=>{
                                                                        return {
                                                                            liteQuestionId: element.liteQuestionId,
                                                                            questionOptionId: element.liteClientAnswerList && element.liteClientAnswerList.length > 0 ? element.liteClientAnswerList.map((opt) => {
                                                                                return opt.liteQuestionOptionId
                                                                            }) : []
                                                                        }
                                                                    })
                                                                    this.setState({
                                                                        isModalOpen: true,
                                                                        currentQuestionPage: 1,
                                                                        liteQuestionList: activeListOfQuestion,
                                                                        liteQuestionAnswer:liteQuestionAnswerMap
                                                                    })
                                                                }}>{__t("lite.edit_want_more_focused")}</Button> : <Button color="info" type="button" onClick={() => {
                                                                    this.setState({
                                                                        isModalOpen: true,
                                                                        currentQuestionPage: 1,
                                                                    })
                                                                }}>{__t("lite.want_more_focused")}</Button>
                                                            }


                                                            <CustomisedModal
                                                                modalSize={
                                                                    'modal-xxl modal-dialog-centered entity-modal'
                                                                }
                                                                modalName={"Questionnaire"}
                                                                isModalOpen={this.state.isModalOpen}
                                                                onModalDismiss={() =>
                                                                    this.setState({
                                                                        isModalOpen: false,
                                                                        liteQuestionList: null,
                                                                        answerPracticeArea: null,
                                                                    })
                                                                }
                                                                className="d-flex flex-column"
                                                            >
                                                                <Progress
                                                                    style={{
                                                                    height: '0.4rem'
                                                                    }}
                                                                    value={currentQuestionPage === totalPages ? 100 : (currentQuestionPage/totalPages)*100}
                                                                />
                                                                <div>
                                                                    <div className="legal-update-status">
                                                                        <FormGroup className="mb-0 form-group">
                                                                            <Select
                                                                                name="lawCategoryId[]"
                                                                                className="form-control selectbox"
                                                                                options={
                                                                                    this.props
                                                                                        .sortedLawCategoryListDropdown
                                                                                }
                                                                                onChange={(opt) => {
                                                                                    let selectedValues = opt
                                                                                        ? opt.map((x) => x.value)
                                                                                        : [];
                                                                                    this.setState({
                                                                                        answerPracticeArea: selectedValues,
                                                                                        questionAnswerLoader:true,
                                                                                    })
                                                                                    if(selectedValues.length > 0){
                                                                                        this.props.getLiteQuestionRequest({
                                                                                            filter: {
                                                                                                entityId: parseInt(this.props?.match?.params?.entityId),
                                                                                                entitySetUpId: this.state.entitySetupId,
                                                                                                countryId: parseInt(this.props.match.params.countriesId),
                                                                                                stateId: this.props.entityDetails?.stateList && this.props.entityDetails?.stateList.length > 0 && this.props.entityDetails?.stateList.map((ele) => ele?.id),
                                                                                                // cityId: this.state.entitySetupData.liteEntityLocationList && this.state.entitySetupData.liteEntityLocationList.length > 0 && this.state.entitySetupData.liteEntityLocationList.map((location) => location?.city?.id),
                                                                                                industryId: [this.state.entitySetupData.industryId],
                                                                                                subIndustryId: this.state.entitySetupData?.liteEntityPropertyList && this.state.entitySetupData?.liteEntityPropertyList.length > 0 && this.state.entitySetupData?.liteEntityPropertyList.map((element) => {
                                                                                                    if (element.type == 2) {
                                                                                                        return element.subIndustryId
                                                                                                    }
                                                                                                    return null
                                                                                                }).filter((ele) => ele),
                                                                                                entityTypeId: [this.state.entitySetupData.entityTypeId],
                                                                                                facilityTypeId: this.state.entitySetupData.liteEntityPropertyList && this.state.entitySetupData.liteEntityPropertyList.length > 0 && this.state.entitySetupData.liteEntityPropertyList.map((element) => {
                                                                                                    if (element.type == 1) {
                                                                                                        return element.facilityTypeId
                                                                                                    }
                                                                                                    return null
                                                                                                }).filter((ele) => ele),
                                                                                                lawCategoryId: selectedValues,
                                                                                            }
                                                                                        })
                                                                                    }else{
                                                                                        this.getLiteComplianceList(this.state.pageSize,
                                                                                            this.state.currentPage,
                                                                                            this.state.sortBy,
                                                                                            this.state.searchFormData)
                                                                                    }
                                                                                }}
                                                                                value={this.props.sortedLawCategoryListDropdown?.filter(
                                                                                    (option) =>
                                                                                        this.state.answerPracticeArea?.includes(
                                                                                            option.value
                                                                                        )
                                                                                )}
                                                                                isMulti
                                                                                closeMenuOnSelect={true}
                                                                                hideSelectedOptions={false}
                                                                                allowSelectAll={true}
                                                                                key="lawCategoryId"
                                                                            />
                                                                            <Label className="form-label">
                                                                                Practice Area
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </div>
                                                                    <div className="question-list">
                                                                        {
                                                                            this.state.questionAnswerLoader ? <div className="d-flex justify-content-center w-100"><Spinner className="d-flex justify-content-center"/></div> :liteQuestionList && liteQuestionList.map((question, index) => {
                                                                                const findQuestion = question.liteClientResponselist && question.liteClientResponselist.length > 0 && question.liteClientResponselist.find((ele) => ele.liteQuestionId == question.id);
                                                                                return <div className="entity-list-item" key={index}>
                                                                                    <p>
                                                                                        <span>{(this.state.currentQuestionPage * this.state.itemsPerPage) - (this.state.itemsPerPage - (index + 1))}.</span>{question.questionText}
                                                                                    </p>
                                                                                    <div className="input-group">
                                                                                        {
                                                                                            question.liteQuestionOptionList && question.liteQuestionOptionList.length > 0 && question.liteQuestionOptionList.map((option, ind) => {
                                                                                                const findOption = findQuestion?.liteClientAnswerList && findQuestion?.liteClientAnswerList.length > 0 && findQuestion?.liteClientAnswerList.find((ele) => ele.liteQuestionOptionId == option.id);
                                                                                                return <div className="" key={ind}>
                                                                                                    <input className="cl-custom-check" id={`opt${option.id}`} checked={findOption?.liteQuestionOptionId == option.id} name={question.isMultipleSelection ? `opt${option.id}` : `opt${index}`} type={question.isMultipleSelection ? "checkbox" : "checkbox"} onChange={(e) => {
                                                                                                        // debugger;
                                                                                                        let DuplicateOfLiteQuestionAnswer = this.state.liteQuestionAnswer && this.state.liteQuestionAnswer.length > 0 ? [...this.state.liteQuestionAnswer] : [];
                                                                                                        const findQuestion = DuplicateOfLiteQuestionAnswer.length > 0 && DuplicateOfLiteQuestionAnswer.find((ele) => ele.liteQuestionId == question.id);
                                                                                                        const optionsArray = findQuestion?.questionOptionId && findQuestion?.questionOptionId.length > 0 ? [...findQuestion.questionOptionId] : [];
                                                                                                        const isAlreadyExist = optionsArray && optionsArray.length > 0 && optionsArray.find((ele) => ele == option.id);
                                                                                                        if (isAlreadyExist) {
                                                                                                            const removeAlreadyExistOption = this.state.liteQuestionList && this.state.liteQuestionList.length > 0 && this.state.liteQuestionList.map((saveQuestion)=>{
                                                                                                                 if(saveQuestion.id == question.id){
                                                                                                                    const alreadyExistOption = [...saveQuestion.liteClientResponselist];
                                                                                                                    const removeAlreadyExistOptionId = alreadyExistOption && alreadyExistOption.length > 0 && alreadyExistOption.map((element)=> {
                                                                                                                        if(element.liteQuestionId == question.id){
                                                                                                                            const removeOptionId = element.liteClientAnswerList && element.liteClientAnswerList.length >0 && element.liteClientAnswerList.filter((filter)=>filter.liteQuestionOptionId !== isAlreadyExist);
                                                                                                                            return {
                                                                                                                                ...element,
                                                                                                                                liteClientAnswerList:removeOptionId
                                                                                                                            }
                                                                                                                        }else{
                                                                                                                            return element
                                                                                                                        }
                                                                                                                    });
                                                                                                                    return {
                                                                                                                        ...saveQuestion,
                                                                                                                        liteClientResponselist:question.isMultipleSelection ?removeAlreadyExistOptionId :[]
                                                                                                                    }
                                                                                                                 }else{
                                                                                                                    return saveQuestion
                                                                                                                 }
                                                                                                            });
                                                                                                            const filterAnswer = question.isMultipleSelection ? this.state.liteQuestionAnswer && this.state.liteQuestionAnswer.length > 0 && this.state.liteQuestionAnswer.map((element)=>{
                                                                                                                if(element.liteQuestionId == question.id){
                                                                                                                    const filterOption  = element.questionOptionId && element.questionOptionId.length >0 && element.questionOptionId.filter((questionOptionIds)=> questionOptionIds !== option.id);
                                                                                                                    if(filterOption.length > 0){
                                                                                                                        return {
                                                                                                                            ...element,
                                                                                                                            questionOptionId:filterOption
                                                                                                                        }
                                                                                                                    }else{
                                                                                                                        return null
                                                                                                                    }
                                                                                                                }
                                                                                                                return element;
                                                                                                            }).filter((ele)=>ele) : this.state.liteQuestionAnswer &&this.state.liteQuestionAnswer.length > 0 && this.state.liteQuestionAnswer.filter((ele)=> ele.liteQuestionId !== question.id)
                                                                                                            this.setState({
                                                                                                                liteQuestionList:removeAlreadyExistOption,
                                                                                                                liteQuestionAnswer: filterAnswer
                                                                                                            })

                                                                                                        } else {
                                                                                                            optionsArray.push(option.id);
                                                                                                            if (question.isMultipleSelection) {
                                                                                                                const isExist = DuplicateOfLiteQuestionAnswer && DuplicateOfLiteQuestionAnswer.length > 0 && DuplicateOfLiteQuestionAnswer.find((el) => el.liteQuestionId == question.id);
                                                                                                                if (isExist) {
                                                                                                                    const getAlreadyAddedOption = isExist.questionOptionId && isExist.questionOptionId.length > 0 ? [...isExist.questionOptionId] : [];
                                                                                                                    getAlreadyAddedOption.push(option.id)
                                                                                                                    DuplicateOfLiteQuestionAnswer = DuplicateOfLiteQuestionAnswer.map((duplicate) => {
                                                                                                                        if (duplicate.liteQuestionId == question.id) {
                                                                                                                            return {
                                                                                                                                ...duplicate,
                                                                                                                                questionOptionId: getAlreadyAddedOption
                                                                                                                            }
                                                                                                                        } else {
                                                                                                                            return duplicate
                                                                                                                        }
                                                                                                                    })
                                                                                                                } else {
                                                                                                                    DuplicateOfLiteQuestionAnswer.push({
                                                                                                                        liteQuestionId: question.id,
                                                                                                                        questionOptionId: optionsArray
                                                                                                                    });
                                                                                                                }
                                                                                                            } else {
                                                                                                                if (findQuestion) {
                                                                                                                    DuplicateOfLiteQuestionAnswer = DuplicateOfLiteQuestionAnswer.map((duplicateAns) => {
                                                                                                                        if (duplicateAns.liteQuestionId == question.id) {
                                                                                                                            return {
                                                                                                                                liteQuestionId: duplicateAns.liteQuestionId,
                                                                                                                                questionOptionId: [option.id]
                                                                                                                            }
                                                                                                                        }
                                                                                                                        return duplicateAns
                                                                                                                    })
                                                                                                                } else {
                                                                                                                    DuplicateOfLiteQuestionAnswer.push({
                                                                                                                        liteQuestionId: question.id,
                                                                                                                        questionOptionId: optionsArray
                                                                                                                    });
                                                                                                                }
                                                                                                            }
                                                                                                            const manipulatedTheLiteQuestionList = this.state.liteQuestionList && this.state.liteQuestionList.length > 0 && this.state.liteQuestionList.map((duplicate) => {
                                                                                                                const findQuestionLite = DuplicateOfLiteQuestionAnswer && DuplicateOfLiteQuestionAnswer.length > 0 && DuplicateOfLiteQuestionAnswer.find((ele) => ele.liteQuestionId == duplicate.id);
                                                                                                                if (findQuestionLite) {
                                                                                                                    const liteClientResponseListArray = [];
                                                                                                                    liteClientResponseListArray.push({
                                                                                                                        liteQuestionId: duplicate.id,
                                                                                                                        liteClientAnswerList: findQuestionLite.questionOptionId && findQuestionLite.questionOptionId.length > 0 && findQuestionLite.questionOptionId.map((ele) => {
                                                                                                                            return {
                                                                                                                                liteQuestionOptionId: ele
                                                                                                                            }
                                                                                                                        })
                                                                                                                    })
                                                                                                                    return {
                                                                                                                        ...duplicate,
                                                                                                                        liteClientResponselist: liteClientResponseListArray
                                                                                                                    }
                                                                                                                } else {
                                                                                                                    return duplicate
                                                                                                                }
                                                                                                            })
                                                                                                            this.setState({
                                                                                                                liteQuestionAnswer: DuplicateOfLiteQuestionAnswer,
                                                                                                                liteQuestionList: manipulatedTheLiteQuestionList
                                                                                                            })
                                                                                                        }
                                                                                                    }} />
                                                                                                    <label className={`cl-custom-check-label btn ${findOption ? "btn-success" : "btn-outline-primary"} `} htmlFor={`opt${option.id}`} title="Language">{option.answerText}</label>
                                                                                                </div>
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        {/* 
                                                                        <div className="entity-list-item">
                                                                            <p>
                                                                                <span>2.</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                                                            </p>
                                                                            <div className="input-group">
                                                                                <div className="">
                                                                                    <input className="cl-custom-check" id="Language1" type="checkbox" />
                                                                                    <label className="cl-custom-check-label" for="Language1" title="Language">Ante mi ullamcorpor</label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <input className="cl-custom-check" id="Framwork1" type="checkbox" />
                                                                                    <label className="cl-custom-check-label" for="Framwork1" title="Framwork">Framwork</label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <input className="cl-custom-check" id="Programming1" type="checkbox" />
                                                                                    <label className="cl-custom-check-label" for="Programming1" title="Programming">Programming</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="entity-list-item">
                                                                            <p>
                                                                                <span>3.</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                                                            </p>
                                                                            <div className="input-group">
                                                                                <div className="">
                                                                                    <input className="cl-custom-check" id="Language2" type="checkbox" />
                                                                                    <label className="cl-custom-check-label" for="Language2" title="Language">Ante mi ullamcorpor</label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <input className="cl-custom-check" id="Framwork2" type="checkbox" />
                                                                                    <label className="cl-custom-check-label" for="Framwork2" title="Framwork">Framwork</label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <input className="cl-custom-check" id="Programming2" type="checkbox" />
                                                                                    <label className="cl-custom-check-label" for="Programming2" title="Programming">Programming</label>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="action-antity">
                                                                        {
                                                                            currentQuestionPage === 1 ? null : <Button color="secondary" outline type="button" onClick={this.prevPage}>
                                                                                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M16.834 7.5C7.97836 7.5 10.5721 7.5 1.71649 7.5M1.71649 7.5L7.38555 1.6875M1.71649 7.5L7.38555 13.3125" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>Back
                                                                            </Button>
                                                                        }
                                                                        {
                                                                            currentQuestionPage === totalPages ? <Button color="primary" type="button" onClick={() => {
                                                                                this.props.postClientQuestionRequest({
                                                                                    entityId: parseInt(this.props?.match?.params?.entityId),
                                                                                    entitySetUpId: this.state.entitySetupId,
                                                                                    questionResponse: this.state.liteQuestionAnswer
                                                                                })
                                                                                // this.props.getEntityDetailsRequest({ countryId: this.props?.match?.params?.countriesId, entitySetupId: this.props?.match?.params?.entityId })
                                                                            }}>Save and Continue</Button> : liteQuestionList?.length > 0 ? <Button color="primary" type="button" onClick={this.nextPage}>Next
                                                                                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M1.5 7.5C10.5797 7.5 7.92031 7.5 17 7.5M17 7.5L11.1875 1.6875M17 7.5L11.1875 13.3125" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                            </Button> : null
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </CustomisedModal >
                                                        </div >

                                                    </div >
                                                </CardHeader >
                                                <CardBody className="custom_table table-responsive">
                                                    <div className="legal-update-status">
                                                        <FormGroup className="mb-0 form-group">
                                                            <Select
                                                                name="lawCategoryId[]"
                                                                className="form-control selectbox"
                                                                options={
                                                                    this.props
                                                                        .sortedLawCategoryListDropdown
                                                                }
                                                                onChange={(opt) => {
                                                                    let selectedValues = opt
                                                                        ? opt.map((x) => x.value)
                                                                        : [];
                                                                    this.setState({
                                                                        lawCategory: selectedValues
                                                                    })
                                                                    this.props.getLiteComplianceListRequest({
                                                                        page: this.state.currentPage,
                                                                        limit: this.state.pageSize,
                                                                        orderBy: this.state.sortBy,
                                                                        filter: {
                                                                            entityId: parseInt(this.props?.match?.params?.entityId),
                                                                            entitySetUpId: this.state.entitySetupId,
                                                                            countryId: parseInt(this.props.match.params.countriesId),
                                                                            stateId: this.props.entityDetails?.stateList && this.props.entityDetails?.stateList.length > 0 && [...new Set(this.props.entityDetails?.stateList.map((ele) => ele?.id))],
                                                                            // cityId: this.state.entitySetupData.liteEntityLocationList && this.state.entitySetupData.liteEntityLocationList.length > 0 && this.state.entitySetupData.liteEntityLocationList.map((location) => location?.city?.id),
                                                                            industryId: [this.state.entitySetupData.industryId],
                                                                            subIndustryId: this.state.entitySetupData?.liteEntityPropertyList && this.state.entitySetupData?.liteEntityPropertyList.length > 0 && this.state.entitySetupData?.liteEntityPropertyList.map((element) => {
                                                                                if (element.type == 2) {
                                                                                    return element.subIndustryId
                                                                                }
                                                                                return null
                                                                            }).filter((ele) => ele),
                                                                            entityTypeId: [this.state.entitySetupData.entityTypeId],
                                                                            facilityTypeId: this.state.entitySetupData.liteEntityPropertyList && this.state.entitySetupData.liteEntityPropertyList.length > 0 && this.state.entitySetupData.liteEntityPropertyList.map((element) => {
                                                                                if (element.type == 1) {
                                                                                    return element.facilityTypeId
                                                                                }
                                                                                return null
                                                                            }).filter((ele) => ele),
                                                                            lawCategoryId: selectedValues,
                                                                            applicabilityId: [],
                                                                            subApplicabilityId: []
                                                                        }
                                                                    })

                                                                }}
                                                                value={this.props.sortedLawCategoryListDropdown?.filter(
                                                                    (option) =>
                                                                        this.state.lawCategory?.includes(
                                                                            option.value
                                                                        )
                                                                )}
                                                                isMulti
                                                                closeMenuOnSelect={true}
                                                                hideSelectedOptions={false}
                                                                allowSelectAll={true}
                                                                key="lawCategoryId"
                                                            />
                                                            <Label className="form-label">
                                                                Practice Area
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    <CustomisedReactTableWithPagination
                                                        className="table table-bordered table-striped"
                                                        displayEntries={
                                                            true
                                                        }
                                                        searching={true}
                                                        currentPage={
                                                            this.state.currentPage
                                                        }
                                                        handleFetchData={this.handleFetchData}
                                                        handleSort={this.handleSort}
                                                        handleSearch={this.handleSearch}
                                                        pageCount={
                                                            this.state.pageCount
                                                        }
                                                        totalRecords={this.state.totalRecords}
                                                        preFilledSearchText={
                                                            this.state.searchFormData
                                                                ?.searchText
                                                        }
                                                        tableData={data}
                                                    />
                                                </CardBody>
                                            </Card >
                                        </TabPane > : null
                                    })
                                }
                            </TabContent >
                        </CardBody >
                    </Card>
                </>
            }
        </Row >
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedEntityTypeListDropDown: selectSortedEntityTypeList(),
    sortedFacilityTypeListDropDown: selectSortedFacilityTypeList(),
    sortedEntityListDropDown: selectSortedEntityList(),
    liteCountriesList: makeLiteClientRegistrationSelectField("liteCountriesList"),
    listOfLiteEntity: selectLiteEntityList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    entityDetails: makeLiteClientRegistrationSelectField("entityDetails"),
    updateEntityApiResponse: makeLiteClientRegistrationSelectField("updateEntityApiResponse"),
    listComplianceApiResponse: makeLiteClientRegistrationSelectField("listComplianceApiResponse"),
    liteOfQuestionAsPerPracticeAreaApiResponse: makeLiteClientRegistrationSelectField("liteOfQuestionAsPerPracticeAreaApiResponse"),
    liteClientQuestionResponseApiResponse: makeLiteClientRegistrationSelectField("liteClientQuestionResponseApiResponse"),
    resetQuestionApiResponse: makeLiteClientRegistrationSelectField("resetQuestionApiResponse"),
    loadingSelector: createLoadingSelector(),
    liteEntitySetupStateCityApiResponse:makeLiteClientRegistrationSelectField("liteEntitySetupStateCityApiResponse")
});
const withConnect = connect(mapStatetoProps, {
    getLiteCountriesEntityRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getAllEntityTypeListRequest,
    getAllFacilityTypeListRequest,
    getEntityListByCompanyGroupIdRequest,
    getLiteCountriesListRequest,
    getEntityDetailsRequest,
    putLiteEntityRequest,
    setResetQuestionnaireRequest,
    getLiteComplianceListRequest,
    getLawCategoryListByStatusRequest,
    getLiteCityListRequest,
    getLiteQuestionRequest,
    postClientQuestionRequest,
    getEntitySetupStateAndCityRequest
});
export default compose(
    withConnect,
    withLiteCountriesListReducer,
    withLiteCountriesListSaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withEntityReducer,
    withEntitySaga,
    withLawReducer,
    withLawSaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withRouter
)(LiteEntityEditModule);