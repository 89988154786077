import { FormGroup } from '@material-ui/core';
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination'
import React, { Component } from 'react'
import { Card, CardBody, Col, Label } from 'reactstrap'
import Select from 'react-select';
import __t from 'i18n/translator';

const VerifiedAccounts = [
    {
        Header: 'User Full Name',
        accessor: 'userFullName',
    },
    {
        Header: 'Account Name',
        accessor: 'accountName',
    },
    {
        Header: 'Email Id',
        accessor: 'emailId',
    },

    {
        Header: 'Request Date',
        accessor: 'createdAt',
    },
    {
        Header: 'Mobile Number',
        accessor: 'mobileNo',
        disableSortBy: true,
    },
    {
        Header: 'Company Full Name',
        accessor: 'companyFullName',
    },
    {
        Header: 'Website',
        accessor: 'companyWebsite',
        disableSortBy: true,
    },
    {
        Header: 'Status',
        accessor: 'clientStatus',
    },
    {
        Header: 'Actions',
        // TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];

const StatusSelection = [
    {
        value: "0",
        label: "Pending",
    },
    {
        value: "2",
        label: "Rejected"
    }
]

class VerifiedAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: null,
        }
    }
    render() {
        const data = {
            columns: VerifiedAccounts,
            rows: this.props.data,
        };

        return (
            <>
                <Col lg="12">
                    <Card>
                        <CardBody className="custom_table table-responsive">
                            {
                                this.props.activeTab == "verified_account" ? <div className="legal-update-status">
                                    <FormGroup className='mb-0 form-group'>
                                        <Select
                                            name="status"
                                            className="form-control selectbox"
                                            options={StatusSelection}
                                            onChange={(opt) => {
                                                let statusVal =
                                                    opt && opt?.value
                                                        ? opt.value
                                                        : '';
                                                this.setState({
                                                    status: statusVal
                                                })
                                                this.props.getStatusChangeFilter(
                                                    {
                                                        filters: {
                                                            isEmailVerified: true,
                                                            clientStatus: parseInt(statusVal)
                                                        }
                                                    }
                                                );
                                            }}

                                            value={StatusSelection.filter(
                                                (option) =>
                                                    this.state.status == option.value
                                            )}
                                            closeMenuOnSelect={true}
                                            isClearable={true}
                                        />
                                        <Label className="form-label">
                                            {__t("lite.status")}
                                        </Label>
                                    </FormGroup>
                                </div> : null
                            }
                            <CustomisedReactTableWithPagination
                                className="table table-bordered table-striped"
                                displayEntries={
                                    true
                                }
                                searching={true}
                                handleFetchData={this.props.handleFetchData}
                                handleSort={this.props.handleSort}
                                handleSearch={this.props.handleSearch}
                                currentPage={
                                    this.props.currentPage
                                }
                                pageCount={
                                    this.props.pageCount
                                }
                                preFilledSearchText={
                                    this.props.searchFormData
                                        ?.searchText
                                }
                                tableData={data}
                                totalRecords={this.props.totalRecords}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </>
        )
    }

}

export default VerifiedAccount