import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
    Button,
} from 'reactstrap';
import { SUCCESS, NA, CLOSE_LEGAL, DANGER } from 'constants/commonConstants';
import ShowMoreText from 'react-show-more-text';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import FileSaver from 'file-saver';
import {
    getComplianceLegalUpdateDetailsByIdRequest,
    getComplianceLegalUpdateActivityByIdRequest,
    updateAssigngcmLegalUpdateRequest,
    closeLegalUpdateByIdRequest
} from 'store/actions';
import * as authConstants from 'constants/authConstants';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import legalUpdateReducer from 'store/UpdateCenter/LegalUpdates/reducer';
import legalUpdateSaga from 'store/UpdateCenter/LegalUpdates/saga';
import { makeLegalUpdateSelectField } from 'store/UpdateCenter/LegalUpdates/selector';
import { commonConfirmBox, errorAlertMessage, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import { checkTwoDayFromEffectiveDate, legalUpdateAllHeaders } from 'app/UpdateCenter/LegalUpdates/CommonFunctions';
import classnames from 'classnames';
import {
    getDayDateName,
    getMonthName,
    getTimeFromDateTimeString,
    getYearName,
} from 'helpers/dateTimeHelper';
import {
    PENDING_WITH_APPROVER,
    ACKNOWLEDGE_BY_UPDATER,
    PENDING,
    APPROVED,
    PENDING_WITH_SUPER_ADMIN,
    APPROVAL_BASED_UPDATE,
    NEW_ADDITIONS,
    DEACTIVATION,
    SUB_UPDATE_DEFINITIONS,
    SUB_UPDATE_DOCUMENTS,
    MODIFICATIONS_UPDATE,
    CONFIRMED,
    DEACTIVATION_DEFINITION,
    DEACTIVATION_DOCUMENT,
} from 'constants/databaseConstants';
import { getValueByKey } from 'helpers/authUtils';
import ApproveModal from './ApproveModal';
import AuditLogContent from 'components/Application/AuditLogContent';
import { getFirstCapitalize } from 'helpers/generalUtils';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { decryptData } from 'helpers/encryptor';
const legalUpdatesKey = 'legalUpdates';
const withLegalUpdatesReducer = injectReducer({
    key: legalUpdatesKey,
    reducer: legalUpdateReducer,
});
const withLegalUpdatesSaga = injectSaga({
    key: legalUpdatesKey,
    saga: legalUpdateSaga,
});
class ComplianceLegalUpdateDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            legalUpdateData: {},
            activeTab: 'details',
            compliancelegalUpdateActivity: [],
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            userRoles: JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES))),
            isChecker: false,
            userApproval: false,
            actionType: 'Approve',
            statusType: '',
            isFormOpen: false,
        };
    }
    componentDidMount() {
        this.props.getComplianceLegalUpdateDetailsByIdRequest(this.state.id);
        this.props.getComplianceLegalUpdateActivityByIdRequest(this.state.id);
        let checkerRoles = this.state.userRoles.filter(
            (x) => x.roleId === 3 && x.type === 'CHECKER'
        );
        if (checkerRoles.length > 0) {
            this.setState({
                isChecker: true,
            });
        }
    }
    scrollToSection = (className) => {
        const section = document.querySelector('#' + className);
        let position = section.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
        document.getElementById(className).classList.add('animate__bounceIn');
        setTimeout(function () {
            document
                .getElementById(className)
                .classList.remove('animate__bounceIn');
        }, 2000);
    };
    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    tabToggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    closeLegalUpdate = async () => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.legal_update_close')
        );
        if (response) {
            this.props.closeLegalUpdateByIdRequest({}, this.state.legalUpdateData.id);
        }
    }
    saveFile = (uploadedFileData) => {
        return FileSaver.saveAs(
            uploadedFileData.fileUrl,
            uploadedFileData.fileName
        );
    };
    filterForApproveBtn = (value) => {
        let status = false;
        switch (value.legalUpdateStatusId) {
            case PENDING:
                status = this.state.isChecker;
                break;
            case PENDING_WITH_APPROVER:
                status =
                    value.approverUserId === this.state.loginUserId
                        ? true
                        : false;
                break;
            case APPROVED:
                status =
                    value.updaterUserId === this.state.loginUserId
                        ? true
                        : false;
                break;
            case ACKNOWLEDGE_BY_UPDATER:
                status = false;
                break;
            case PENDING_WITH_SUPER_ADMIN:
                status =
                    value.superAdminId === this.state.loginUserId
                        ? true
                        : false;
                break;
            default:
                break;
        }
        return status;
    };
    acknowledgedUser = async (id) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.acknowledged_legal_update')
        );
        if (response) {
            this.props.updateAssigngcmLegalUpdateRequest({}, id);
        }
    };
    openUserApprovalModal = (value) => {
        if (value.legalUpdateStatusId === PENDING_WITH_SUPER_ADMIN) {
            this.acknowledgedUser(value.id);
            return;
        }
        if (
            value.updateTypeId.includes(APPROVAL_BASED_UPDATE) === false &&
            value.updateTypeId.includes(NEW_ADDITIONS) === false &&
            value.updateTypeId.includes(DEACTIVATION) === false &&
            value.legalUpdateStatusId === APPROVED
        ) {
            this.acknowledgedUser(value.id);
        } else {
            this.setState({
                userApproval: true,
                actionType: 'Approve',
            });
        }
    };
    closeUserApprovalModal = () => {
        this.setState({
            //resetTableSection: true,
            userApproval: false,
            actionType: 'Approve',
        });
    };
    filterForApproveButton = (value) => {
        let label = '';
        if (
            value.updateTypeId.includes(APPROVAL_BASED_UPDATE) === false &&
            value.updateTypeId.includes(NEW_ADDITIONS) === false &&
            value.updateTypeId.includes(DEACTIVATION) === false &&
            value.legalUpdateStatusId === APPROVED
        ) {
            label = 'Acknowledged';
        } else {
            switch (value.legalUpdateStatusId) {
                case PENDING:
                    label = 'Assign to Approver';
                    break;
                case PENDING_WITH_APPROVER:
                    label = 'Assign to Updater';
                    break;
                case APPROVED:
                    label = 'Assign to Super Admin';
                    break;
                case PENDING_WITH_SUPER_ADMIN:
                    label = 'Acknowledged';
                    break;
                default:
                    break;
            }
        }
        return label;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.compliancelegalUpdateDetailsApiResponse && this.props.compliancelegalUpdateDetailsApiResponse !== nextprops.compliancelegalUpdateDetailsApiResponse) {
            if (nextprops.compliancelegalUpdateDetailsApiResponse.responseType === SUCCESS) {
                this.setState({
                    legalUpdateData: nextprops.compliancelegalUpdateDetailsApiResponse.data
                });
            } else {
                toastrMessage(nextprops.compliancelegalUpdateDetailsApiResponse.message, nextprops.compliancelegalUpdateDetailsApiResponse.responseType);
            }
        }
        if (nextprops.compliancelegalUpdateActivityApiResponse && this.props.compliancelegalUpdateActivityApiResponse !== nextprops.compliancelegalUpdateActivityApiResponse) {
            this.setState({
                compliancelegalUpdateActivity: nextprops.compliancelegalUpdateActivityApiResponse.data
            });
        }
        if (nextprops.assignGcmLegalUpdateApiResponse && this.props.assignGcmLegalUpdateApiResponse !== nextprops.assignGcmLegalUpdateApiResponse) {
            if (nextprops.assignGcmLegalUpdateApiResponse.responseType === SUCCESS) {
                this.props.getComplianceLegalUpdateDetailsByIdRequest(this.state.id);
                this.props.getComplianceLegalUpdateActivityByIdRequest(this.state.id);
            }
        }
        if (nextprops.closeLegalUpdateApiResponse && this.props.closeLegalUpdateApiResponse !== nextprops.closeLegalUpdateApiResponse) {
            toastrMessage(nextprops.closeLegalUpdateApiResponse.message, nextprops.closeLegalUpdateApiResponse.responseType);
            this.props.getComplianceLegalUpdateDetailsByIdRequest(this.state.id);
            this.props.getComplianceLegalUpdateActivityByIdRequest(this.state.id);
        }
    }
    render() {
        let editLawDefinitions = false;
        let editLawDocuments = false;
        let closeBtn = false;
        if (
            (this.state.legalUpdateData.isClosed === 0 && this.state.legalUpdateData.updaterUserId === this.state.loginUserId &&
                (
                    this.state.legalUpdateData.legalUpdateStatusId === ACKNOWLEDGE_BY_UPDATER ||
                    this.state.legalUpdateData.legalUpdateStatusId === CONFIRMED
                )
            ) &&
            (
                !this.state.legalUpdateData.pendingLawEdit ||
                !this.state.legalUpdateData.pendingComplianceEdit ||
                (
                    (
                        this.state.legalUpdateData?.updateTypeId?.includes(MODIFICATIONS_UPDATE) ||
                        this.state.legalUpdateData?.updateTypeId?.includes(DEACTIVATION)
                    ) &&
                    (
                        this.state.legalUpdateData?.subUpdateTypeId?.includes(SUB_UPDATE_DEFINITIONS) || this.state.legalUpdateData?.subUpdateTypeId?.includes(DEACTIVATION_DEFINITION)
                    ) &&
                    this.state.legalUpdateData.isDefinitionUpdated === 1
                ) ||
                (
                    (
                        this.state.legalUpdateData.updateTypeId.includes(MODIFICATIONS_UPDATE) ||
                        this.state.legalUpdateData.updateTypeId.includes(DEACTIVATION)
                    ) &&
                    (
                        this.state.legalUpdateData.subUpdateTypeId.includes(SUB_UPDATE_DOCUMENTS) ||
                        this.state.legalUpdateData.subUpdateTypeId.includes(DEACTIVATION_DOCUMENT)
                    ) &&
                    this.state.legalUpdateData.isDocumentUpdated === 1
                )
            )) {
            closeBtn = true;
        }
        if (this.state.legalUpdateData.isClosed === 0 && this.state.legalUpdateData.updaterUserId === this.state.loginUserId &&
            (
                this.state.legalUpdateData.legalUpdateStatusId === ACKNOWLEDGE_BY_UPDATER ||
                this.state.legalUpdateData.legalUpdateStatusId === CONFIRMED
            ) &&
            (
                this.state.legalUpdateData?.updateTypeId?.includes(MODIFICATIONS_UPDATE) ||
                this.state.legalUpdateData?.updateTypeId?.includes(DEACTIVATION)
            ) &&
            (
                this.state.legalUpdateData?.subUpdateTypeId?.includes(SUB_UPDATE_DEFINITIONS) ||
                this.state.legalUpdateData?.subUpdateTypeId?.includes(DEACTIVATION_DEFINITION)
            ) &&
            this.state.legalUpdateData.updaterUserId === this.state.loginUserId) {
            editLawDefinitions = true;
        }
        if (this.state.legalUpdateData.isClosed === 0 && this.state.legalUpdateData.updaterUserId === this.state.loginUserId &&
            (
                this.state.legalUpdateData.legalUpdateStatusId === ACKNOWLEDGE_BY_UPDATER ||
                this.state.legalUpdateData.legalUpdateStatusId === CONFIRMED
            ) &&
            (
                this.state.legalUpdateData?.updateTypeId?.includes(MODIFICATIONS_UPDATE) ||
                this.state.legalUpdateData?.updateTypeId?.includes(DEACTIVATION)
            ) &&
            (
                this.state.legalUpdateData?.subUpdateTypeId?.includes(SUB_UPDATE_DOCUMENTS) ||
                this.state.legalUpdateData?.subUpdateTypeId?.includes(DEACTIVATION_DOCUMENT)
            ) &&
            this.state.legalUpdateData.updaterUserId === this.state.loginUserId) {
            editLawDocuments = true;
        }
        const result = this.state
            .legalUpdateData
            ?.departments
            ?.length > 0 && Object.groupBy(this.state
                .legalUpdateData
                ?.departments
                , (department) => department.companyGroup.companyGroupName);
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'legal-updates-details'} />
                    </Col>
                    <Col lg="12" className="viewlaws_tabs tabs-main">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab === 'details',
                                    })}
                                    onClick={() => {
                                        this.tabToggle('details');
                                    }}
                                >
                                    Details
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            'activity_log',
                                    })}
                                    onClick={() => {
                                        this.tabToggle('activity_log');
                                    }}
                                >
                                    Activity Log
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            'legal_audit_logs',
                                    })}
                                    onClick={() => {
                                        this.tabToggle('legal_audit_logs');
                                    }}
                                >
                                    Audit Trail
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Card>
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        <a
                                            href={() => false}
                                            className="dripicons-arrow-thin-left"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}
                                        >
                                            { }
                                        </a>
                                        <span>
                                            &nbsp;
                                            {this.state.legalUpdateData
                                                ?.legalUpdateCode +
                                                ' - Date Of Update : ' +
                                                this.state.legalUpdateData
                                                    ?.dateOfUpdate}
                                        </span>
                                    </h4>
                                    {closeBtn && this.state.activeTab === 'details' && (
                                        <Button
                                            type="button"
                                            className="btn btn-info btn-sm"
                                            onClick={() => {
                                                this.closeLegalUpdate()
                                            }}>
                                            <i className='fas fa-check'> </i> {CLOSE_LEGAL}
                                        </Button>
                                    )}
                                </div>
                            </CardHeader>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane className="details" tabId="details">
                                    <CardBody>
                                        <div className="compliance_details">
                                            <Row>
                                                <Col lg="12">
                                                    {legalUpdateAllHeaders.map(
                                                        (itemData, i) => {
                                                            return (
                                                                <React.Fragment
                                                                    key={i}
                                                                >
                                                                    <a
                                                                        href={() => false}
                                                                        className="badge badge-primary mr-2 p-1"
                                                                        onClick={() =>
                                                                            this.scrollToSection(
                                                                                itemData.field
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            itemData.label
                                                                        }
                                                                    </a>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                                </Col>
                                                <Col lg="12">
                                                    <ul className="basic-details">
                                                        <li
                                                            key="country"
                                                            id="country"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.country'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.country
                                                                            ?.countryName
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="state"
                                                            id="state"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.state'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.state
                                                                        ?.stateName ||
                                                                        NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="city"
                                                            id="city"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.city'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.cities
                                                                        ?.length >
                                                                        0
                                                                        ? this.state.legalUpdateData.cities
                                                                            .map(
                                                                                (
                                                                                    city
                                                                                ) =>
                                                                                    city.cityName
                                                                            )
                                                                            .join(
                                                                                ','
                                                                            )
                                                                        : NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="dateOfUpdate"
                                                            id="dateOfUpdate"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.date_of_update'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.dateOfUpdate
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="lawName"
                                                            id="lawName"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.law_name'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.law
                                                                        ?.lawName ||
                                                                        NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="lawCode"
                                                            id="lawCode"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.law_code'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {(this.state
                                                                        .legalUpdateData
                                                                        ?.law
                                                                        ?.lawCode && (
                                                                            <a
                                                                                href={() => false}
                                                                                title={(this.state.legalUpdateData.pendingLawEdit === true) ? __t('form_labels.legal_update.change_law') : __t('form_labels.legal_update.view_law_details')}
                                                                                className={(this.state.legalUpdateData.pendingLawEdit === true) ? 'btn btn-secondary btn-sm px-2 py-1 d-inline-flex align-items-center mr-2' : 'btn btn-link'}
                                                                                onClick={() => {
                                                                                    if (this.state.legalUpdateData.pendingLawEdit === true) {
                                                                                        const { daysDifference, subtractTwoDayFromEffective } = checkTwoDayFromEffectiveDate(this.state.legalUpdateData.effectiveDate);
                                                                                        if (daysDifference >= -2) {
                                                                                            window.open(
                                                                                                '/statutory-compliances/edit-law-details/' + this.state.legalUpdateData.lawId,
                                                                                                '_blank'
                                                                                            )
                                                                                        } else {
                                                                                            toastrMessage(`${__t('form_labels.legal_update.change_document_alert_message')} ${subtractTwoDayFromEffective.format("DD-MM-YYYY")}`, DANGER)
                                                                                        }

                                                                                    } else {
                                                                                        window.open(
                                                                                            '/statutory-compliances/view-law/' +
                                                                                            this
                                                                                                .state
                                                                                                .legalUpdateData
                                                                                                .lawId,
                                                                                            '_blank'
                                                                                        )
                                                                                    }
                                                                                }
                                                                                }
                                                                            >
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .legalUpdateData
                                                                                        .law
                                                                                        .lawCode
                                                                                }{' '}
                                                                                <i className={(this.state.legalUpdateData.pendingLawEdit) ? "fas fa-pencil-alt ml-2 mr-2" : "fas fa-external-link-alt"}></i>
                                                                            </a>
                                                                        )) ||
                                                                        NA}
                                                                    {editLawDefinitions === true && (
                                                                        <a
                                                                            className={'btn btn-secondary btn-sm px-2 py-1 d-inline-flex align-items-center mr-2'}
                                                                            onClick={() => {
                                                                                const { daysDifference, subtractTwoDayFromEffective } = checkTwoDayFromEffectiveDate(this.state.legalUpdateData.effectiveDate);
                                                                                if (daysDifference >= -2) {
                                                                                    window.open(
                                                                                        '/statutory-compliances/view-law/' +
                                                                                        this
                                                                                            .state
                                                                                            .legalUpdateData
                                                                                            .lawId + '/definition',
                                                                                        '_blank'
                                                                                    )
                                                                                } else {
                                                                                    toastrMessage(`${__t('form_labels.legal_update.change_document_alert_message')} ${subtractTwoDayFromEffective.format("DD-MM-YYYY")}`, DANGER)
                                                                                }
                                                                            }}> {__t(
                                                                                'form_labels.legal_update.change_definition'
                                                                            )}{' '}
                                                                            <i className="fas fa-pencil-alt ml-2 mr-2"></i>
                                                                        </a>
                                                                    )}
                                                                    {editLawDocuments === true && (
                                                                        <a
                                                                            className={'btn btn-secondary btn-sm px-2 py-1 d-inline-flex align-items-center mr-2'}
                                                                            onClick={() => {
                                                                                const { daysDifference, subtractTwoDayFromEffective } = checkTwoDayFromEffectiveDate(this.state.legalUpdateData.effectiveDate);
                                                                                if (daysDifference >= -2) {
                                                                                    window.open('/statutory-compliances/view-law/' + this.state.legalUpdateData.lawId + '/document', '_blank')
                                                                                } else {
                                                                                    toastrMessage(`${__t('form_labels.legal_update.change_document_alert_message')} ${subtractTwoDayFromEffective.format("DD-MM-YYYY")}`, DANGER)
                                                                                }

                                                                            }}> {__t('form_labels.legal_update.change_document')}{' '}
                                                                            <i className="fas fa-pencil-alt ml-2 mr-2"></i>
                                                                        </a>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="complianceCode"
                                                            id="complianceCode"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.compliance_code'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {(this.state
                                                                        .legalUpdateData
                                                                        ?.LawCompliance
                                                                        ?.complianceCode && (
                                                                            <a
                                                                                href={() => false}
                                                                                title={(this.state.legalUpdateData.pendingComplianceEdit === true) ? __t('form_labels.legal_update.change_compliance') : __t('form_labels.legal_update.view_compliance_details')}
                                                                                className={(this.state.legalUpdateData.pendingComplianceEdit === true) ? 'btn btn-secondary btn-sm px-2 py-1 d-inline-flex align-items-center mr-2' : 'btn btn-link'}
                                                                                onClick={() => {
                                                                                    if (this.state.legalUpdateData.pendingComplianceEdit === true) {
                                                                                        const { daysDifference, subtractTwoDayFromEffective } = checkTwoDayFromEffectiveDate(this.state.legalUpdateData.effectiveDate);
                                                                                        if (daysDifference >= -2) {
                                                                                            window.open(
                                                                                                '/statutory-compliances/edit-details/' + this.state.legalUpdateData.LawComplianceId,
                                                                                                '_blank'
                                                                                            )
                                                                                        } else {
                                                                                            toastrMessage(`${__t('form_labels.legal_update.change_document_alert_message')} ${subtractTwoDayFromEffective.format("DD-MM-YYYY")}`, DANGER)
                                                                                        }

                                                                                    } else {
                                                                                        window.open(
                                                                                            '/statutory-compliances/view-details/' +
                                                                                            this
                                                                                                .state
                                                                                                .legalUpdateData
                                                                                                .LawComplianceId,
                                                                                            '_blank'
                                                                                        )
                                                                                    }
                                                                                }
                                                                                }
                                                                            >
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .legalUpdateData
                                                                                        .LawCompliance
                                                                                        .complianceCode
                                                                                }{' '}
                                                                                <i className={(this.state.legalUpdateData.pendingComplianceEdit) ? "fas fa-pencil-alt ml-2 mr-2" : "fas fa-external-link-alt"}></i>
                                                                            </a>
                                                                        )) ||
                                                                        NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="lawCategory"
                                                            id="lawCategory"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.law_category'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.lawCategory
                                                                            ?.lawCategoryName
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="industry"
                                                            id="industry"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.industry'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state.legalUpdateData?.industries
                                                                        ?.map(
                                                                            (
                                                                                industry
                                                                            ) =>
                                                                                industry.industryName
                                                                        )
                                                                        .join(
                                                                            ','
                                                                        )}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="subIndustry"
                                                            id="subIndustry"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.sub_industry'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state.legalUpdateData?.subIndustries
                                                                        ?.map(
                                                                            (
                                                                                subIndustry
                                                                            ) =>
                                                                                subIndustry.subIndustryName
                                                                        )
                                                                        .join(
                                                                            ','
                                                                        )}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="referenceNo"
                                                            id="referenceNo"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.reference_no'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.referenceNo
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="documentType"
                                                            id="documentType"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.document_type'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.documentType
                                                                            ?.documentTypeName
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="updateType"
                                                            id="updateType"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.parent_update_type'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state.legalUpdateData?.updateTypes
                                                                        ?.map(
                                                                            (
                                                                                updateTypes
                                                                            ) =>
                                                                                updateTypes.updateTypeName
                                                                        )
                                                                        .join(
                                                                            ','
                                                                        )}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="subUpdateType"
                                                            id="subUpdateType"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.sub_update_type'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state.legalUpdateData?.subUpdateTypes
                                                                        ?.map(
                                                                            (
                                                                                subUpdateType
                                                                            ) =>
                                                                                subUpdateType.subUpdateTypeName
                                                                        )
                                                                        .join(
                                                                            ','
                                                                        )}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="updateSummary"
                                                            id="updateSummary"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.update_summary'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.updateSummary
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="particularsOfUpdate"
                                                            id="particularsOfUpdate"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.particulars_of_update'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.particularsOfUpdate
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="impactOfUpdate"
                                                            id="impactOfUpdate"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.impact_of_update'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.impactOfUpdate ||
                                                                        NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="impactedSections"
                                                            id="impactedSections"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.impacted_sections'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.lawSections
                                                                        ?.length >
                                                                        0
                                                                        ? this.state.legalUpdateData.lawSections
                                                                            .map(
                                                                                (
                                                                                    section
                                                                                ) =>
                                                                                    section.sectionName
                                                                            )
                                                                            .join(
                                                                                ','
                                                                            )
                                                                        : NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="links"
                                                            id="links"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.links'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.links
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="source"
                                                            id="source"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.source'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.source
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="effectiveDate"
                                                            id="effectiveDate"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.effective_date'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.effectiveDate ||
                                                                        NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="dateOfPublication"
                                                            id="dateOfPublication"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.date_of_publication'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.dateOfPublication
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="isDocumentAttached"
                                                            id="isDocumentAttached"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.attached_document'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.isDocumentAttached ===
                                                                        'Y'
                                                                        ? 'Yes'
                                                                        : this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.isDocumentAttached ===
                                                                            'N'
                                                                            ? 'No'
                                                                            : 'NA'}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="complianceCategory"
                                                            id="complianceCategory"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.compliance_category'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.complianceCategory
                                                                        ?.complianceCategoryName ||
                                                                        NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="nameOfBusinessFunction"
                                                            id="nameOfBusinessFunction"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.name_of_business_function'
                                                                    )}
                                                                </b>
                                                                <ul className='company_group_list'>
                                                                    {
                                                                        Object.keys(result).length > 0 ? Object.entries(result).map(([key, value]) => {
                                                                            return <li key={key}>
                                                                                <strong>{key}</strong>
                                                                                <p>{
                                                                                    value && value.length > 0 ? value.map((element) => element.departmentName).join(", ") : "NA"}</p>
                                                                            </li>
                                                                        }) : "NA"
                                                                    }
                                                                    {/* {this.state
                                                                        .legalUpdateData
                                                                        ?.departments
                                                                        ?.length >
                                                                        0
                                                                        ? this.state.legalUpdateData.departments
                                                                            .map(
                                                                                (
                                                                                    department
                                                                                ) =>
                                                                                    department.companyGroup.companyGroupName + ' - ' + department.departmentName
                                                                            )
                                                                            .join(
                                                                                ', '
                                                                            ).trim()
                                                                        : NA} */}
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="updatePriority"
                                                            id="updatePriority"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.update_priority'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.updatePriority
                                                                        ? getFirstCapitalize(
                                                                            this
                                                                                .state
                                                                                .legalUpdateData
                                                                                ?.updatePriority
                                                                        )
                                                                        : NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="legalUpdateStatus"
                                                            id="legalUpdateStatus"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.legal_update_status'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .legalUpdateData
                                                                            ?.legalUpdateStatus
                                                                            ?.statusName
                                                                    }
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="approverUser"
                                                            id="approverUser"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.approver_user'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.approverUser
                                                                        ?.fullName ||
                                                                        NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li
                                                            key="updaterUser"
                                                            id="updaterUser"
                                                        >
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t(
                                                                        'form_labels.legal_update.updater_user'
                                                                    )}
                                                                </b>
                                                                <p>
                                                                    {this.state
                                                                        .legalUpdateData
                                                                        ?.updaterUser
                                                                        ?.fullName ||
                                                                        NA}
                                                                </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </TabPane>
                                <TabPane
                                    className="activity_log"
                                    tabId="activity_log"
                                >
                                    <CardBody>
                                        <div id="cd-timeline" className="mt-1">
                                            <ul className="timeline list-unstyled">
                                                {this.state.compliancelegalUpdateActivity?.map(
                                                    (
                                                        legalUpdateActivity,
                                                        key
                                                    ) => (
                                                        <React.Fragment
                                                            key={key}
                                                        >
                                                            <li className="timeline-list">
                                                                <div className="cd-timeline-content bg-light p-3">
                                                                    <Row>
                                                                        <Col lg="8">
                                                                            <h6 className="mt-0 mb-1">
                                                                                {
                                                                                    legalUpdateActivity
                                                                                        .legalUpdatesActivityType
                                                                                        .activityTypeName
                                                                                }
                                                                            </h6>
                                                                            {legalUpdateActivity.remarks !=
                                                                                null && (
                                                                                    <p className="mb-1">
                                                                                        {this.getShowMoreTextElement(
                                                                                            legalUpdateActivity.remarks.trim()
                                                                                        )}
                                                                                    </p>
                                                                                )}
                                                                            {legalUpdateActivity?.legalUpdateActivityTypeId ===
                                                                                7 &&
                                                                                legalUpdateActivity?.documentUrl && (
                                                                                    <p className="px-2 py-3 mb-0 d-flex align-items-center">
                                                                                        <Label>
                                                                                            {__t(
                                                                                                'form_labels.reporting.file'
                                                                                            )}
                                                                                        </Label>
                                                                                        <a
                                                                                            href={() => false}
                                                                                            onClick={() => {
                                                                                                this.saveFile(
                                                                                                    {
                                                                                                        fileName:
                                                                                                            legalUpdateActivity.documentName,
                                                                                                        fileUrl:
                                                                                                            legalUpdateActivity.documentUrl,
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            title="Download Uploaded File"
                                                                                            className="btn btn-link tbl-action file_view_btn text-primary"
                                                                                        >
                                                                                            <svg
                                                                                                width="16"
                                                                                                height="20"
                                                                                                viewBox="0 0 16 20"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path
                                                                                                    d="M9 5V1H5C3.11438 1 2.17157 1 1.58579 1.58579C1 2.17157 1 3.11438 1 5V15C1 16.8856 1 17.8284 1.58579 18.4142C2.17157 19 3.11438 19 5 19H11C12.8856 19 13.8284 19 14.4142 18.4142C15 17.8284 15 16.8856 15 15V7H11C10.0572 7 9.58579 7 9.29289 6.70711C9 6.41421 9 5.94281 9 5Z"
                                                                                                    fill="#4F2682"
                                                                                                    fillOpacity="0.13"
                                                                                                />
                                                                                                <path
                                                                                                    d="M9.17157 1H5C3.11438 1 2.17157 1 1.58579 1.58579C1 2.17157 1 3.11438 1 5V15C1 16.8856 1 17.8284 1.58579 18.4142C2.17157 19 3.11438 19 5 19H11C12.8856 19 13.8284 19 14.4142 18.4142C15 17.8284 15 16.8856 15 15V6.82843C15 6.41968 15 6.2153 14.9239 6.03153C14.8478 5.84776 14.7032 5.70324 14.4142 5.41421L10.5858 1.58579C10.2968 1.29676 10.1522 1.15224 9.96847 1.07612C9.7847 1 9.58032 1 9.17157 1Z"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.2"
                                                                                                />
                                                                                                <path
                                                                                                    d="M5 11L11 11"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.2"
                                                                                                    strokeLinecap="round"
                                                                                                />
                                                                                                <path
                                                                                                    d="M5 15L9 15"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.2"
                                                                                                    strokeLinecap="round"
                                                                                                />
                                                                                                <path
                                                                                                    d="M9 1V5C9 5.94281 9 6.41421 9.29289 6.70711C9.58579 7 10.0572 7 11 7H15"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.2"
                                                                                                />
                                                                                            </svg>
                                                                                            {
                                                                                                legalUpdateActivity?.documentName
                                                                                            }
                                                                                        </a>
                                                                                    </p>
                                                                                )}
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <p className="mb-0">
                                                                                <Label className="m-0 mr-2">
                                                                                    <small>
                                                                                        {__t(
                                                                                            'form_labels.legal_updates.created_by'
                                                                                        )}
                                                                                    </small>
                                                                                </Label>
                                                                                <span>
                                                                                    <small>
                                                                                        {legalUpdateActivity
                                                                                            .legalUpdatesActivityType
                                                                                            .id ===
                                                                                            PENDING
                                                                                            ? legalUpdateActivity
                                                                                                ?.complianceLegalUpdate
                                                                                                ?.user
                                                                                                ?.fullName
                                                                                            : legalUpdateActivity
                                                                                                ?.user
                                                                                                ?.fullName}
                                                                                    </small>
                                                                                </span>
                                                                            </p>
                                                                            {legalUpdateActivity
                                                                                .legalUpdatesActivityType
                                                                                .id ===
                                                                                PENDING_WITH_APPROVER && (
                                                                                    <p className="mb-0">
                                                                                        <Label className="m-0 mr-2">
                                                                                            <small>
                                                                                                {__t(
                                                                                                    'form_labels.legal_updates.approver_user'
                                                                                                )}
                                                                                            </small>
                                                                                        </Label>
                                                                                        <span>
                                                                                            <small>
                                                                                                {
                                                                                                    legalUpdateActivity
                                                                                                        ?.complianceLegalUpdate
                                                                                                        ?.approverUser
                                                                                                        ?.fullName
                                                                                                }
                                                                                            </small>
                                                                                        </span>
                                                                                    </p>
                                                                                )}
                                                                            {legalUpdateActivity
                                                                                .legalUpdatesActivityType
                                                                                .id ===
                                                                                ACKNOWLEDGE_BY_UPDATER && (
                                                                                    <p className="mb-0">
                                                                                        <Label className="m-0 mr-2">
                                                                                            <small className="text-primary">
                                                                                                {__t(
                                                                                                    'form_labels.legal_updates.updater_user'
                                                                                                )}
                                                                                            </small>
                                                                                        </Label>
                                                                                        <span>
                                                                                            <small>
                                                                                                {
                                                                                                    legalUpdateActivity
                                                                                                        ?.complianceLegalUpdate
                                                                                                        ?.updaterUser
                                                                                                        ?.fullName
                                                                                                }
                                                                                            </small>
                                                                                        </span>
                                                                                    </p>
                                                                                )}
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="date bg-primary">
                                                                        <h4 className="mt-0">
                                                                            {getDayDateName(
                                                                                legalUpdateActivity.createdAt
                                                                            )}
                                                                        </h4>
                                                                        <p className="mb-0 text-white-50">
                                                                            <span className="mr-1">
                                                                                {getMonthName(
                                                                                    legalUpdateActivity.createdAt
                                                                                )}
                                                                            </span>
                                                                            {getYearName(
                                                                                legalUpdateActivity.createdAt
                                                                            )}
                                                                        </p>
                                                                        <hr className="mt-1 mb-1 bg-white"></hr>
                                                                        <p className="mb-0 text-white">
                                                                            {getTimeFromDateTimeString(
                                                                                legalUpdateActivity.createdAt
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </CardBody>
                                </TabPane>
                                <TabPane
                                    className="legal_audit_logs"
                                    tabId="legal_audit_logs"
                                >
                                    <CardBody>
                                        <AuditLogContent
                                            auditType="legalUpdate"
                                            auditId={
                                                this.state.legalUpdateData.id
                                            }
                                        />
                                    </CardBody>
                                </TabPane>
                            </TabContent>
                        </Card>
                        {this.state.activeTab === 'details' && (
                            <div className="d-flex align-items-end justify-content-end">
                                {this.filterForApproveBtn(
                                    this.state.legalUpdateData
                                ) && (
                                        <>
                                            <a
                                                href={() => false}
                                                title={this.filterForApproveButton(
                                                    this.state.legalUpdateData
                                                )}
                                                className="btn btn-primary mr-2"
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            countryId:
                                                                this.state
                                                                    .legalUpdateData
                                                                    .countryId,
                                                            compliancesId:
                                                                this.state
                                                                    .legalUpdateData
                                                                    .id,
                                                            statusType:
                                                                this.state
                                                                    .legalUpdateData
                                                                    .legalUpdateStatusId,
                                                        },
                                                        () => {
                                                            this.openUserApprovalModal(
                                                                this.state
                                                                    .legalUpdateData
                                                            );
                                                        }
                                                    );
                                                }}
                                            >
                                                <i
                                                    className="fa fa-check"
                                                    aria-hidden="true"
                                                ></i>
                                                {this.filterForApproveButton(
                                                    this.state.legalUpdateData
                                                )}
                                            </a>
                                            {this.state.isChecker === false && (
                                                <a
                                                    href={() => false}
                                                    title="Reject"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                countryId:
                                                                    this.state
                                                                        .legalUpdateData
                                                                        .countryId,
                                                                compliancesId:
                                                                    this.state
                                                                        .legalUpdateData
                                                                        .id,
                                                            },
                                                            () => {
                                                                this.setState({
                                                                    userApproval: true,
                                                                    actionType:
                                                                        'Reject',
                                                                });
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-ban "
                                                        aria-hidden="true"
                                                    ></i>
                                                    Reject
                                                </a>
                                            )}
                                        </>
                                    )}
                            </div>
                        )}
                    </Col>
                    <ApproveModal
                        isModalOpen={this.state.userApproval}
                        onModalDismiss={() => this.closeUserApprovalModal()}
                        modalName={
                            this.state.actionType === 'Approve'
                                ? this.state.legalUpdateData
                                    .legalUpdateStatusId ===
                                    PENDING_WITH_APPROVER
                                    ? __t('tables.legal_updates.assign_updater')
                                    : this.state.legalUpdateData
                                        .legalUpdateStatusId === APPROVED
                                        ? __t(
                                            'tables.legal_updates.assign_super_admin'
                                        )
                                        : __t(
                                            'tables.legal_updates.assign_approver'
                                        )
                                : __t('tables.legal_updates.reject_compliance')
                        }
                        countryId={this.state.legalUpdateData.countryId}
                        compliancesId={this.state.legalUpdateData.id}
                        statusType={
                            this.state.legalUpdateData.legalUpdateStatusId
                        }
                        actionType={this.state.actionType}
                    />
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    compliancelegalUpdateDetailsApiResponse: makeLegalUpdateSelectField('compliancelegalUpdateDetailsApiResponse'),
    compliancelegalUpdateActivityApiResponse: makeLegalUpdateSelectField('compliancelegalUpdateActivityApiResponse'),
    assignGcmLegalUpdateApiResponse: makeLegalUpdateSelectField('assignGcmLegalUpdateApiResponse'),
    closeLegalUpdateApiResponse: makeLegalUpdateSelectField('closeLegalUpdateApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getComplianceLegalUpdateDetailsByIdRequest,
    getComplianceLegalUpdateActivityByIdRequest,
    updateAssigngcmLegalUpdateRequest,
    closeLegalUpdateByIdRequest
});
export default compose(
    withConnect,
    withLegalUpdatesReducer,
    withLegalUpdatesSaga,
    withRouter,
)(ComplianceLegalUpdateDetails);
