import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { LITE_ACCOUNT_DETAILS_ENTITY_REQUEST, LITE_ACCOUNT_DETAILS_REQUEST, LITE_ACCOUNT_ENTITY_NAME_UPDATE_REQUEST, LITE_ACCOUNT_UPDATE_REQUEST, LITE_COUNTRY_LIST_WISE_ENTITY_REQUEST, LITE_ENTITY_DETAILS_BY_ENTITY_ID_REQUEST, LITE_ENTITY_STATE_LIST_REQUEST, LITE_ENTITY_UPDATE_BY_ID_REQUEST, LITE_ENTITY_WISE_QUESTION_LIST_REQUEST, LITE_SUSPEND_ACCOUNT_REQUEST } from "./actionTypes";
import { handleApiCallException } from "store/Shared/actions";
import { getAccountDetailsResponse, getCountryListWithEntityResponse, getEntityWiseQuestionListResponse, getLiteAccountDetailsResponse, liteAccountEntityNameUpdateResponse, LiteAccountUpdateResponse, LiteEntityDetailsByEntityIdResponse, liteEntityStateListResponse, LiteEntityUpdateByIdResponse, suspendLiteAccountResponse } from "./actions";
import { invokeApi } from "helpers/axiosHelper";
import { ACCOUNT_DETAILS_URL, LITE_ACCOUNT_DETAILS_URL, LITE_ACCOUNT_UPDATE_API_URL, LITE_COUNTRY_LIST_WISE_ENTITY_API_URL, LITE_ENTITY_DETAILS_BY_ID_API_URL, LITE_ENTITY_SETUP_STATE_API_URL, LITE_ENTITY_UPDATE_NAME_API_URL, LITE_ENTITY_WISE_QUESTION_LIST, LITE_SUSPEND_ACCOUNT_API_URL } from "helpers/apiConstants";

function* getAccountDetails({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', ACCOUNT_DETAILS_URL, data);
        if (response.status) {
            yield put(getAccountDetailsResponse(response.data));
        } else {
            yield put(getAccountDetailsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLiteAccountDetailsWithEntity({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_ACCOUNT_DETAILS_URL}/${data.accountId}`);
        if (response.status) {
            yield put(getLiteAccountDetailsResponse(response.data));
        } else {
            yield put(getLiteAccountDetailsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getCountryListWiseEntity({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_COUNTRY_LIST_WISE_ENTITY_API_URL}/${data.companyGroupId}/country/${data.countryId}`);
        if (response.status) {
            yield put(getCountryListWithEntityResponse(response.data));
        } else {
            yield put(getCountryListWithEntityResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* liteEntityNameUpdateApiCall({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_ENTITY_UPDATE_NAME_API_URL}`, data);
        if (response.status) {
            yield put(liteAccountEntityNameUpdateResponse(response));
        } else {
            yield put(liteAccountEntityNameUpdateResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEntityStateListApi({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_ENTITY_SETUP_STATE_API_URL}/${data.entitySetupId}`);
        if (response.status) {
            yield put(liteEntityStateListResponse(response.data));
        } else {
            yield put(liteEntityStateListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* liteAccountUpdateApi({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_ACCOUNT_UPDATE_API_URL}/${data.liteClientId}`, data.apiData);
        if (response.status) {
            yield put(LiteAccountUpdateResponse(response));
        } else {
            yield put(LiteAccountUpdateResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* liteEntityDetailsByEntityIdApiCall({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_ENTITY_DETAILS_BY_ID_API_URL}/${data.entityId}/entitySetUp/${data.entitySetupId}`);
        if (response.status) {
            yield put(LiteEntityDetailsByEntityIdResponse(response.data));
        } else {
            yield put(LiteEntityDetailsByEntityIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* liteEntityUpdateByIdApiCall({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_ENTITY_DETAILS_BY_ID_API_URL}/${data.entityId}/entitySetUp/${data.entitySetupId}`,data.formValues);
        if (response.status) {
            yield put(LiteEntityUpdateByIdResponse(response));
        } else {
            yield put(LiteEntityUpdateByIdResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* liteEntityWiseQuestionListApi({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_ENTITY_WISE_QUESTION_LIST}/${data.liteClientId}/question-attemps`,data);
        if (response.status) {
            yield put(getEntityWiseQuestionListResponse(response));
        } else {
            yield put(getEntityWiseQuestionListResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* liteSuspendAccountApi({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_SUSPEND_ACCOUNT_API_URL}`,data);
        if (response.status) {
            yield put(suspendLiteAccountResponse(response));
        } else {
            yield put(suspendLiteAccountResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchLiteAccountDetails() {
    yield takeEvery(LITE_ACCOUNT_DETAILS_REQUEST, getAccountDetails);
    yield takeEvery(LITE_ACCOUNT_DETAILS_ENTITY_REQUEST, getLiteAccountDetailsWithEntity);
    yield takeEvery(LITE_COUNTRY_LIST_WISE_ENTITY_REQUEST, getCountryListWiseEntity);
    yield takeEvery(LITE_ACCOUNT_ENTITY_NAME_UPDATE_REQUEST, liteEntityNameUpdateApiCall);
    yield takeEvery(LITE_ENTITY_STATE_LIST_REQUEST, getEntityStateListApi);
    yield takeEvery(LITE_ACCOUNT_UPDATE_REQUEST, liteAccountUpdateApi);
    yield takeEvery(LITE_ENTITY_DETAILS_BY_ENTITY_ID_REQUEST, liteEntityDetailsByEntityIdApiCall);
    yield takeEvery(LITE_ENTITY_UPDATE_BY_ID_REQUEST, liteEntityUpdateByIdApiCall);
    yield takeEvery(LITE_ENTITY_WISE_QUESTION_LIST_REQUEST, liteEntityWiseQuestionListApi);
    yield takeEvery(LITE_SUSPEND_ACCOUNT_REQUEST, liteSuspendAccountApi);

}
function* LiteAccountDetailsSaga() {
    yield all([fork(watchLiteAccountDetails)]);
}
export default LiteAccountDetailsSaga;