export const CLIENT_REGISTRATION_LIST_REQUEST = "client-registration-list-request";
export const CLIENT_REGISTRATION_LIST_RESPONSE = "client-registration-list-response";
export const CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST = "client-registration-resend-email-request";

export const CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE = "client-registration-resend-email-response";

export const CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST = "client-registration-status-update-client-request";

export const CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE = "client-registration-status-update-client-response";

export const LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST = "lite-countries-list-as-per-plan-request";
export const LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE = "lite-countries-list-as-per-plan-response";


export const LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST = "lite-countries-wise-entity-list-request";
export const LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE = "lite-countries-wise-entity-list-response";

export const LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST = "lite-countries-wise-entity-add-request";

export const LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE = "lite-countries-wise-entity-add-response";


export const LITE_MANAGEMENT_USER_LIST_REQUEST = "lite-management-user-list-request";

export const LITE_MANAGEMENT_USER_LIST_RESPONSE = "lite-management-user-list-response";


export const LITE_ACTIVE_ENTITY_REQUEST = "lite-active-entity-request";

export const LITE_ACTIVE_ENTITY_RESPONSE = "lite-active-entity-response";

export const LITE_ENTITY_ADD_SETUP_REQUEST = "lite-entity-add-setup-request";

export const LITE_ENTITY_DETAILS_REQUEST = "lite-entity-details-request";

export const LITE_ENTITY_DETAILS_RESPONSE = "lite-entity-details-response";


export const LITE_ENTITY_UPDATE_REQUEST = "lite-entity-update-request";

export const LITE_ENTITY_UPDATE_RESPONSE = "lite-entity-update-response";
export const LITE_QUESTION_LIST_REQUEST = "lite-question-list-request";
export const LITE_QUESTION_LIST_RESPONSE = "lite-question-list-response";

export const LITE_QUESTION_ADD_REQUEST = "lite-question-add-request";

export const LITE_QUESTION_ADD_RESPONSE = "lite-question-add-response";


export const LITE_QUESTION_INACTIVE_REQUEST = "lite-question-inactive-request";

export const LITE_QUESTION_INACTIVE_RESPONSE = "lite-question-inactive-response";

export const LITE_QUESTION_UPDATE_REQUEST = "lite-question-update-request";

export const LITE_QUESTION_UPDATE_RESPONSE = "lite-question-update-response";

export const LITE_QUESTION_SINGLE_REQUEST = "lite-question-single-request";

export const LITE_QUESTION_SINGLE_RESPONSE = "lite-question-single-response";

export const LITE_QUESTION_RESET_REQUEST = "lite-question-reset-request";

export const LITE_QUESTION_RESET_RESPONSE = "lite-question-reset-response";

export const LITE_COMPLIANCE_LIST_REQUEST = "lite-compliance-list-request";

export const LITE_COMPLIANCE_LIST_RESPONSE = "lite-compliance-list-response";


export const LITE_CITY_LIST_REQUEST = "lite-city-list-request";

export const LITE_CITY_LIST_RESPONSE = "lite-city-list-response";

export const LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_REQUEST = "lite-question-list-as-per-practice-area-request";

export const LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_RESPONSE = "lite-question-list-as-per-practice-area-response";


export const LITE_CLIENT_RESPONSE_QUESTION_REQUEST = "lite-client-response-question-request";

export const LITE_CLIENT_RESPONSE_QUESTION_RESPONSE = "lite-client-response-question-response";

export const LITE_ENTITY_SETUP_STATE_CITY_REQUEST = "lite-entity-setup-state-city-request";

export const LITE_ENTITY_SETUP_STATE_CITY_RESPONSE = "lite-entity-setup-state-city-response";