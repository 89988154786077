import React, { Component } from 'react';
import {
    CardBody,
    Card,
    CardHeader,
    Container,
    Col,
    Row,
    Collapse,
    Nav, NavLink,
    TabContent,
    TabPane,
    NavItem,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    DANGER,
} from 'constants/commonConstants';
import classnames from 'classnames';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    addUserRequest,
    updateUserRequest,
    updateUserStatusByUserIdRequest,
    deleteUserByUserIdRequest,
    passwordResendUserByUserIdRequest,
    filterUserRequest,
    getCompanyGroupListByStatusRequest,
    getAllEntityListOnUserListPageRequest,
    cleareFilterUserResponse,
    unblockEmailRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import { makeUserSelectField } from 'store/User/selector';
import {
    listColumns,
    initialUserSearchDataState,
} from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBoxWithRemarks, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { checkModulePermission } from 'helpers/projectUtils';
import BlockEmailSearchForm from './BlockEmailSearchForm';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import {
    MODULE_USER,
    PERMISSION_ADD,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import { makeCompanyGroupSelectField, selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { makeEntitySelectField } from 'store/Entity/selector';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
import ViewUnblockedComment from './ViewUnblockedComment';
import ShowMoreText from 'react-show-more-text';

const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'unresolvedTab',
            userList: [],
            viewDialog: true,
            searchUsersFormData: initialUserSearchDataState(),
            activePage: 1,
            isUnblockEmailModalOpen: false,
            unbockedEmailObj: {}
        };
    }
    componentDidMount() {
        //Retrieve user list
        this.props.getCompanyGroupListByStatusRequest(ACTIVE, 'user');
        this.props.getAllEntityListOnUserListPageRequest();
    }
    filterFormSubmit = (values) => {
        this.setState({
            searchUsersFormData: {
                ...values,
                isResolved: (this.state.activeTab === 'unresolvedTab') ? false : true
            }
        }, () => {
            this.props.filterUserRequest({ filter: values });
        })
    };
    resetForm = () => {
        this.setState({
            searchUsersFormData: initialUserSearchDataState(),
        }, () => {
            this.props.filterUserRequest({ filter: this.state.searchUsersFormData });
        });
    };
    unblockUser = async (userData) => {
        const response = await commonConfirmBoxWithRemarks(
            __t('common.are_you_sure'),
            __t('alerts.user_unblock'),
            __t('common.comment')
        );
        if (response && response.result && response.textarea && response.textarea.value) {
            this.props.unblockEmailRequest({ ...userData, comment: response.textarea.value });
        } else if (response && response.result && !(response.textarea && response.textarea.value)) {
            toastrMessage("Comment is required", DANGER);
        }
    };
    generateTable = (records) => {
        let userRecords = [];
        userRecords = records.map((userData, i) => {
            let userCompanyGroupIds = userData?.companyGroupId?.split(',').map(Number);
            let actions = (
                <div className="text-center">
                    {
                        userData.isEmailBlock === 1 && (<a
                            href={() => false}
                            title={__t('common.resolve_user')}
                            className="btn btn-link text-secondary tbl-action"
                            onClick={() => {
                                this.unblockUser({ email: userData.email, userId: userData.id });
                            }}
                        >
                            <i className="fas fa-lock-open"></i>
                        </a>) || (<a
                            href={() => false}
                            title={__t('common.comment')}
                            className="btn btn-link text-secondary tbl-action"
                            onClick={() => {
                                this.setState({
                                    isUnblockEmailModalOpen: true,
                                    unbockedEmailObj: {
                                        fullName: userData.unbockedEmailHistories[0]?.createdByUser?.fullName,
                                        comment: userData.unbockedEmailHistories[0]?.comment,
                                        createdAt: userData.unbockedEmailHistories[0]?.createdAt,
                                    },
                                });
                            }}
                        >
                            <i className="fas fa-comment-dots"></i>
                        </a>)
                    }


                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(userData.id)}
                        checked={userData.isActive ? true : false}
                        disabled={true}
                    />
                    &nbsp;
                </div>
            );
            let companyGroupNames =
                this.props.companyGroupList
                    ?.filter((opt) => userCompanyGroupIds?.includes(opt.id))
                    ?.map((opt) => opt?.companyGroupName) || [];

            let entityIds = userData?.userEntities?.map(
                (opt) => opt.entityId
            )
            let entityName = this.props.entityListOnUserList?.filter(item => entityIds.includes(item.id))?.map(x => x.entityName).join(', ')
            return {
                fullName: userData.fullName,
                userName: userData?.userName,
                email: userData?.email ? userData.email : '',
                companyGroupName: companyGroupNames.join(', '),
                entityName: entityName,
                isActive: isActive,
                actions: actions,
                bounceReason: (userData?.bounceEmails[0]?.bounceReason) ? this.getShowMoreTextElement(userData?.bounceEmails[0]?.bounceReason) : '',
            };
        });
        return userRecords;
    };
    closeUnBlockedCommentModal() {
        this.setState({
            isUnblockEmailModalOpen: false,
            unbockedEmailObj: {},
        });
    }
    componentWillUnmount() {
        this.props.cleareFilterUserResponse();
    }

    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.companyGroupList &&
            this.props.companyGroupList !== nextprops.companyGroupList
        ) {
            this.setState(
                {
                    companyGroupList: nextprops.companyGroupList,
                },
                () => {
                    this.props.filterUserRequest({ filter: initialUserSearchDataState() });
                    this.setState({
                        isUnblockEmailModalOpen: false,
                        unbockedEmailObj: {}
                    });
                }
            );
        }
        if (nextprops.userList && this.props.userList !== nextprops.userList) {
            this.setState({
                userList: this.generateTable(nextprops.userList),
            });
        }
        if (nextprops.unblockEmailApiResponse && this.props.unblockEmailApiResponse !== nextprops.unblockEmailApiResponse) {
            toastrMessage(nextprops.unblockEmailApiResponse.message, nextprops.unblockEmailApiResponse.responseType);
            this.props.getCompanyGroupListByStatusRequest(ACTIVE, 'user');
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.userList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'blocked_email'} />
                        </Col>

                        <Col lg="12">
                            <CustomisedCollapseForOne
                                className="accordion"
                                viewDialog={false}
                                dialogName={__t('page_headers.blocked_email_filter')}
                            >
                                <CardBody className="px-4 pt-4 pb-2">
                                    <Collapse
                                        isOpen={this.state.viewDialog}
                                    >
                                        <BlockEmailSearchForm
                                            sortedCompanyGroupListDropdown={this.props.sortedCompanyGroupListDropdown}
                                            searchUsersFormData={
                                                this.state
                                                    .searchUsersFormData
                                            }
                                            onFormSubmit={(
                                                searchFormData
                                            ) => {
                                                this.filterFormSubmit(
                                                    searchFormData
                                                );
                                            }}
                                            onResetForm={() => {
                                                this.resetForm();
                                            }}
                                        />
                                    </Collapse>
                                </CardBody>
                            </CustomisedCollapseForOne>

                        </Col>
                        <Col lg="12" className='viewlaws_tabs tabs-main'>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab === 'unresolvedTab',
                                        })}
                                        onClick={() => {
                                            this.setState({
                                                activeTab: 'unresolvedTab',
                                                searchUsersFormData: { ...this.state.searchUsersFormData, isResolved: false }
                                            }, () => {
                                                this.props.filterUserRequest({ filter: this.state.searchUsersFormData });
                                            });
                                        }}
                                    >
                                        Unresolved
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                'resolvedTab',
                                        })}
                                        onClick={() => {
                                            this.setState({
                                                activeTab: 'resolvedTab',
                                                searchUsersFormData: { ...this.state.searchUsersFormData, isResolved: true }
                                            }, () => {
                                                this.props.filterUserRequest({ filter: this.state.searchUsersFormData });
                                            });
                                        }}
                                    >
                                        Resolved
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane className="laws_tab" tabId="unresolvedTab">
                                    <Row>
                                        <Col lg="12">
                                            <Card className="mb-2">
                                                <CardHeader>
                                                    <div className="page-header">
                                                        <h4>
                                                            {__t('page_headers.blocked_email_list')}
                                                        </h4>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <CustomisedMDBDatatablePage
                                                        activepage={this.state.activePage}
                                                        onHandlePageChange={this.handlePageChange}
                                                        tableRecords={data}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane className="laws_tab" tabId="resolvedTab">
                                    <Row>
                                        <Col lg="12">
                                            <Card className="mb-2">
                                                <CardHeader>
                                                    <div className="page-header">
                                                        <h4>
                                                            {__t('page_headers.resolved_email_list')}
                                                        </h4>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <CustomisedMDBDatatablePage
                                                        activepage={this.state.activePage}
                                                        onHandlePageChange={this.handlePageChange}
                                                        tableRecords={data}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>

                    </Row>
                </Container>
                <ViewUnblockedComment
                    modalName={__t('form_labels.unblock_comment.comment')}
                    isModalOpen={this.state.isUnblockEmailModalOpen}
                    unbockedEmailObj={this.state.unbockedEmailObj}
                    onModalDismiss={() => this.closeUnBlockedCommentModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    userList: makeUserSelectField('userList'),
    companyGroupList: makeCompanyGroupSelectField('companyGroupList'),
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    userApiResponse: makeUserSelectField('userApiResponse'),
    userStatusUpdateApiResponse: makeUserSelectField(
        'userStatusUpdateApiResponse'
    ),
    deleteUserApiResponse: makeUserSelectField('deleteUserApiResponse'),
    passwordResendUserApiResponse: makeUserSelectField(
        'passwordResendUserApiResponse'
    ),
    entityListOnUserList: makeEntitySelectField('entityListOnUserList'),
    unblockEmailApiResponse: makeUserSelectField('unblockEmailApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    addUserRequest,
    updateUserRequest,
    updateUserStatusByUserIdRequest,
    deleteUserByUserIdRequest,
    passwordResendUserByUserIdRequest,
    filterUserRequest,
    getCompanyGroupListByStatusRequest,
    getAllEntityListOnUserListPageRequest,
    cleareFilterUserResponse,
    unblockEmailRequest
});
export default compose(
    withConnect,
    withUserReducer,
    withUserSaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withRouter
)(Index);
