import React, { Component } from 'react';
import { Label, FormGroup } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

// users
import user4 from '../../../images/users/user-4.png';
import { getValueByKey, removeLoggedInUserDetails, removeSSOLoggedInUserDetails } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import Select from 'react-select';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { getInitials } from 'helpers/generalUtils';
import { decryptData } from 'helpers/encryptor';
import { withMsal } from '@azure/msal-react';
class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        const loginUserRoles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
        this.state = {
            menu: false,
            profileSideMenu: false,
            loginUserRoles: loginUserRoles,
            selectedLoginUserRole: parseInt(
                getValueByKey(authConstants.CURRENT_ROLE)
            ),
        };
        this.toggle = this.toggle.bind(this);
        this.toggleProfileSideMenu = this.toggleProfileSideMenu.bind(this);
    }

    toggle() {
        this.setState((prevState) => ({
            menu: !prevState.menu,
        }));
    }
    toggleProfileSideMenu() {
        this.setState((prevState) => ({
            profileSideMenu: !prevState.profileSideMenu,
        }), () => {
            if (this.state.selectedLoginUserRole && this.state.selectedLoginUserRole !== parseInt(getValueByKey(authConstants.CURRENT_ROLE))) {
                window.location.replace('/home');
            }
        });
    }
    render() {
        var userFullName = getValueByKey(authConstants.USER_FULL_NAME);
        var userEmail = getValueByKey(authConstants.USER_EMAIL);
        var userContactNo = getValueByKey(authConstants.USER_CONTACT_NO);

        let loginUserRoles = this.state.loginUserRoles.map((opt) => ({
            value: opt.id,
            label: opt.roleName,
        }));

        const loginUserRolesDropdown = [...new Map(loginUserRoles.map(item =>
            [item['value'], item])).values()];

        return (
            <React.Fragment>
                <p
                    onClick={this.toggleProfileSideMenu}
                    className="profile-user"
                >
                    <img src={user4} alt="user" className="rounded-circle" />
                    <b>
                        Welcome <span>{userFullName}</span>
                    </b>{' '}
                </p>

                <CustomisedSideModal
                    modalSize={
                        'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider'
                    }
                    modalName={'Profile'}
                    isModalOpen={this.state.profileSideMenu}
                    onModalDismiss={() => this.toggleProfileSideMenu()}
                >
                    <div className='profile_top_height'>
                        <div className="user_name mb-2">
                            <span>{getInitials(userFullName)}</span>
                            <b>
                                Welcome <span>{userFullName}</span>
                            </b>{' '}
                        </div>

                        <FormGroup className='profile_role'>
                            <Select
                                className="form-control selectbox mt-2"
                                placeholder="Role"
                                options={loginUserRolesDropdown}
                                onChange={(opt) => {
                                    localStorage.setItem(authConstants.CURRENT_ROLE, opt.value);
                                    this.props.history.push('/home');
                                    if (this.props.location.pathname === '/home') {
                                        this.toggleProfileSideMenu();
                                    }
                                }}
                                value={loginUserRolesDropdown.filter(
                                    (option) =>
                                        option.value ===
                                        this.state.selectedLoginUserRole
                                )}
                            />
                            <Label className="profile_label">
                                Role
                            </Label>
                        </FormGroup>
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={true}
                            dialogName={'General'}
                        >
                            <ul className="profile_general">
                                <li>
                                    <h4>Full Name :</h4>
                                    <p>{userFullName}</p>
                                </li>
                                <li>
                                    <h4>Email :</h4>
                                    <p>{userEmail}</p>
                                </li>
                                <li>
                                    <h4>Contact No :</h4>
                                    <p>{userContactNo}</p>
                                </li>

                            </ul>
                            <div className="d-flex justify-content-center mb-2">
                                <Link
                                    className="btn dropdown-item edit-profile_btn"
                                    to={'/edit-profile'}
                                >
                                    <svg
                                        width="30"
                                        height="30"
                                        viewBox="0 0 30 30"
                                        fill="transparent"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.7241 23.3374L11.2314 22.4672L11.7242 23.3374C11.9659 23.2006 12.1574 23.0079 12.3086 22.8558C12.3223 22.842 12.3357 22.8285 12.3488 22.8154L21.7929 13.3713C21.8057 13.3585 21.8186 13.3457 21.8314 13.3329C22.1313 13.0331 22.4228 12.7417 22.6308 12.469C22.8653 12.1616 23.0858 11.7637 23.0858 11.25C23.0858 10.7363 22.8653 10.3384 22.6308 10.031C22.4228 9.75831 22.1313 9.46695 21.8314 9.16714C21.8186 9.15434 21.8057 9.14152 21.7929 9.12868L20.8713 8.20711C20.8585 8.19427 20.8457 8.18144 20.8328 8.16862C20.533 7.8687 20.2417 7.57723 19.969 7.36919C19.6616 7.13466 19.2637 6.91421 18.75 6.91421C18.2363 6.91421 17.8384 7.13466 17.531 7.36919C17.2583 7.57723 16.967 7.8687 16.6672 8.16862C16.6543 8.18144 16.6415 8.19427 16.6287 8.20711L7.18461 17.6512C7.17153 17.6643 7.15805 17.6777 7.14424 17.6914C6.99207 17.8426 6.79945 18.0341 6.66256 18.2759L7.53276 18.7686L6.66256 18.2759C6.52568 18.5176 6.46061 18.7813 6.40921 18.9896C6.40454 19.0085 6.39999 19.027 6.3955 19.0449L5.48197 22.699C5.47917 22.7102 5.47625 22.7219 5.47323 22.7339C5.43641 22.8803 5.3854 23.0833 5.36783 23.2629C5.34762 23.4696 5.34093 23.9215 5.70971 24.2903C6.07848 24.6591 6.53044 24.6524 6.73714 24.6322C6.91673 24.6146 7.11962 24.5636 7.2661 24.5268C7.27813 24.5238 7.28977 24.5208 7.30099 24.518L10.9551 23.6045C10.9731 23.6 10.9915 23.5955 11.0104 23.5908C11.2187 23.5394 11.4824 23.4743 11.7241 23.3374Z"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        />
                                        <path
                                            d="M15.625 9.375L19.375 6.875L23.125 10.625L20.625 14.375L15.625 9.375Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                    Edit Profile
                                </Link>
                            </div>
                        </CustomisedCollapseForOne>
                        <div className="profile_last_btn">
                            <Link
                                className="btn dropdown-item change_pass_btn"
                                to={'/change-password'}
                            >
                                <i className="fas fa-key"></i> Change Password
                            </Link>

                        </div>
                    </div>
                    <div>
                        {getValueByKey(authConstants.LOGIN_TYPE) === 'SSO' ? (
                            <Link
                                className="btn dropdown-item logout_btn"
                                // onClick={async () => {
                                //     // const msalInstance = this.props.msalContext.instance;
                                //     // await msalInstance.logoutPopup().catch((error) => {
                                //     //     console.error(error);
                                //     // });
                                //     removeLoggedInUserDetails();
                                //     this.props.history.push('/logout');
                                // }}
                                to={{
                                    pathname: `/logout`,
                                }}
                            >
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </Link>
                        ) : (
                            <Link
                                className="btn dropdown-item logout_btn"
                                to={{
                                    pathname: `/logout`
                                }}
                            >
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </Link>
                        )}
                    </div>
                </CustomisedSideModal>
            </React.Fragment>
        );
    }
}

export default withRouter(withMsal(ProfileMenu));
