import CustomisedModal from 'components/Application/CustomisedModal';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Select from 'react-select';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup,
    Row,
    Button,
    Label,
} from 'reactstrap';
import { io } from 'socket.io-client';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import chatReducer from 'store/Chat/reducer';
import chatSaga from 'store/Chat/saga';
import {
    getChatListByTaskIdRequest,
    getMessageListByChatIdRequest,
    getUserByUserIdRequest,
    addNewMessageRequest,
    getTaskUsersRequest,
    createChatRequest,
    addOrRemoveUserRequest,
    saveUserNotificationRequest,
    renameGroupRequest,
} from 'store/actions';
import {
    makeChatSelectField,
    selectSortedTaskUserList,
} from 'store/Chat/selector';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import { makeUserSelectField } from 'store/User/selector';
import { Field, Formik, Form } from 'formik';
import SimpleReactValidator from 'simple-react-validator';
import {
    ADD_MEMBER,
    CANCEL,
    COMMON_GROUP_NAME,
    DANGER,
    SEND,
    SUBMIT,
    SUCCESS,
} from 'constants/commonConstants';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { getInitials } from 'helpers/generalUtils';
import {
    getTimeFromDateTimeString,
    getYearFromDateTimeString,
    getMonthFromDateTimeString,
    getDayFromDateTimeString,
    getDateTimeString,
} from 'helpers/dateTimeHelper';
import { getBaseEndPoint, getBaseUrl, getSocketOptions } from 'helpers/axiosHelper';
import {
    CHAT_MESSAGE_TYPE_ACTIVITY,
    CHAT_MESSAGE_TYPE_ATTACHMENT,
    CHAT_MESSAGE_TYPE_MESSAGE,
    LOCAL_FILE_STORAGE,
    ROLE_MANAGEMENT_TEAM,
} from 'constants/databaseConstants';
import __t from 'i18n/translator';

import RichTextEditor from 'react-rte';
import parse from 'html-react-parser';
import { customChatFileValidator } from 'helpers/customValidators';
import { makeNotificationSelectField } from 'store/Notification/selector';
import ChartThree from '../../../../images/03_no_chat.png';
import DOMPurify from 'dompurify';
import FileSaver from 'file-saver';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import { getAttchmentClassExtWise } from 'helpers/projectUtils';
import chatIcon from '../../../../images/chat-btn.png';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';

const chatKey = 'chat';
const withChatReducer = injectReducer({
    key: chatKey,
    reducer: chatReducer,
});
const withChatSaga = injectSaga({ key: chatKey, saga: chatSaga });

const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const fileStorage = process.env.REACT_APP_FILE_STORAGE;
const s3Url = process.env.REACT_APP_S3_URL;

class ChatModule extends React.Component {


    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customChatFileValidator()),
        });
        this.state = {
            chatMenu: false,
            chats: [],
            currentChatData: null,
            loginUserId: this.props.loginUserId,
            currentChatName: '',
            currentChatUsers: [],
            socketConnected: false,
            currentChat: null,
            message: RichTextEditor.createEmptyValue(),
            allMessages: [],
            commonGroupName: COMMON_GROUP_NAME,
            oneToManyChatManu: false,
            groupChatManu: false,
            renameGroupNameManu: false,
            taskUsers: [],
            groupFormData: {
                groupName: '',
                users: [],
            },
            groupChatForm: {
                groupName: '',
            },
            addUserFormData: {
                users: [],
            },
            showAddUserForm: false,
            showChatFileDialog: false,
            isAddMemberSectionOpen: false,
            viewMemberSectionOpen: true,
            selectedFile: null,
            replyToMsg: null,
            isOpenToolbar: false,
            tagUserName: null,
            showTagUserList: false,
            taskUserList: [],
            chatSearchInput: '',
            chatFilter: [],
            downloadFileUrl: '',
            downloadFileName: ''
        };
        this.toggleChatMenu = this.toggleChatMenu.bind(this);
        this.toggleChatFileDialog = this.toggleChatFileDialog.bind(this);
        this.toggleOneToManyChatManu = this.toggleOneToManyChatManu.bind(this);
        this.toggleGroupChatManu = this.toggleGroupChatManu.bind(this);
        this.toggleRenameGroupNameManu =
            this.toggleRenameGroupNameManu.bind(this);
        this.onChangeMessageInput = this.onChangeMessageInput.bind(this);
        this.socket = null;
        if (process.env.REACT_APP_ENABLE_SOCKET === '1') {
            this.socket = io(getBaseEndPoint(), getSocketOptions());
        }
        this.inputFile = React.createRef(null);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.openToolbar = this.openToolbar.bind(this);
    }
    componentDidMount() {
        this.props.getChatListByTaskIdRequest(this.props.taskId);
        this.props.getTaskUsersRequest(this.props.taskId);
        this.props.getUserByUserIdRequest(this.props.loginUserId);
        this.setState({ socketConnected: true });
        if (this.socket) {
            this.socket.on('message recieved', (newMessageRecieved) => {
                if (
                    this.state.allMessages.length !=
                    newMessageRecieved.messagesLength &&
                    this.state.currentChat?.id == newMessageRecieved?.id &&
                    this.props.loginUserId != newMessageRecieved.lastMessage.sender
                ) {
                    this.setState({
                        allMessages: [
                            ...this.state.allMessages,
                            newMessageRecieved.lastMessage,
                        ],
                    });
                    this.props.getChatListByTaskIdRequest(this.props.taskId);
                }
                if (
                    this.state.currentChat?.id != newMessageRecieved?.id &&
                    this.props.loginUserId != newMessageRecieved.lastMessage.sender
                ) {
                    this.props.getChatListByTaskIdRequest(this.props.taskId);
                }
            });
            this.socket.on('new chat', (id) => {
                if (id == this.props.loginUserId)
                    this.props.getChatListByTaskIdRequest(this.props.taskId);
            });
            this.socket.on('refresh activity', (chat) => {
                this.props.getChatListByTaskIdRequest(this.props.taskId);
                this.props.getMessageListByChatIdRequest(chat.id);
            });
            this.socket.on('refresh chat', (removedUserId) => {
                if (removedUserId && (removedUserId == this.props.loginUserId)) {
                    this.props.toggleChat();
                }
            });
        }
    }
    isValueBlank = (editorValue) => {
        const cleanValue = DOMPurify.sanitize(editorValue.toString(), {
            ALLOWED_TAGS: [],
        });
        const strippedValue = cleanValue.replace(/&nbsp;|\s/g, ''); // Remove non-breaking spaces and other whitespace characters
        return strippedValue.length === 0;
    };

    openToolbar = () => {
        this.setState({
            isOpenToolbar: !this.state.isOpenToolbar,
        });
    };
    onChangeMessageInput = (value) => {
        this.setState({ message: value });
    };

    onChangeChatSearchInput = (event) => {
        let searchValue = event.target.value;
        this.setState({
            chatSearchInput: searchValue,
            chatFilter: this.state.chats.filter((chat) =>
                chat.groupName.toLowerCase().includes(searchValue.toLowerCase())
            ),
        });
    };

    toggleChatMenu = () => {
        this.setState(
            {
                chatMenu: !this.state.chatMenu,
            },
            () => {
                //api call
            }
        );
    };

    toggleChatFileDialog = () => {
        this.setState(
            {
                showChatFileDialog: !this.state.showChatFileDialog,
            },
            () => {
                //api call
                if (!this.state.showChatFileDialog) {
                    this.setState({ selectedFile: null });
                }
            }
        );
    };
    showOpenFileDlg = () => {
        this.inputFile.current.click();
    };
    toggleOneToManyChatManu = () => {
        this.setState(
            {
                oneToManyChatManu: !this.state.oneToManyChatManu,
            },
            () => {
                this.validator.hideMessages();
                this.forceUpdate();
            }
        );
    };
    toggleGroupChatManu = () => {
        this.setState(
            {
                groupChatManu: !this.state.groupChatManu,
            },
            () => {
                this.validator.hideMessages();
                this.forceUpdate();
            }
        );
    };
    toggleRenameGroupNameManu = () => {
        this.setState(
            {
                renameGroupNameManu: !this.state.renameGroupNameManu,
            },
            () => {
                this.validator.hideMessages();
                this.forceUpdate();
            }
        );
    };
    renameGroup = (values) => {
        this.props.renameGroupRequest({
            groupName: values.groupName,
            id: this.state.currentChat.id,
        });
    };
    addNewMessage = async (attach = false, file = null) => {
        if (this.state.message || file) {
            let formData = new FormData();
            if (attach && file) {
                formData.append('attachmentFile', file);
                formData.append(
                    'messageType',
                    parseInt(CHAT_MESSAGE_TYPE_ATTACHMENT)
                );
            } else {
                let messageInputText = this.state.message.toString('html');
                formData.append('message', messageInputText);
                formData.append(
                    'messageType',
                    parseInt(CHAT_MESSAGE_TYPE_MESSAGE)
                );
            }

            formData.append('chatId', this.state.currentChat.id);
            formData.append('sender', this.props.loginUserId);
            if (this.state.replyToMsg?.id)
                formData.append('replyTo', this.state.replyToMsg.id);
            const receivers = await Promise.all(
                this.state.currentChat.users.filter(
                    (id) => id != this.props.loginUserId
                )
            );
            receivers.forEach((receiver) => {
                formData.append('receiver[]', receiver);
            });
            this.props.addNewMessageRequest(formData);
        }
    };
    handleFileChange = async (selectorFiles) => {
        let file = selectorFiles[0];
        if (file) {
            if (!customChatFileValidator(file.name)) {
                toastrMessage(__t('validations.custom.chat_file'), DANGER);
                this.setState({ selectedFile: null });
                return false;
            }
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            });
            this.setState({ selectedFile: file });
            this.toggleChatFileDialog();
        }
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    toggleAddUserForm = () => {
        this.setState({
            // showAddUserForm: !this.state.showAddUserForm,
            isAddMemberSectionOpen: true,
            viewMemberSectionOpen: false,
        });
    };
    openViewMember = () => {
        this.setState({
            isAddMemberSectionOpen: false,
            viewMemberSectionOpen: true,
        });
    };
    createGroupChat = async (values) => {
        const userIds = await Promise.all(
            this.state.taskUsers.map((user) => user.id)
        );
        this.props.createChatRequest({
            taskId: this.props.taskId,
            taskType: this.props.taskType, //static
            users: userIds,
            groupName: values.groupName,
            isGroup: true,
        });
    };
    addUserFormSubmit = async (values) => {
        if (this.validator.allValid()) {
            this.props.addOrRemoveUserRequest({
                id: this.state.currentChat.id,
                users: [values],
                isAdd: true,
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            if (!values.users.includes(parseInt(this.props.loginUserId)))
                values.users.push(parseInt(this.props.loginUserId));
            this.props.createChatRequest({
                taskId: this.props.taskId,
                taskType: this.props.taskType, //static
                users: values.users,
                groupName: values.groupName,
                isGroup: false,
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    removeUser = async (id) => {
        const currentChatUserIds = await Promise.all(
            this.state.currentChatUsers.map((user) => user.id)
        );
        if (currentChatUserIds.length <= 2)
            return toastrMessage('Maximun to users required in group.', DANGER);
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.remove_user')
        );
        if (response) {
            let userIds = await Promise.all(
                currentChatUserIds.filter((userId) => {
                    if (userId != id) return userId;
                })
            );
            this.props.addOrRemoveUserRequest({
                id: this.state.currentChat.id,
                users: userIds,
                removedUserId: id,
                isRemove: true,
            });
        }
    };
    replyToMessageProcess = async (replyToMessage) => {
        this.setState({ replyToMsg: replyToMessage });

    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.chatList && nextprops.chatList !== this.props.chatList)
            this.setState({
                chats: nextprops.chatList,
                chatFilter: nextprops.chatList,
                replyToMsg: null,
            });
        if (
            nextprops.messageListApiResponse &&
            nextprops.messageListApiResponse !==
            this.props.messageListApiResponse
        ) {
            this.setState({
                currentChatData: nextprops.messageListApiResponse.data,
                // allMessages: nextprops.messageListApiResponse.data.result.messages,
                allMessages: [
                    ...new Set(
                        nextprops.messageListApiResponse.data.result.messages
                    ),
                ],
                currentChat: nextprops.messageListApiResponse.data.result.chat,
                currentChatName:
                    nextprops.messageListApiResponse.data.result.chat.groupName,
                currentChatUsers:
                    nextprops.messageListApiResponse.data.result.chat.chatUsers,
                replyToMsg: null,
            }, () => {
                if (this.state.allMessages.length > 0) {
                    window.setInterval(function () {
                        var elem = document.getElementById('chat-area');
                        if (elem) {
                            elem.scrollTop = elem.scrollHeight;
                        }
                    }, 1000);
                }
            });
            if (this.socket) {
                this.socket.emit(
                    'join chat',
                    nextprops.messageListApiResponse.data.result.chat.id
                );
            }
            this.props.getChatListByTaskIdRequest(this.props.taskId);
        }
        if (
            nextprops.editUserApiResponse &&
            nextprops.editUserApiResponse !== this.props.editUserApiResponse
        ) {
            if (this.socket) {
                this.socket.emit('setup', nextprops.editUserApiResponse.data.data);
                this.socket.on('connected', () =>
                    this.setState({ socketConnected: true })
                );
            }
        }
        if (
            nextprops.addNewMessageApiResponse &&
            nextprops.addNewMessageApiResponse !==
            this.props.addNewMessageApiResponse
        ) {
            this.setState({
                allMessages: [
                    ...this.state.allMessages,
                    nextprops.addNewMessageApiResponse.data.data,
                ],
            });
            if (this.state.selectedFile) {
                this.toggleChatFileDialog();
                this.setState({ selectedFile: null });
            }
            if (this.socket) {
                this.socket.emit(
                    'new message',
                    nextprops.addNewMessageApiResponse.data.chat
                );
                this.socket.emit(
                    'send notifications',
                    nextprops.addNewMessageApiResponse.data.notifications
                );
            }
            this.setState({
                message: RichTextEditor.createEmptyValue(),
                replyToMsg: null,
            });
            this.props.getChatListByTaskIdRequest(this.props.taskId);
        }
        if (
            nextprops.taskUserList &&
            nextprops.taskUserList !== this.props.taskUserList
        ) {
            this.setState({ taskUsers: nextprops.taskUserList });
        }
        if (
            nextprops.createChatApiResponse &&
            nextprops.createChatApiResponse !== this.props.createChatApiResponse
        ) {
            toastrMessage(
                nextprops.createChatApiResponse.message,
                nextprops.createChatApiResponse.responseType
            );
            if (nextprops.createChatApiResponse.responseType === SUCCESS) {
                if (this.socket) {
                    this.socket.emit(
                        'create chat',
                        nextprops.createChatApiResponse.data.chat
                    );
                }
                this.state.oneToManyChatManu && this.toggleOneToManyChatManu();
                this.state.groupChatManu && this.toggleGroupChatManu();
                this.props.getChatListByTaskIdRequest(this.props.taskId);
            }
        }
        if (
            nextprops.addOrRemoveUserApiResponse &&
            nextprops.addOrRemoveUserApiResponse !==
            this.props.addOrRemoveUserApiResponse
        ) {
            toastrMessage(
                nextprops.addOrRemoveUserApiResponse.message,
                nextprops.addOrRemoveUserApiResponse.responseType
            );
            if (nextprops.addOrRemoveUserApiResponse.responseType === SUCCESS) {
                this.props.getMessageListByChatIdRequest(
                    this.state.currentChat.id
                );
                if (this.socket) {
                    this.socket.emit(
                        'new activity',
                        nextprops.addOrRemoveUserApiResponse.data.chat
                    );
                }
                if (nextprops.addOrRemoveUserApiResponse.data.removedUserId) {
                    if (this.socket) {
                        this.socket.emit(
                            'user removed from chat',
                            nextprops.addOrRemoveUserApiResponse.data.removedUserId
                        );
                    }
                }

            }
        }
        if (
            nextprops.renameGroupApiResponse &&
            nextprops.renameGroupApiResponse !==
            this.props.renameGroupApiResponse
        ) {
            toastrMessage(
                nextprops.renameGroupApiResponse.message,
                nextprops.renameGroupApiResponse.responseType
            );
            if (nextprops.renameGroupApiResponse.responseType === SUCCESS) {
                this.setState({
                    currentChatName:
                        nextprops.renameGroupApiResponse.data.chat.groupName,
                });
                this.toggleRenameGroupNameManu();
                this.props.getChatListByTaskIdRequest(this.props.taskId);
            }
        }
        if (
            nextprops.sortedTaskUserListDropdown &&
            nextprops.sortedTaskUserListDropdown !==
            this.props.sortedTaskUserListDropdown
        ) {
            if (nextprops.sortedTaskUserListDropdown?.length) {
                this.setState({
                    taskUserList: nextprops.sortedTaskUserListDropdown.filter(
                        (opt) => opt.value != this.props.loginUserId
                    ),
                });
            }
        }
    }
    scrollToBottom = () => {
        window.scrollTo({
            bottom: 0,
            behavior: 'smooth',
        });
    };
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    render() {
        const baseUrl = getBaseUrl();
        const fileHostUrl = (fileStorage === LOCAL_FILE_STORAGE) ? baseUrl : s3Url;
        // The toolbarConfig object allows you to specify custom buttons, reorder buttons and to add custom css classes.
        // Supported inline styles: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Inline-Styles.md
        // Supported block types: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Custom-Block-Render.md#draft-default-block-render-map
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: [
                'INLINE_STYLE_BUTTONS',
                'BLOCK_TYPE_BUTTONS',
                'LINK_BUTTONS',
                'HISTORY_BUTTONS',
            ],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
                { label: 'Strikethrough', style: 'STRIKETHROUGH' },
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' },
            ],
        };
        const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)))


        return (
            <div className="task-chat">
                <div className="chat-sidebar animate__animated animate__fadeInLeft">
                    <CardBody className="p-0 h-100">
                        <div className="d-flex align-items-center mx-3">
                            <a
                                href={() => false}
                                className="chat_back_btn"
                                onClick={() => this.props.toggleChat()}
                            >
                                <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8 1L2 7M2 7L8 13M2 7H17"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </a>

                            <h5>
                                <img src={chatIcon} alt="Chat button" />
                                {__t('chat.chats')}
                            </h5>
                        </div>

                        <div className="chat_search_box mb-3">
                            <div className="search_box">
                                <input
                                    type="text"
                                    className="search_input"
                                    value={this.state.chatSearchInput}
                                    onChange={this.onChangeChatSearchInput}
                                />
                                <svg
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z"
                                        fill="currentColor"
                                    />
                                </svg>

                            </div>
                            {
                                currentRole[0].roleId !== ROLE_MANAGEMENT_TEAM?  <UncontrolledDropdown direction="left">
                                                    <DropdownToggle
                                                        tag="a"
                                                        color="link"
                                                        className="add_chat"
                                                    >
                                                        <i className="fas fa-user-plus"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu
                                                        className="dropdown-menu-right"
                                                        end="true"
                                                    >
                                                        <DropdownItem
                                                            onClick={this.toggleOneToManyChatManu}
                                                        >
                                                            <i className="fas fa-user-friends"></i>{' '}
                                                            {__t('chat.create_chat')}
                                                        </DropdownItem>
                    
                                                        <DropdownItem
                                                            onClick={this.toggleGroupChatManu}
                                                        >
                                                            <i className="fas fa-users"></i>{' '}
                                                            {__t('chat.start_group_chat')}
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                    <CustomisedModal
                                                        isModalOpen={this.state.groupChatManu}
                                                        modalName={__t('chat.create_group_chat')}
                                                        onModalDismiss={() => {
                                                            this.toggleGroupChatManu();
                                                        }}
                                                        modalSize="modal-md"
                                                    >
                                                        <Formik
                                                            initialValues={this.state.groupChatForm}
                                                            enableReinitialize={true}
                                                            onSubmit={this.createGroupChat}
                                                        >
                                                            {({ values, setFieldValue }) => (
                                                                <Form>
                                                                    <Row>
                                                                        <FormGroup className="col-lg-12">
                                                                            <Field
                                                                                name="groupName"
                                                                                className="form-control"
                                                                                value={
                                                                                    values.groupName
                                                                                }
                                                                                placeholder={__t(
                                                                                    'chat.chat_name'
                                                                                )}
                                                                                maxLength={100}
                                                                            />
                                                                            <Label className="form-label">
                                                                                {__t(
                                                                                    'chat.chat_name'
                                                                                )}{' '}
                                                                                <span className="text-danger required">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            {this.validator.message(
                                                                                __t(
                                                                                    'chat.chat_name'
                                                                                ),
                                                                                values.groupName,
                                                                                'required|max:100',
                                                                                {
                                                                                    messages: {
                                                                                        required:
                                                                                            __t(
                                                                                                'validations.chat.chat_name_required'
                                                                                            ),
                                                                                        max: __t(
                                                                                            'validations.chat.chat_name_max',
                                                                                            {
                                                                                                max: 100,
                                                                                            }
                                                                                        ),
                                                                                    },
                                                                                }
                                                                            )}
                                                                        </FormGroup>
                                                                        <Col lg="12">
                                                                            <FormGroup className="mb-0 d-flex justify-content-end">
                                                                                <Button
                                                                                    type="submit"
                                                                                    color="primary"
                                                                                    className="mr-2"
                                                                                >
                                                                                    {SUBMIT}
                                                                                </Button>
                                                                                <Button
                                                                                    type="reset"
                                                                                    color="secondary"
                                                                                    onClick={
                                                                                        this
                                                                                            .toggleGroupChatManu
                                                                                    }
                                                                                >
                                                                                    {CANCEL}
                                                                                </Button>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </CustomisedModal>
                                                    <CustomisedModal
                                                        isModalOpen={this.state.oneToManyChatManu}
                                                        modalName={__t('chat.create_chat')}
                                                        onModalDismiss={() => {
                                                            this.toggleOneToManyChatManu();
                                                        }}
                                                        modalSize="modal-md"
                                                    >
                                                        <Formik
                                                            initialValues={this.state.groupFormData}
                                                            enableReinitialize={true}
                                                            onSubmit={this.formSubmit}
                                                        >
                                                            {({ values, setFieldValue }) => (
                                                                <Form>
                                                                    <Row>
                                                                        <FormGroup className="col-lg-12">
                                                                            <Field
                                                                                name="groupName"
                                                                                className="form-control"
                                                                                value={
                                                                                    values.groupName
                                                                                }
                                                                                placeholder={__t(
                                                                                    'chat.chat_name'
                                                                                )}
                                                                                maxLength={100}
                                                                            />
                                                                            <Label className="form-label">
                                                                                {__t(
                                                                                    'chat.chat_name'
                                                                                )}{' '}
                                                                                <span className="text-danger required">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            {this.validator.message(
                                                                                __t(
                                                                                    'chat.chat_name'
                                                                                ),
                                                                                values.groupName,
                                                                                'required|max:100',
                                                                                {
                                                                                    messages: {
                                                                                        required:
                                                                                            __t(
                                                                                                'validations.chat.chat_name_required'
                                                                                            ),
                                                                                        max: __t(
                                                                                            'validations.chat.chat_name_max',
                                                                                            {
                                                                                                max: 100,
                                                                                            }
                                                                                        ),
                                                                                    },
                                                                                }
                                                                            )}
                                                                        </FormGroup>
                                                                        <FormGroup className="col-lg-12">
                                                                            <Field
                                                                                component={Select}
                                                                                name="users"
                                                                                className="form-control selectbox"
                                                                                options={
                                                                                    this.state
                                                                                        .taskUserList
                                                                                }
                                                                                onChange={(opt) => {
                                                                                    setFieldValue(
                                                                                        'users',
                                                                                        opt &&
                                                                                        opt.map(
                                                                                            (
                                                                                                x
                                                                                            ) =>
                                                                                                x.value
                                                                                        )
                                                                                    );
                                                                                }}
                                                                                value={this.state.taskUserList.filter(
                                                                                    (option) =>
                                                                                        values.users?.includes(
                                                                                            option.value
                                                                                        )
                                                                                )}
                                                                                isMulti={true}
                                                                                closeMenuOnSelect={
                                                                                    false
                                                                                }
                                                                            />
                                                                            <Label className="form-label">
                                                                                {__t(
                                                                                    'chat.chat_members'
                                                                                )}{' '}
                                                                                <span className="text-danger required">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            {this.validator.message(
                                                                                __t(
                                                                                    'chat.chat_members'
                                                                                ),
                                                                                values.users,
                                                                                'required',
                                                                                {
                                                                                    messages: {
                                                                                        required:
                                                                                            __t(
                                                                                                'validations.chat.chat_members_required'
                                                                                            ),
                                                                                    },
                                                                                }
                                                                            )}
                                                                        </FormGroup>
                                                                        <Col lg="12">
                                                                            <FormGroup className="mb-0 d-flex justify-content-end">
                                                                                <Button
                                                                                    type="submit"
                                                                                    color="primary"
                                                                                    className="mr-2"
                                                                                >
                                                                                    {SUBMIT}
                                                                                </Button>
                                                                                <Button
                                                                                    type="reset"
                                                                                    color="secondary"
                                                                                    onClick={
                                                                                        this
                                                                                            .toggleOneToManyChatManu
                                                                                    }
                                                                                >
                                                                                    {CANCEL}
                                                                                </Button>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </CustomisedModal>
                                                </UncontrolledDropdown>:null
                            }
                           
                        </div>
                        <ul>
                            {this.state.chatFilter &&
                                this.state.chatFilter.map((chat, key) => {
                                    return (
                                        <li
                                            key={key}
                                            className={
                                                chat.groupName ==
                                                    this.state.currentChatName
                                                    ? 'active'
                                                    : ''
                                            }
                                        >
                                            <a
                                                href={() => false}
                                                onClick={() => {
                                                    this.setState({
                                                        allMessages: []
                                                    }, () => {
                                                        this.props.getMessageListByChatIdRequest(
                                                            chat.id
                                                        );
                                                    })
                                                }}
                                            >
                                                <div className="chat-profile-img">
                                                    <i
                                                        className={
                                                            chat.isGroup
                                                                ? 'fas fa-users'
                                                                : 'fas fa-user-friends'
                                                        }
                                                    ></i>
                                                </div>
                                                <div
                                                    className={
                                                        chat?.lastChatMessage?.message?.includes(
                                                            '<ul'
                                                        )
                                                            ? 'chat-details extra-match'
                                                            : 'chat-details'
                                                    }
                                                >
                                                    <span>
                                                        {chat.groupName}
                                                        {chat.lastChatMessage && (
                                                            <small>
                                                                {getTimeFromDateTimeString(
                                                                    chat
                                                                        .lastChatMessage
                                                                        ?.createdAt
                                                                )}
                                                            </small>
                                                        )}
                                                    </span>
                                                    <div>
                                                        <span>
                                                            {chat
                                                                .lastChatMessage
                                                                ?.message
                                                                ? parse(
                                                                    chat.lastChatMessage.message.toString()
                                                                )
                                                                : ''}
                                                        </span>
                                                        {chat.unSeenMessages
                                                            ?.length > 0 && (
                                                                <small>
                                                                    {
                                                                        chat.totalUnSeenMessages
                                                                    }
                                                                </small>
                                                            )}
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    );
                                })}
                        </ul>
                    </CardBody>
                </div>

                <div className="chat-main animate__animated animate__fadeInRight">
                    <CardBody className="p-0 h-100 d-flex flex-column">
                        <div className="chat-header">
                            <div className="chat-profile">

                                {this.state.currentChat?.id && (
                                    <h6
                                        onClick={this.toggleRenameGroupNameManu}
                                    >
                                        <div className="chat-profile-img mr-2">
                                            <i className="fas fa-user-friends"></i>
                                        </div>
                                        {this.state.currentChatName
                                            ? this.state.currentChatName
                                            : __t('chat.select_chat')}
                                    </h6>
                                )}

                                <CustomisedModal
                                    isModalOpen={this.state.renameGroupNameManu}
                                    modalName={__t('chat.rename_group_chat')}
                                    onModalDismiss={() => {
                                        this.toggleRenameGroupNameManu();
                                    }}
                                    modalSize="modal-md"
                                >
                                    <Formik
                                        initialValues={{
                                            groupName:
                                                this.state.currentChatName,
                                        }}
                                        enableReinitialize={true}
                                        onSubmit={this.renameGroup}
                                    >
                                        {({ values, setFieldValue }) => (
                                            <Form>
                                                <Row>
                                                    <FormGroup className="col-lg-12">
                                                        <Field
                                                            name="groupName"
                                                            className="form-control"
                                                            value={
                                                                values.groupName
                                                            }
                                                            placeholder={__t(
                                                                'chat.chat_name'
                                                            )}
                                                            maxLength={100}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'chat.chat_name'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'chat.chat_name'
                                                            ),
                                                            values.groupName,
                                                            'required|max:100',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.chat.chat_name_required'
                                                                        ),
                                                                    max: __t(
                                                                        'validations.chat.chat_name_max',
                                                                        {
                                                                            max: 100,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <Col lg="12">
                                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-2"
                                                            >
                                                                {SUBMIT}
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                color="secondary"
                                                                onClick={
                                                                    this
                                                                        .toggleRenameGroupNameManu
                                                                }
                                                            >
                                                                {CANCEL}
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </CustomisedModal>
                            </div>
                            <div className="chat-header-action">

                                {this.state.currentChatName != '' && (
                                    <Button
                                        color="link"
                                        className="info_btn"
                                        title="Details"
                                        onClick={this.toggleChatMenu}
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                r="10"
                                                transform="matrix(-1 0 0 1 12 12)"
                                                fill="transparent"
                                                fillOpacity="0.25"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                            />
                                            <path
                                                d="M12 12L12 18"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                                strokeLinecap="square"
                                            />
                                            <path
                                                d="M12 7L12 6"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                                strokeLinecap="square"
                                            />
                                        </svg>
                                    </Button>
                                )}
                            </div>
                            <CustomisedModal
                                isModalOpen={this.state.chatMenu}
                                modalName={__t('chat.view_members')}
                                onModalDismiss={() => {
                                    this.toggleChatMenu();
                                }}
                                modalSize={
                                    'modal-lg modal-dialog-centered modal-dialog-scrollable side_slider chatmember_modal'
                                }
                            >
                                {!this.state.currentChat?.isGroup &&
                                    !this.state.isAddMemberSectionOpen && (
                                        <Button
                                            color="success"
                                            className="add_member"
                                            onClick={() =>
                                                this.toggleAddUserForm()
                                            }
                                            title={__t('chat.add_member')}
                                        >
                                            <i className="fas fa-plus"></i> Add
                                            members
                                        </Button>
                                    )}
                                <div className="chat_membars">

                                    <ul
                                        className={
                                            this.state.viewMemberSectionOpen
                                                ? 'chat_member show'
                                                : 'chat_member'
                                        }
                                    >
                                        {this.state.currentChatUsers.length >
                                            0 &&
                                            this.state.currentChatUsers.map(
                                                (user, key) => {
                                                    return (
                                                        <li key={key}>
                                                            <div>
                                                                <span>
                                                                    {getInitials(
                                                                        user.fullName
                                                                    )}
                                                                </span>
                                                                <p>
                                                                    {
                                                                        user.fullName
                                                                    }
                                                                    <small>
                                                                        {
                                                                            user.email
                                                                        }{' '}
                                                                    </small>
                                                                </p>
                                                            </div>
                                                            {!this.state
                                                                .currentChat
                                                                .isGroup &&
                                                                this.props
                                                                    .loginUserId !==
                                                                user.id && (
                                                                    <a
                                                                        href={() => false}
                                                                        title={__t(
                                                                            'chat.remove_member'
                                                                        )}
                                                                        onClick={() =>
                                                                            this.removeUser(
                                                                                user.id
                                                                            )
                                                                        }
                                                                        className="text-danger"
                                                                    >
                                                                        <i className="fas fa-trash-alt"></i>
                                                                    </a>
                                                                )}
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </ul>
                                    <div
                                        className={
                                            this.state.isAddMemberSectionOpen
                                                ? 'availble_member show'
                                                : 'availble_member'
                                        }
                                    >
                                        <div className="availble_list">
                                            <h6>
                                                <a
                                                    href={() => false}
                                                    className="dripicons-arrow-thin-left"
                                                    onClick={() =>
                                                        this.openViewMember()
                                                    }
                                                >
                                                    {' '}
                                                    { }
                                                </a>
                                                Available participants
                                            </h6>
                                        </div>
                                        <ul className="chat_member">
                                            {this.state.taskUserList
                                                .filter((option) => {
                                                    let usersArr =
                                                        this.state.currentChatUsers?.map(
                                                            ({ id, ...rest }) =>
                                                                id
                                                        );
                                                    return !usersArr?.includes(
                                                        option.value
                                                    );
                                                })
                                                .map((user, key) => {
                                                    return (
                                                        <li key={key}>
                                                            <div>
                                                                <span>
                                                                    {getInitials(
                                                                        user.fullName
                                                                    )}
                                                                </span>
                                                                <p>
                                                                    {
                                                                        user.fullName
                                                                    }
                                                                    <small>
                                                                        {
                                                                            user.email
                                                                        }{' '}
                                                                    </small>
                                                                </p>
                                                            </div>
                                                            <a
                                                                href={() => false}
                                                                title="Add member"
                                                                className="text-success"
                                                                onClick={() =>
                                                                    this.addUserFormSubmit(
                                                                        user.value
                                                                    )
                                                                }
                                                            >
                                                                <i className="fas fa-plus"></i>
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                </div>
                                {this.state.showAddUserForm && (
                                    <Formik
                                        initialValues={
                                            this.state.addUserFormData
                                        }
                                        enableReinitialize={true}
                                        onSubmit={this.addUserFormSubmit}
                                    >
                                        {({ values, setFieldValue }) => (
                                            <Form>
                                                <Row>
                                                    <FormGroup className="col-lg-8">
                                                        <Field
                                                            component={Select}
                                                            name="users"
                                                            className="form-control selectbox"
                                                            options={this.state.taskUserList.filter(
                                                                (option) => {
                                                                    let usersArr =
                                                                        this.state.currentChatUsers?.map(
                                                                            ({
                                                                                id,
                                                                                ...rest
                                                                            }) =>
                                                                                id
                                                                        );
                                                                    return !usersArr?.includes(
                                                                        option.value
                                                                    );
                                                                }
                                                            )} //all task users
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'users',
                                                                    opt &&
                                                                    opt.map(
                                                                        (
                                                                            x
                                                                        ) =>
                                                                            x.value
                                                                    )
                                                                );
                                                            }}
                                                            isMulti={true}
                                                            closeMenuOnSelect={
                                                                false
                                                            }
                                                            value={this.state.taskUserList.filter(
                                                                (option) =>
                                                                    values.users.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'chat.members'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t('chat.members'),
                                                            values.users,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.chat.members_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <Col lg="4">
                                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-2"
                                                            >
                                                                {ADD_MEMBER}
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                color="secondary"
                                                                onClick={
                                                                    this
                                                                        .toggleAddUserForm
                                                                }
                                                            >
                                                                {CANCEL}
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </CustomisedModal>
                        </div>
                        <div className="chat-area" id="chat-area">
                            <ul>
                                {this.state.allMessages.length > 0 &&
                                    this.state.allMessages.map(
                                        (message, key) => {
                                            let currentMessageYear =
                                                getYearFromDateTimeString(
                                                    message.createdAt
                                                ),
                                                currentMessageMonth =
                                                    getMonthFromDateTimeString(
                                                        message.createdAt
                                                    ),
                                                currentMessageDay =
                                                    getDayFromDateTimeString(
                                                        message.createdAt
                                                    );

                                            let previousMessageYear,
                                                previousMessageMonth,
                                                previousMessageDay,
                                                flag = false;

                                            if (key > 0) {
                                                previousMessageYear =
                                                    getYearFromDateTimeString(
                                                        this.state.allMessages[
                                                            key - 1
                                                        ].createdAt
                                                    );
                                                previousMessageMonth =
                                                    getMonthFromDateTimeString(
                                                        this.state.allMessages[
                                                            key - 1
                                                        ].createdAt
                                                    );
                                                previousMessageDay =
                                                    getDayFromDateTimeString(
                                                        this.state.allMessages[
                                                            key - 1
                                                        ].createdAt
                                                    );
                                            }

                                            if (
                                                currentMessageYear !== previousMessageYear ||
                                                currentMessageMonth !== previousMessageMonth ||
                                                currentMessageDay !== previousMessageDay
                                            ) {
                                                flag = true;
                                            }

                                            return (
                                                <React.Fragment key={key}>
                                                    {flag && (
                                                        <li
                                                            key={key}
                                                            className="notification sticky"
                                                        >
                                                            <span>
                                                                {getDateTimeString(
                                                                    message.createdAt,
                                                                    currentMessageDay,
                                                                    currentMessageMonth,
                                                                    currentMessageYear
                                                                )}
                                                            </span>
                                                        </li>
                                                    )}
                                                    {(message.messageType ===
                                                        'Activity' ||
                                                        message.messageType ==
                                                        CHAT_MESSAGE_TYPE_ACTIVITY) && (
                                                            <li
                                                                key={key}
                                                                className="notification"
                                                            >
                                                                <span>
                                                                    {
                                                                        message.message
                                                                    }
                                                                </span>
                                                            </li>
                                                        )}
                                                    {(message.messageType ===
                                                        'Message' ||
                                                        message.messageType ==
                                                        CHAT_MESSAGE_TYPE_MESSAGE) && (
                                                            <li
                                                                key={key}
                                                                className={
                                                                    message.sender !=
                                                                        this.props
                                                                            .loginUserId
                                                                        ? 'receive animate__animated animate__fadeInLeft'
                                                                        : 'send animate__animated animate__fadeInRight'
                                                                }
                                                            >
                                                                {message.sender !=
                                                                    this.props
                                                                        .loginUserId && (
                                                                        <div className="msg_img_inner">
                                                                            {getInitials(
                                                                                message
                                                                                    .senderUser
                                                                                    .fullName
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                <div className="chat_msg">
                                                                    <div className="chat_user">
                                                                        {message.sender !=
                                                                            this
                                                                                .props
                                                                                .loginUserId && (
                                                                                <span className="user_name">
                                                                                    {
                                                                                        message
                                                                                            .senderUser
                                                                                            .fullName
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        <span className="msg_time">
                                                                            {getTimeFromDateTimeString(
                                                                                message.createdAt
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <p>

                                                                        {message.replyTo && (
                                                                            <div className="reply-text">
                                                                                <b>
                                                                                    {
                                                                                        message
                                                                                            .replyToMessage
                                                                                            .senderUser
                                                                                            .fullName
                                                                                    }
                                                                                </b>
                                                                                <span>
                                                                                    {message
                                                                                        .replyToMessage
                                                                                        .message
                                                                                        ? parse(
                                                                                            message.replyToMessage.message.toString()
                                                                                        )
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                        {message.message
                                                                            ? parse(
                                                                                message.message.toString()
                                                                            )
                                                                            : ''}
                                                                    </p>
                                                                    <UncontrolledDropdown
                                                                        direction={
                                                                            message.sender ==
                                                                                this
                                                                                    .props
                                                                                    .loginUserId
                                                                                ? 'left'
                                                                                : 'right'
                                                                        }
                                                                    >
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            color="link"
                                                                            className="chat_action"
                                                                        >
                                                                            <i className="fas fa-ellipsis-v"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu
                                                                            className="dropdown-menu-right"
                                                                            end="true"
                                                                        >
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    this.replyToMessageProcess(
                                                                                        message
                                                                                    )
                                                                                }
                                                                                }
                                                                            >
                                                                                <svg
                                                                                    width="24"
                                                                                    height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        d="M4 10L3.29289 10.7071L2.58579 10L3.29289 9.29289L4 10ZM21 18C21 18.5523 20.5523 19 20 19C19.4477 19 19 18.5523 19 18L21 18ZM8.29289 15.7071L3.29289 10.7071L4.70711 9.29289L9.70711 14.2929L8.29289 15.7071ZM3.29289 9.29289L8.29289 4.29289L9.70711 5.70711L4.70711 10.7071L3.29289 9.29289ZM4 9L14 9L14 11L4 11L4 9ZM21 16L21 18L19 18L19 16L21 16ZM14 9C17.866 9 21 12.134 21 16L19 16C19 13.2386 16.7614 11 14 11L14 9Z"
                                                                                        fill="currentColor"
                                                                                    />
                                                                                </svg>{' '}
                                                                                Reply
                                                                            </DropdownItem>

                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                            </li>
                                                        )}
                                                    {(message.messageType ===
                                                        'Attachment' ||
                                                        message.messageType ==
                                                        CHAT_MESSAGE_TYPE_ATTACHMENT) && (
                                                            <li
                                                                key={key}
                                                                className={
                                                                    message.sender !=
                                                                        this.props
                                                                            .loginUserId
                                                                        ? 'receive animate__animated animate__fadeInLeft'
                                                                        : 'send animate__animated animate__fadeInRight'
                                                                }
                                                            >
                                                                {message.sender !=
                                                                    this.props
                                                                        .loginUserId && (
                                                                        <div className="msg_img_inner">
                                                                            {getInitials(
                                                                                message
                                                                                    .senderUser
                                                                                    .fullName
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                <div className="chat_msg">
                                                                    <div className="chat_user">
                                                                        {message.sender !=
                                                                            this
                                                                                .props
                                                                                .loginUserId && (
                                                                                <span className="user_name">
                                                                                    {
                                                                                        message
                                                                                            .senderUser
                                                                                            .fullName
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        <span className="msg_time">
                                                                            {getTimeFromDateTimeString(
                                                                                message.createdAt
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <div className="attachments">
                                                                        <div className="file">
                                                                            {message.replyTo && (
                                                                                <div className="reply-text">
                                                                                    <b>
                                                                                        {
                                                                                            message
                                                                                                .replyToMessage
                                                                                                .senderUser
                                                                                                .fullName
                                                                                        }
                                                                                    </b>
                                                                                    <span>
                                                                                        {message
                                                                                            .replyToMessage
                                                                                            .message
                                                                                            ? parse(
                                                                                                message.replyToMessage.message.toString()
                                                                                            )
                                                                                            : ''}
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                            <div className="file_inner">
                                                                                <span>

                                                                                    <i
                                                                                        className={getAttchmentClassExtWise(
                                                                                            message.message
                                                                                        )}
                                                                                    ></i>
                                                                                </span>
                                                                                <p>
                                                                                    <a
                                                                                        href={() => false}
                                                                                        title="Download Uploaded File"
                                                                                        className="text-primary"
                                                                                        onClick={() =>
                                                                                            this.props.saveFile({
                                                                                                type: 'chat',
                                                                                                fileName: message.message,
                                                                                                fileUrl: message.attachmentPath
                                                                                            })}
                                                                                    >
                                                                                        {message.message
                                                                                            ? parse(
                                                                                                message.message.toString()
                                                                                            )
                                                                                            : ''}
                                                                                    </a>

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <UncontrolledDropdown
                                                                        direction={
                                                                            message.sender ==
                                                                                this
                                                                                    .props
                                                                                    .loginUserId
                                                                                ? 'left'
                                                                                : 'right'
                                                                        }
                                                                    >
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            color="link"
                                                                            className="chat_action"
                                                                        >
                                                                            <i className="fas fa-ellipsis-v"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu
                                                                            className="dropdown-menu-right"
                                                                            end="true"
                                                                        >
                                                                            <DropdownItem
                                                                                onClick={() =>
                                                                                    this.replyToMessageProcess(
                                                                                        message
                                                                                    )
                                                                                }
                                                                            >
                                                                                <svg
                                                                                    width="24"
                                                                                    height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        d="M4 10L3.29289 10.7071L2.58579 10L3.29289 9.29289L4 10ZM21 18C21 18.5523 20.5523 19 20 19C19.4477 19 19 18.5523 19 18L21 18ZM8.29289 15.7071L3.29289 10.7071L4.70711 9.29289L9.70711 14.2929L8.29289 15.7071ZM3.29289 9.29289L8.29289 4.29289L9.70711 5.70711L4.70711 10.7071L3.29289 9.29289ZM4 9L14 9L14 11L4 11L4 9ZM21 16L21 18L19 18L19 16L21 16ZM14 9C17.866 9 21 12.134 21 16L19 16C19 13.2386 16.7614 11 14 11L14 9Z"
                                                                                        fill="currentColor"
                                                                                    />
                                                                                </svg>{' '}
                                                                                Reply
                                                                            </DropdownItem>

                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                            </li>
                                                        )}
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                            </ul>
                            {!this.state.currentChat && (
                                <div className="no_chat">
                                    <img
                                        src={ChartThree}
                                        alt="No Chat available"
                                    />
                                    <h3>No chat are available in this task</h3>
                                </div>
                            )}
                        </div>
                        {this.state.currentChatName != '' && (
                            <div className="chat-footer">
                                <CustomisedModal
                                    isModalOpen={this.state.showChatFileDialog}
                                    modalName={__t(
                                        'chat.chat_attachment_header'
                                    )}
                                    onModalDismiss={() => {
                                        this.toggleChatFileDialog();
                                    }}
                                    modalSize={'modal-md'}
                                >
                                    {this.state.selectedFile && (
                                        <Card className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                            <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <Link
                                                            to="#"
                                                            className="upload-document-title font-weight-bold"
                                                        >
                                                            {
                                                                this.state
                                                                    .selectedFile
                                                                    .name
                                                            }
                                                        </Link>
                                                        <p className="mb-0">
                                                            <strong>
                                                                {
                                                                    this.state
                                                                        .selectedFile
                                                                        .formattedSize
                                                                }
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    )}
                                    <Row>
                                        <Col className="d-flex justify-content-end">
                                            <Button
                                                color="primary"
                                                className="mr-2"
                                                onClick={() =>
                                                    this.addNewMessage(
                                                        true,
                                                        this.state.selectedFile
                                                    )
                                                }
                                            >
                                                {SEND}
                                            </Button>
                                            <Button
                                                color="secondary"
                                                onClick={
                                                    this.toggleChatFileDialog
                                                }
                                            >
                                                {CANCEL}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CustomisedModal>
                                {/*===============Tag=Users=Section===============*/}


                                {this.state.currentChat?.id && (
                                    <div
                                        className={
                                            this.state.showTagUserList
                                                ? 'tag-user-list show'
                                                : 'tag-user-list'
                                        }
                                    >
                                        <ul className="chat_member">
                                            {this.state.currentChatUsers.map(
                                                (user, key) => {
                                                    return (
                                                        <li key={key}>
                                                            <div>
                                                                <span>
                                                                    {getInitials(
                                                                        user.fullName
                                                                    )}
                                                                </span>
                                                                <p>
                                                                    {
                                                                        user.fullName
                                                                    }
                                                                    <small>
                                                                        {
                                                                            user.email
                                                                        }{' '}
                                                                    </small>
                                                                </p>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                )}

                                {/*===============End=Tag=Users=Section===============*/}
                                {/*===============Reply=Section===============*/}
                                {this.state.replyToMsg?.id && (
                                    <div className="reply-text">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                        >
                                            <g>
                                                <path
                                                    fill="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <path d="M12 14v5.5L1.5 11 12 2.5V8c5.523 0 10 4.477 10 10 0 .273-.01.543-.032.81A8.999 8.999 0 0 0 14 14h-2zm-2 1.308V12h4c1.42 0 2.791.271 4.057.773A7.982 7.982 0 0 0 12 10h-2V6.692L4.679 11 10 15.308z" />
                                            </g>
                                        </svg>
                                        <Link to="#" className="">
                                            <b>
                                                {
                                                    this.state.replyToMsg
                                                        .senderUser.fullName
                                                }
                                            </b>
                                            <span>
                                                {this.state.replyToMsg.message
                                                    ? parse(
                                                        this.state.replyToMsg.message.toString()
                                                    )
                                                    : ''}
                                            </span>
                                        </Link>
                                        <Link
                                            to="#"
                                            className="close_rpl"
                                            onClick={() =>
                                                this.setState({
                                                    replyToMsg: null,
                                                })
                                            }
                                            title="Remove reply"
                                        >
                                            <svg
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g data-name="Layer 2">
                                                    <g data-name="close">
                                                        <rect
                                                            width="24"
                                                            height="24"
                                                            transform="rotate(180 12 12)"
                                                            opacity="0"
                                                        />
                                                        <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </Link>
                                    </div>
                                )}

                                {/*===============End-Reply=Section===============*/}
                                <div
                                    className="chat-input"
                                    id="chatContentArea"
                                    onKeyPress={(e) => console.log(e.key)}
                                >

                                    <RichTextEditor
                                        id="enterKey"
                                        placeholder="Type a message"
                                        toolbarConfig={toolbarConfig}
                                        value={this.state.message}
                                        onChange={this.onChangeMessageInput}
                                        toolbarClassName={
                                            this.state.isOpenToolbar
                                                ? 'custom-chat-toolbar show'
                                                : 'custom-chat-toolbar'
                                        }
                                        editorClassName="custom-chat-editor"
                                    />
                                    <Button
                                        color="link"
                                        className="attachment-btn text-fortamet p-0"
                                        onClick={() => {
                                            this.openToolbar();
                                        }}
                                    >
                                        <svg
                                            width="16px"
                                            height="16px"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                        >
                                            <path d="M3.36 7L1 13h1.34l.51-1.47h2.26L5.64 13H7L4.65 7H3.36zm-.15 3.53l.78-2.14.78 2.14H3.21zM11.82 4h-1.6L7 13h1.56l.75-2.29h3.36l.77 2.29H15l-3.18-9zM9.67 9.5l1.18-3.59c.059-.185.1-.376.12-.57.027.192.064.382.11.57l1.25 3.59H9.67z" />
                                        </svg>
                                    </Button>
                                    <Button
                                        color="link"
                                        className="attachment-btn p-0"
                                        onClick={this.showOpenFileDlg}
                                    >
                                        <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M21.4383 11.6622L12.2483 20.8522C11.1225 21.9781 9.59552 22.6106 8.00334 22.6106C6.41115 22.6106 4.88418 21.9781 3.75834 20.8522C2.63249 19.7264 2 18.1994 2 16.6072C2 15.015 2.63249 13.4881 3.75834 12.3622L12.9483 3.17222C13.6989 2.42166 14.7169 2 15.7783 2C16.8398 2 17.8578 2.42166 18.6083 3.17222C19.3589 3.92279 19.7806 4.94077 19.7806 6.00222C19.7806 7.06368 19.3589 8.08166 18.6083 8.83222L9.40834 18.0222C9.03306 18.3975 8.52406 18.6083 7.99334 18.6083C7.46261 18.6083 6.95362 18.3975 6.57834 18.0222C6.20306 17.6469 5.99222 17.138 5.99222 16.6072C5.99222 16.0765 6.20306 15.5675 6.57834 15.1922L15.0683 6.71222"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <input
                                            type="file"
                                            id="file"
                                            ref={this.inputFile}
                                            style={{ display: 'none' }}
                                            onChange={(e) =>
                                                this.handleFileChange(
                                                    e.target.files
                                                )
                                            }
                                        />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            var messageText =
                                                this.state.message.toString(
                                                    'html'
                                                );
                                            const valueIsEmpty =
                                                this.isValueBlank(messageText);
                                            if (!valueIsEmpty) {
                                                this.addNewMessage();
                                            } else {
                                                return false;
                                            }
                                        }}
                                        color="link"
                                        className="send-btn"
                                    >
                                        <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m6 12-3 9 18-9L3 3l3 9zm0 0h6"
                                            />
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </div>
            </div>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    chatList: makeChatSelectField('chatList'),
    messageListApiResponse: makeChatSelectField('messageListApiResponse'),
    editUserApiResponse: makeUserSelectField('editUserApiResponse'),
    addNewMessageApiResponse: makeChatSelectField('addNewMessageApiResponse'),
    sortedTaskUserListDropdown: selectSortedTaskUserList(),
    taskUserList: makeChatSelectField('taskUserList'),
    createChatApiResponse: makeChatSelectField('createChatApiResponse'),
    addOrRemoveUserApiResponse: makeChatSelectField(
        'addOrRemoveUserApiResponse'
    ),
    saveChatNotificationApiResponse: makeNotificationSelectField(
        'saveChatNotificationApiResponse'
    ),
    renameGroupApiResponse: makeChatSelectField('renameGroupApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getChatListByTaskIdRequest,
    getMessageListByChatIdRequest,
    getUserByUserIdRequest,
    addNewMessageRequest,
    getTaskUsersRequest,
    createChatRequest,
    addOrRemoveUserRequest,
    saveUserNotificationRequest,
    renameGroupRequest,
});
export default compose(
    withConnect,
    withRouter,
    withChatReducer,
    withChatSaga,
    withUserReducer,
    withUserSaga
)(ChatModule);
