export const LOGIN = '/login';
export const SEND_CODE = '/send-otp';
export const LOGOUT = '/logout';
export const CHANGE_PASSWORD = '/change-password';
export const FORGOT_PASSWORD = '/forgot-password';
export const CONFIRM_RESET_PASSWORD_TOKEN = '/confirm-reset-password-token';
export const RESET_PASSWORD = '/reset-password';
export const CONFIRM_ACTIVATE_ACCOUNT_TOKEN = '/confirm-activate-account-token';
export const ACTIVATE_ACCOUNT = '/activate-account';
export const COUNTRY = '/country';
export const COUNTRY_BY_STATUS = '/country/getCountriesByStatus';
export const GCM_AUTH_USER_COUNTRIES = '/gcm-auth-user/countries';
export const STATE = '/state';
export const STATE_BY_STATUS = '/state/getStatesByStatus';
export const STATE_BY_COUNTRY = '/state/list-by-country';
export const CITY = '/city';
export const CITY_BY_STATUS = '/city/getCitiesByStatus';
export const CITY_BY_STATE = '/city/list-by-state';
export const INDUSTRY = '/industry';
export const INDUSTRY_BY_STATUS = '/industry/geIndustryByStatus';
export const APPLICABILITY = '/applicability';
export const SUB_INDUSTRY = '/sub-industry';
export const APPLICABILITY_BY_STATUS = '/applicability/getApplicabilityByStatus';
export const SUB_APPLICABILITY_LIST_BY_APPLICABILITY = '/sub-applicability/list-by-applicability';
export const SUB_INDUSTRY_BY_STATUS = '/sub-industry/list';
export const SUB_INDUSTRY_LIST_BY_INDUSTRY = '/sub-industry/list-by-industry';
export const DEPARTMENT = '/department';
export const DEPARTMENT_BY_STATUS = '/department/getDepartmentByStatus';
export const DEPARTMENT_LIST_BY_ENTITY = '/department/company';
export const ROLES = '/roles';
export const MODULES = '/role-permission/module-list';
export const PERMISSIONS = '/role-permission/permission-list';
export const ROLE_PERMISSION = '/role-permission';
export const ROLE_PERMISSION_LOGIN_USER = '/role-permission-login-user';
export const ENTITY_ROLES = '/roles/entity-roles';
export const ENTITY_ROLE = '/entity-roles';
export const ROLES_WITH_ENTITY_ROLE_BY_ENTITY = '/entity-roles/roles';
export const ENTITY_ROLE_BY_ENTITY = '/entity-roles/list';
export const ENTITY_GROUP = '/company-group';
export const ENTITY_GROUP_BY_STATUS = '/company-group/list';
export const ENTITY_GROUP_BY_ENTITY = '/company-group/list-by-company';
export const LAW_CATEGORY = '/law-category';
export const LAW_CATEGORY_BY_STATUS = '/law-category/list';
export const ENTITY = '/entity';
export const ENTITY_DETAILS = '/entity/details';
export const ENTITY_BY_STATUS = '/entity/list';
export const ENTITY_BY_COMPANY_GROUP = '/entity/list-by-companygroup';
export const ENTITY_BY_COUNTRY = '/entity/list-by-country';
export const ENTITY_TEAM_BY_ENTITY = '/entity/users/team';
export const ENTITY_ALL_USERS_BY_ENTITY = '/entity/users/all';
export const ENTITY_LOCATION = '/entity-locations';
export const ENTITY_LOCATION_BY_STATUS = '/entity-locations/list';
export const ENTITY_LOCATION_BY_ENTITY = '/entity-locations/list-by-entity';
export const USER_BY_ENTITY_AND_ROLE = '/entity/user-list-by-entity-and-role';
export const USER = '/user';
export const UNBLOCK_EMAIL = '/unblock-email';
export const USER_BY_STATUS = '/user/list';
export const USER_RESEND_PASSWORD = '/user/resend-password';
export const USER_SET_NEW_PASSWORD = '/user/set-new-password';
export const USER_BY_ENTITY = '/user/list-by-company';
export const USER_PROFILE = '/profile';
export const USER_APPLICATION_THEME = '/change-application-theme';
export const FINANCIAL_YEARS = '/financial-years';
export const SECTION = '/law-sections';
export const SECTION_BY_STATUS = '/law-sections/list';
export const SECTION_LIST_BY_LAW = '/law-sections/list-by-law';
export const LAW = '/laws';
export const LAW_EDIT_VIA_UPDATE = '/laws-edit-via-update';
export const LAW_BY_STATUS = '/laws/list';
export const LAW_DETAILS = '/laws/details';
export const ENTITY_TYPE = '/entity-types';
export const FACILITY_TYPE = '/facility-types';
export const DOCUMENT_TYPE = '/documentTypes';
export const LAW_DOCUMENT = '/law-documents';
export const LAW_DOCUMENT_BY_STATUS = '/law-documents/list';
export const DOCUMENT_REPOSITORY_STANDARD = '/document-respository/standard';
export const DOCUMENT_REPOSITORY_TRANSACTIONAL = '/document-respository/transactional';
export const DOCUMENT_REPOSITORY_STANDARD_UPLOAD = '/document-respository/standard/uploads';
export const DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID = '/document-respository/relevant-compliances';
export const SYSTEM_CONFIGURATION_ROLES = '/system-configuration-roles';
export const SYSTEM_CONFIGURATION_USERS = '/system-configuration-users';
export const EVENT = '/event';
export const EVENT_LAW_COMPLIANCE_LIST = '/triggered-compliances';
export const EVENT_BY_STATUS = '/event/list';
export const EVENT_BY_SEARCH = '/event/search';
export const EVENT_BY_FILTER_FOR_ALLOCATION = '/event/filter-for-allocation';
export const EVENT_ORDER_UPDATE = '/event/update-order';

export const UPLOAD_COMPLIANCES = '/compliances/uploads';
export const UPLOAD_COMPLIANCES_FILE_LOGS = '/compliances/file-logs';
export const LAWS_SEARCH = '/laws/search';
export const LAWS_COUNTRY_COVERAGE_SEARCH = '/laws/country-coverage';
export const LAWS_BY_LOGIN_USER = '/get-laws-by-login-user';
export const LAW_DEFINITIONS_BY_LAW = '/law-definitions';
export const LAW_DEFINITIONS_VERSION_ADD = '/law-definition-version';
export const LAW_DEFINITIONS_VERSION_LIST = '/law-definition-versions-list';
export const LAW_DEFINITIONS_ADD = '/law-definition';
export const LAW_DOCUMENTS_BY_LAW = '/law-documents';
export const LAW_DOCUMENTS_VERSION_ADD = '/law-document-version';
export const LAW_DOCUMENTS_VERSION_LIST = '/law-document-versions-list';
export const LAW_DOCUMENTS_ADD = '/law-document';
export const LAW_COMPLIANCES_LIST_BY_LAW = '/law-compliances';
export const LAW_COMPLIANCES = '/law-compliances';
export const LAW_COMPLIANCE = '/law-compliance';
export const SET_COMPLIANCE_FOR_LITE = '/lite-law-compliances';

export const LAW_COMPLIANCE_DETAILS_BY_LAW_ID = '/law-compliance/details';
export const LAW_COMPLIANCES_FOR_ALLOCATION = '/law-compliances';
export const COMPLIANCES_FOR_ALLOCATE_TO_ENTITY = '/allocation/allocate-compliances';
export const ALLOCATE_CHECK_COMPLIANCES_ENTITY = '/allocation/check-compliance/entity-locations';
export const ALLOCATIONS = '/allocations';
export const ALLOCATIONS_SEARCH = '/allocation/all-allocated-compliances-list';
export const TRACK_ALLOCATIONS_SEARCH = '/track-allocations';
export const ASSIGN_USER_AND_DEPARTMENT_ALLOCATION = '/allocation/compliances/user-mapping';
export const UPDATE_ALLOCATION_STATUS = '/allocation/compliances/change-schedule-status';
export const DELETE_ALLOCATION = '/allocation';
export const GET_SCHEDULE_LIST = '/task-schedule/view';
export const UPDATE_SCHEDULE = '/task-schedule';

export const GET_AUDIT_LIST = '/audit-trail/view';
export const IMPORT_MASTER = '/master-upload';
export const IMPORT_MASTER_LOGS = '/master-upload-file-logs';
export const ALLOCATION_TASK = '/allocation-task';
export const TASK_STATUS = '/task-statuses';
export const USER_ENTITY = '/get-user-entities';
export const ASSIGNED_COMPLIANCES = '/get-user-assigned-compliances';
export const ASSIGNED_SEARCH_TASKS = '/task';
export const KNOW_YOUR_TASKS = '/task/know-your-compliances';
export const ASSIGNED_REPORT_SEARCH_TASK = '/assigned-report-search-task';
export const ASSIGNED_REVIEW_SEARCH_TASK = '/assigned-review-search-task';
export const ASSIGNED_AUDIT_SEARCH_TASK = '/assigned-audit-search-task';
export const TASK = '/task';
export const USER_ASSIGNED_SEARCH_TASKS = '/get-user-tasks';
export const TASK_USER_DETAILS = '/task/user-details';
export const TASK_ACTIVITY = '/task/activity-logs';
export const TASK_DOCUMENT = '/task/get-documents-by-task';
export const TASK_CHANGE_DETAILS = '/task/change-details';
export const TASK_REPORTING = '/task/reporting';
export const TASK_REOPEN = '/reopen-task';
export const TASK_SEND_FOR_AUDIT = '/task/send-audit';
export const SUB_TASK = '/sub-task';
export const SUB_TASK_BY_TASK = '/sub-task/list-by-task';
export const LIST_COLLABORATOR = '/get-collaborator-list-by-task';
export const ADD_COLLABORATOR = '/add-collaborator-by-task';
export const DELETE_COLLABORATOR = '/delete-collaborator-by-task';
export const GET_ASSIGNED_EVENTS = '/assigned-events';
export const GET_ASSIGNED_EVENT_COMPLIANCES = '/get-assigned-events-compliances';
export const PLAN_EVENT = '/plan-event';
export const PLAN_EVENT_CANCEL = '/plan-event-cancel';
export const PLAN_EVENT_UPDATE_USER_DETAILS = '/plan-event/update-user-details';
export const CHATS_BY_TASK = '/chats-by-task';
export const MESSAGES_BY_CHAT = '/messages-by-chat';
export const MESSAGE = '/message';
export const GET_ONGOING_EVENTS = '/ongoing-events';
export const GET_EXECUTED_EVENTS = '/executed-events';
export const PLAN_EVENT_DETAILS = '/plan-event-details';
export const TASK_USERS = '/taskUsers';
export const ADD_OR_REMOVE_USER = '/addOrRemoveUser';
export const CHAT = '/chat';
export const NOTIFICATION = '/notification';
export const NOTIFICATIONS = '/notifications';
export const READ_NOTIFICATIONS = '/notifications/read';
export const READ_NOTIFICATION = '/notification/read/';
export const RENAME_GROUP = '/rename-group';
export const USERS = '/users';
export const COMPANYGROUP = '/company-group';
export const AUDIT_OBSERVATION = '/task-audit-observation';

export const EMAIL_TEMPLATE_TYPES = '/email-templates/configurable-list';
export const TASK_USER_DELEGATION = '/task-user-delegation';
export const TASK_USER_DELEGATIONS = '/task-user-delegations';

export const FILE_UPLOAD = '/upload-file';
export const TASK_QUICK_UPDATE = '/task/quick-update-task-reporting';
export const GET_DASHBOARD_DATA_BY_ROLE = '/dashboard/get-data-by-role';
export const GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE = '/dashboard/get-data-for-month-wise-tasks';
export const GET_DASHBOARD_DETAILS_DATA = '/dashboard/get-details-data';
export const GET_DASHBOARD_ADMIN_DETAILS_DATA = '/dashboard/get-dashboard-details-data';
export const GET_DASHBOARD_M_DETAILS_DATA = '/dashboard/get-management-details-1-data';
export const GET_DASHBOARD_MANAGEMENT_DETAILS_DATA = '/dashboard/get-management-details-data';
export const GET_OVERALL_TASK_LIST = '/dashboard/get-overall-task-list';
export const GET_DASHBOARD_LAW_SEARCH = '/dashboard/get-law-search';
export const GET_DASHBOARD_LAW_COMPLIANCES_SEARCH = '/dashboard/get-law-compliance-search';
export const GET_DASHBOARD_LAW_DOCUMENTS_SEARCH = '/dashboard/get-law-document-search';

export const LAW_COMPLIANCES_SEARCH = '/law-compliances-search';
export const PROJECT_TASK_USERS_BY_ENTITY = '/project-task-users-by-entity';
export const PROJECT = '/project';
export const PROJECT_LIST = '/projects';
export const PROJECT_DETAILS = '/project-details/';
export const USERS_SEARCH = '/user-search';
export const PROJECT_TASK_LIST = '/projects-tasks';
export const PROJECT_TASK = '/project-task';
export const PROJECT_SUB_TASK = '/project-sub-task';
export const PROJECT_TASK_DETAILS = '/project-task-details/';
export const PROJECT_TASK_COMMENT = '/project-task/comment/';
export const PROJECT_TASK_ATTACHMENT = '/project-task-attachment';
export const PROJECT_TASK_ACTIVITY = '/project-task-activity/';

export const GET_DASHBOARD_LAW_BY_FILTERS = '/dashboard/get-law-by-filters';
export const GET_DASHBOARD_LAW_COMPLIANCE_BY_FILTERS = '/dashboard/get-law-compliance-by-filters';
export const GET_DASHBOARD_LAW_DOCUMENTS_FILTERS = '/dashboard/law-documents-by-filters';

export const PROJECT_TASK_DOCUMENT = '/project-task-document';
export const CLOSE_PROJECT = '/close-project';
export const ACTIVE_OR_DEACTIVE_PROJECT = '/active-or-deactive-project';
export const GET_USER_ROLE_WISE_COUNTRIES = '/country/getUserRoleWiseCountries/true';

export const COMPLIANCES_UPDATE_UPLOADS = '/compliances-update/uploads';
export const COMPLIANCES_UPDATE_FILE_LOGS = '/compliances-update/file-logs';
export const COMPLIANCES_LEGAL_UPDATE_SEARCH = '/compliances-legal-update-search';
export const RELEVANT_UPDATE_SEARCH = '/get-relevant-updates-search';

export const ENTITY_DEPARTMENT = '/entity/get-departments';

export const GCM_USERS_BY_COUNTRY = '/gcm-users-by-country';
export const ASSIGN_GCM_UPDATER_LEGAL_UPDATE = '/assign-gcm-updater/legal-update/';
export const GET_COMPLIANCE_LEGAL_UPDATE_BY_ID = '/legal-update';

export const GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID = '/legal-update-activity/legal-update';

export const STORE_DOWNLOAD_LOG = '/store-download-logs';
export const GET_DOWNLOAD_LOG = '/get-download-logs';

export const GET_COMPLIANCE_TYPES = '/get-compliance-types';
export const GET_COMPLIANCE_CATEGORIES = '/get-compliance-categories';
export const GET_PERIODICITIES = '/get-periodicities';

export const GET_FILING_TYPES = '/get-filing-types';
export const GET_MASTER_SAMPLE = '/get-master-sample';
export const GET_PENALTY_TYPES = '/get-penalty-types';
export const GET_RISK_RATING = '/get-risk-ratings';
export const GET_IMPACT_MANAGEMENT_PERSONNELS = '/impact-management-personnels';
export const LEGAL_UPDATE_UPLOAD_DOCUMENT = '/legal-update/upload-document';
export const COMPLIANCE_LEGAL_UPDATE = '/compliance-legal-update';
export const GET_LAW_COMPLIANCES = '/get-law-compliances/';
export const GET_LAW_SECTIONS = '/get-law-sections/';
export const UPDATE_TYPE = '/update-types';
export const SUB_UPDATE_TYPE = '/sub-update-types';
export const LEGAL_UPDATE_STATUS = '/legal-update-status';
export const UPDATER_COMPLIANCE_LEGAL_UPDATE = '/updater/compliance-legal-update';
export const CLOSE_LEGAL_UPDATE = '/complete-legal-update';

export const VERIFY_TOKEN = '/verify-token';
export const DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD = '/document-respository/standard/bulk-uploads';

export const GET_ARCHIVE_RECORDS_LIST = '/archive-records';
export const UPLOAD_ARCHIVE_RECORDS = '/upload-archive-records';
export const ARCHIVE_RECORD = '/archive-record';
export const REPORT_DATA_BY_TYPE = '/reports-by-type';
export const GET_CERTIFICATE_DATA = '/get-certificate-data';
export const REPORT_SYSTEM_RUNTIME = '/reports/system-runtime';
export const NEWS = '/news';
export const NEWS_LIST = '/news-list';
export const ACTIVATE_OR_DEACTIVATE_NEWS = '/news/activate-or-deactivate';
export const PUBLISH_NEWS = '/news/publish';
export const NEWS_WITH_SEARCH = '/news-search';
export const RELEASE_TYPE = '/release-types';
export const ARCHIVE_DEPARTMENTS = '/archive-departments';
export const CHANGE_STATUS_TRACK_ALLOCATION = '/track-allocation/change-status/bulk';
export const TRACK_ALLOCATION = '/track-allocation/compliances';
export const TRACK_ALLOCATION_ACTIVITIES = '/track-allocation/activities';
export const ALLOCATION_RENEWAL_DATE = '/allocation/renewal-date';
export const DETLETE_TRACK_ALLOCATION = '/track-allocation';
export const TENANT = '/tenant';
export const TENANT_STATUS_UPDATE = '/tenant/activate-or-deactivate';
export const TENANTS = '/tenants';
export const TENANT_LIST = '/tenant-list';
export const TENANT_BY_STATUS = '/tenants/list';
export const COMPLIANCE_VERSIONS = '/compliance-versions/';
export const COMPLAINCE_QUEUE_FILE = '/compliance-queue-file/';
export const VERIFY_CODE = '/verify-otp';
export const TENANT_DB_GENERATE = '/tenants/generate-database-config';

export const FUND_MASTER = '/fund-master';
export const FUND_MASTER_ACTIVE_INACTIVE = '/fund-master/active-or-deactive';
export const FUND_MASTER_LIST_BY_ENTITY = '/fund-master/company';

export const FUND_TASK_USERS_BY_ENTITY = '/fund-task-users-by-entity';
export const FUND = '/fund';
export const FUND_RETRIEVE = '/fund-retrieve';
export const FUND_TASK_SEQUENCE_ORDER = '/update-fund-task-sequence-order';
export const FUND_LIST = '/funds';
export const FUND_DETAILS = '/fund-details/';
export const FUND_TASK_LIST = '/funds-tasks';
export const FUND_TASK = '/fund-task';
export const FUND_SUB_TASK = '/fund-sub-task';
export const FUND_TASK_DETAILS = '/fund-task-details/';
export const FUND_TASK_COMMENT = '/fund-task/comment/';
export const FUND_TASK_ATTACHMENT = '/fund-task-attachment';
export const FUND_TASK_ACTIVITY = '/fund-task-activity/';
export const FUND_TASK_DOCUMENT = '/fund-task-document';
export const CLOSE_FUND = '/close-fund';
export const ACTIVE_OR_DEACTIVE_FUND = '/active-or-deactive-fund';
export const FUND_HEALTH_DETAILS = '/get-fund-health-check';
export const FUND_CHECK_ACCESS = '/fund-check-access';
export const FUND_TASK_COUNT = '/get-task-count-by-fund';
export const GET_DASHBOARD_DEPARTMENT_TASKS_BY_ENTITY = '/dashboard/get-department-tasks-by-entity';
export const GET_DASHBOARD_DATA_FOR_OVERALL = '/dashboard/get-data-for-risk';
export const DOWNLOAD_FILE_BY_MODEL = '/download-file-by-key';
export const USER_ROLE_LIST = '/user-roles';

export const TICKET = '/ticket';
export const TICKET_SEARCH = '/ticket-search';
export const TICKET_CATEGORY = '/ticket-categories';
export const TICKET_ASSIGNEE_USER = '/ticket-assignee-users';
export const TICKET_MESSAGE = '/ticket-message';
export const TICKET_HISTORY_LIST = '/ticket-messages';
export const TICKET_COUNT = '/ticket-count';

//lite version api userSelect: 

export const PRICING_LIST = "/lite-client-plans";
export const ACCOUNT_DETAILS_URL = "/lite-client-accounts/verified-lite-clients";

export const SELECT_CLIENT_PLAN = "/lite-client-accounts/trial-lite-plan";

export const REGISTER_CLIENT_LIST = "/lite-client-accounts/registered-clients";

export const RESEND_EMAIL_LITE = "/lite-client-accounts/client-registration";

export const STATUS_UPDATE_CLIENT = "/lite-client-accounts/verified-account";

export const LITE_COUNTRIES_LIST_API = "/lite-client-accounts/lite-country-list";

export const LITE_ENTITY_LIST_API = "/lite-entity-list";
export const LITE_ENTITY_DETAILS_API = "/lite-entity/country";


export const LITE_ENTITY_ADD_API = "/lite-entity";
export const LITE_ENTITY_ADD_SETUP_API = "/lite-entity-setup";

export const LITE_MANAGEMENT_LIST_API = "/lite-management-user-list";

export const LITE_QUESTION_API = "/lite-question";
export const LITE_QUESTION_LIST_API = "/lite-questions";
export const LITE_QUESTION_RESET_API = "/reaset-questionnaire";
export const LITE_COMPLIANCE_LIST_API = "/lite-law-compliances";

export const LITE_CITY_API_URL = "/lite-city-list";
export const LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_API_URL = "/lite-question-list";
export const LITE_QUESTION_CLIENT_RESPONSE_API_URL = "/lite-question-answer";
export const LITE_ACCOUNT_DETAILS_URL = "/lite-client-account";
export const LITE_COUNTRY_LIST_WISE_ENTITY_API_URL = "/lite-entities/lite-company";
export const LITE_ENTITY_UPDATE_NAME_API_URL = "/lite-entity-update";
export const LITE_ACCOUNT_UPDATE_API_URL = "/lite-client-account";


export const LITE_ENTITY_SETUP_STATE_CITY_API_URL = "/lite-entity-cities";
export const LITE_ENTITY_SETUP_STATE_API_URL = "/lite-entity-states";
export const LITE_ENTITY_DETAILS_BY_ID_API_URL = "/lite-entity";
export const LITE_ENTITY_WISE_QUESTION_LIST = "/lite-client";
export const LITE_SUSPEND_ACCOUNT_API_URL = "/lite-suspend-account";
export const LITE_COMPLIANCE_API_URL = "/lite-compliance";



















