import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => {
    return state.LiteAccountDetails || initialState
};
const makeLiteAccountSelectField = field => {
    return createSelector(reducerState, accountDetailsListState => {
        return accountDetailsListState[field]
    });
}
export {
    makeLiteAccountSelectField,
};
