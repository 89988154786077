import CustomisedModal from 'components/Application/CustomisedModal';
import React, { Component } from 'react';

class SeeMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false, // To toggle full string visibility
            isPopupOpen: false, // To toggle the popup visibility
        };
    }

    // Open the popup
    openPopup = () => {
        this.setState({ isPopupOpen: true });
    };

    // Close the popup
    closePopup = () => {
        this.setState({ isPopupOpen: false });
    };

    render() {
        const { value } = this.props;
        const { isExpanded, isPopupOpen } = this.state;

        // Split the string into an array of words
        const words = value.map((word) => word.trim());
        const firstTwoLines =
            words.length > (this.props.length ?? 20)
                ? words.slice(0, this.props.length ?? 20)
                : value; // Adjust as per how you want to split
        return (
            <div>
                {isExpanded
                    ? value.map((element, index) => {
                          return (
                              <span>
                                  {element}
                                  {index == firstTwoLines.length - 1
                                      ? null
                                      : ','}
                              </span>
                          );
                      })
                    : firstTwoLines.map((element, index) => (
                          <span key={index}>
                              {element}
                              {index == firstTwoLines.length - 1 ? null : ','}
                          </span>
                      ))}
                {!isExpanded && value.length > (this.props.length ?? 20) && (
                    <p onClick={this.openPopup}>See More</p>
                )}
                <CustomisedModal
                    modalName={this.props.modalName}
                    isModalOpen={isPopupOpen}
                    onModalDismiss={() => this.closePopup()}
                    classNameHeader={"entity-state-modal"}
                >
                    <p>
                        {value.map((element, index) => {
                            return <span key={index}>{element}</span>;
                        })}
                    </p>
                </CustomisedModal>
            </div>
        );
    }
}

export default SeeMore;
