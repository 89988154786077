import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import { monthList } from "constants/dropdowns";
import * as Yup from "yup";
import { animatedComponents, MultiValue, Option } from "helpers/projectUtils";
import Select from "react-select";
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import CreatableSelect from 'react-select/creatable';
import { Button, CardBody, FormGroup, Label, Row, Spinner } from "reactstrap";
import __t from "i18n/translator";
import { ACTIVE } from "constants/commonConstants";
import ReactTooltip from "react-tooltip";
import { commonConfirmBox } from "helpers/messageHelper";
import { liteEntityFormInitialValue } from "./commonFunc";
import { createFilter } from "react-select";
class LiteEntityForm extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (!this.props.isEditEntity) {
            this.props.getLiteCountriesEntityRequest({ countryId: this.props?.match?.params?.countriesId });
            // this.props.getCountryListByStatusRequest(ACTIVE);
            this.props.getStateListByCountryRequest((this.props?.match?.params?.countriesId) ? [this.props?.match?.params?.countriesId] : []);
            this.props.getIndustryListByStatusRequest(ACTIVE);
            this.props.getAllEntityTypeListRequest();
            this.props.getAllFacilityTypeListRequest();
            this.props.getEntityListByCompanyGroupIdRequest([2])
        }
    }

    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest((countryIds) ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest((stateIds) ? stateIds : []);
    }


    render() {
        return <>
            <CardBody>
                <Formik
                    initialValues={this.props.liteEntityInitialValue}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        industryId: Yup.string().required(__t("lite.entity.industry")),
                        entityId: Yup.string().test(
                            'valid-entity-name',
                            __t("lite.entity.entityNameValidation"),
                            (value) => {
                                const regexp = /^[a-zA-Z0-9 @._,()&-]+$/;
                                return regexp.test(value);
                            }
                        ).max(25, __t("lite.entity.entityValidation")).required(__t("lite.entity.entityName")),
                        managementUser: Yup.string().email(__t("lite.entity.emailId")).required(__t("lite.entity.managementUser")),
                        // subIndustryId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.subIndustry")).required(__t("lite.entity.subIndustry")),
                        financialYearStart: Yup.string().required(__t("lite.entity.financial_year_start")),
                        financialYearEnd: Yup.string().required(__t("lite.entity.financial_year_end")),
                        stateId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.state")).required(__t("lite.entity.state")),
                        cityId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.city")).required(__t("lite.entity.city")),
                        entityTypeId: Yup.string().required(__t("lite.entity.entity_type")),
                        facilityTypeId: Yup.array().of(Yup.string()).min(1, __t("lite.entity.facility_type")).required(__t("lite.entity.facility_type")),
                    })}
                    validateOnChange= {true}
                    validateOnBlur={true}
                    onSubmit={this.props.onFormSubmit}
                >
                    {({ values, setFieldValue, errors, handleBlur, touched, setTouched, isSubmitting, setFieldError, setFieldTouched, validateForm }) => {
                        return <Form className="w-100">
                            <Row className='filter-form'>
                                <FormGroup className='col-lg-3'>
                                    <Field
                                        component={CreatableSelect}
                                        name="entityId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.listOfLiteEntity
                                        }
                                        onChange={opt => {
                                            if (!this.props.isEditEntity) {

                                                if (opt?.__isNew__) {
                                                    setFieldValue("entityNewValue", true)
                                                } else {
                                                    setFieldValue("entityNewValue", false)
                                                }
                                                let findManagementUser;
                                            
                                                if(this.props.isEditEntity){
                                                    findManagementUser =  this.props.entityExtraData;
                                                }else{
                                                    findManagementUser = this.props.listOfEntityInLite && this.props.listOfEntityInLite.length > 0 && this.props.listOfEntityInLite.find((ele) => ele.id == opt?.value);
                                                }
                                                if (findManagementUser) {
                                                    setFieldValue("managementUser", findManagementUser?.userRoles[0]?.user?.email);
                                                    setFieldValue("managementUserId", findManagementUser?.userRoles[0]?.user?.id)
                                                    setFieldValue("financialYearStart", findManagementUser?.entityFinancialYears[0]?.startMonth);
                                                    setFieldValue("financialYearEnd", findManagementUser?.entityFinancialYears[0]?.endMonth);
                                                    setFieldValue("managementNewValue", false);
                                                    setFieldTouched("managementUser", false,false)
                                                    setFieldTouched("financialYearStart", false,false)
                                                    setFieldTouched("financialYearEnd", false,false)
                                                } else {
                                                    setFieldValue("managementUser", "")
                                                    setFieldValue("managementUserId", "")
                                                    setFieldValue("financialYearStart", "")
                                                    setFieldValue("financialYearEnd", "")
                                                    setFieldValue("managementNewValue", true);
                                                }
                                                setFieldValue("entityId", opt?.value)
                                            }
                                        }}
                                        value={values?.entityNewValue ? { label: values?.entityId, value: values?.entityId } : this.props.listOfLiteEntity?.filter(
                                            option => values?.entityId == option.value
                                        )}
                                        isDisabled={this.props.isEditEntity}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={true}
                                        isClearable={true}
                                        key="entityId"

                                    />
                                    {
                                        errors.entityId && <small className='error'>{errors.entityId}</small>
                                    }
                                    <Label className="form-label">
                                        {__t('form_labels.lite_entity.entity_setup.entity_name')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-3 d-flex flex-column'>
                                    <div className="d-flex w-100">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="stateId[]"
                                            id="state"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedStateListDropdown?.filter((opt) => opt.value !== -1)
                                            }
                                            onBlur={handleBlur}
                                            filterOption={createFilter({ ignoreAccents: false })}
                                            onChange={opt => {
                                                let selectedValues = (opt) ? opt.map(x => x.value) : [];
                                                setFieldValue(
                                                    'stateId',
                                                    selectedValues
                                                );
                                                setFieldValue(
                                                    'cityId',
                                                    []
                                                );
                                                setFieldValue("entityLocations", [])
                                                this.getCityListByState(selectedValues);
                                                if (this.props.isEditEntity) {
                                                    this.props.setEditValue({ ...values, stateId: [...selectedValues] });
                                                }
                                            }}
                                            value={this.props.sortedStateListDropdown?.filter(
                                                option =>
                                                    values.stateId?.includes(option.value)
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{ Option, MultiValue, animatedComponents }}
                                            key="stateId"
                                        />
                                        {/* State Tooltip */}
                                        <Button
                                            color='link'
                                            type='button'
                                            className='text-primary p-0 ml-2'
                                            data-tip
                                            data-for='state'
                                        >
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.89999 16.5H12.1V9.89999H9.89999V16.5ZM11 7.7C11.3117 7.7 11.5731 7.5944 11.7843 7.3832C11.9955 7.172 12.1007 6.91093 12.1 6.6C12.0993 6.28906 11.9937 6.028 11.7832 5.8168C11.5727 5.6056 11.3117 5.5 11 5.5C10.6883 5.5 10.4273 5.6056 10.2168 5.8168C10.0063 6.028 9.90073 6.28906 9.89999 6.6C9.89926 6.91093 10.0049 7.17236 10.2168 7.3843C10.4287 7.59623 10.6898 7.70146 11 7.7ZM11 22C9.47833 22 8.04833 21.7111 6.71 21.1332C5.37167 20.5553 4.2075 19.7718 3.2175 18.7825C2.2275 17.7932 1.44393 16.6291 0.866801 15.29C0.289668 13.9509 0.000734725 12.5209 1.3924e-06 11C-0.00073194 9.47906 0.288201 8.04906 0.866801 6.71C1.4454 5.37093 2.22897 4.20676 3.2175 3.2175C4.20603 2.22823 5.3702 1.44467 6.71 0.8668C8.0498 0.288933 9.4798 0 11 0C12.5202 0 13.9502 0.288933 15.29 0.8668C16.6298 1.44467 17.794 2.22823 18.7825 3.2175C19.771 4.20676 20.555 5.37093 21.1343 6.71C21.7136 8.04906 22.0022 9.47906 22 11C21.9978 12.5209 21.7089 13.9509 21.1332 15.29C20.5575 16.6291 19.774 17.7932 18.7825 18.7825C17.791 19.7718 16.6269 20.5557 15.29 21.1343C13.9531 21.7129 12.5231 22.0015 11 22Z" fill="currentColor" /></svg>
                                        </Button>
                                        <ReactTooltip id='state' type='dark' effect="solid" className='text-center'>
                                            <span className='text-center'>{__t("lite.entity.edit.tooltip")}</span>
                                        </ReactTooltip>
                                        <Label className="form-label">
                                            {__t('form_labels.lite_entity.entity_setup.state')}
                                        </Label>
                                    </div>
                                    {
                                        errors.stateId && touched.stateId && <small className='error'>{errors.stateId}</small>
                                    }

                                </FormGroup>
                                <FormGroup className='col-lg-3'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCityListDropdown?.filter((opt) => opt.value !== -1)
                                        }
                                        // filterOption={createFilter({ ignoreAccents: false })}
                                        onChange={opt => {
                                            let selectedValues = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'cityId',
                                                selectedValues
                                            );
                                            let StateCityArray = values.entityLocations.length > 0 ? [...values.entityLocations] : [];
                                            StateCityArray = opt ? opt.map((x) => {
                                                return {
                                                    stateId: x.stateId,
                                                    cityId: x.value
                                                }
                                            }) : [];
                                            setFieldValue("entityLocations", StateCityArray)
                                            if (this.props.isEditEntity) {
                                                this.props.setEditValue({ ...values, cityId: [...selectedValues] });
                                            }
                                        }}
                                        value={this.props.sortedCityListDropdown?.filter(
                                            option => values.cityId?.includes(option.value)
                                        )}
                                        onBlur={handleBlur}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                        key="cityId"
                                    />
                                    {
                                        errors.cityId && touched.cityId && <small className='error'>{errors.cityId}</small>
                                    }
                                    <Label className="form-label">
                                        {__t('form_labels.lite_entity.entity_setup.city')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className='col-lg-3'>
                                    <div className="d-flex w-100">
                                        <Field
                                            component={CreatableSelect}
                                            name="managementUser"
                                            className="form-control selectbox"
                                            options={[]}
                                            isDisabled={!values.entityNewValue ? true : false}
                                            onChange={async (opt) => {
                                                if (opt?.__isNew__) {
                                                    setFieldValue("managementNewValue", true)
                                                } else {
                                                    setFieldValue("managementNewValue", false)
                                                }
                                                setFieldValue("managementUser", opt?.value)
                                            }}
                                            onBlur={handleBlur}
                                            value={values?.managementNewValue ? { value: values.managementUser, label: values.managementUser } : { value: values.managementUser, label: values.managementUser }}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={true}
                                            isClearable={true}
                                            key="managementUser"
                                        />

                                        <Button
                                            color='link'
                                            type='button'
                                            className='text-primary p-0 ml-2'
                                            data-tip
                                            data-for='managementUser'
                                        >
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.89999 16.5H12.1V9.89999H9.89999V16.5ZM11 7.7C11.3117 7.7 11.5731 7.5944 11.7843 7.3832C11.9955 7.172 12.1007 6.91093 12.1 6.6C12.0993 6.28906 11.9937 6.028 11.7832 5.8168C11.5727 5.6056 11.3117 5.5 11 5.5C10.6883 5.5 10.4273 5.6056 10.2168 5.8168C10.0063 6.028 9.90073 6.28906 9.89999 6.6C9.89926 6.91093 10.0049 7.17236 10.2168 7.3843C10.4287 7.59623 10.6898 7.70146 11 7.7ZM11 22C9.47833 22 8.04833 21.7111 6.71 21.1332C5.37167 20.5553 4.2075 19.7718 3.2175 18.7825C2.2275 17.7932 1.44393 16.6291 0.866801 15.29C0.289668 13.9509 0.000734725 12.5209 1.3924e-06 11C-0.00073194 9.47906 0.288201 8.04906 0.866801 6.71C1.4454 5.37093 2.22897 4.20676 3.2175 3.2175C4.20603 2.22823 5.3702 1.44467 6.71 0.8668C8.0498 0.288933 9.4798 0 11 0C12.5202 0 13.9502 0.288933 15.29 0.8668C16.6298 1.44467 17.794 2.22823 18.7825 3.2175C19.771 4.20676 20.555 5.37093 21.1343 6.71C21.7136 8.04906 22.0022 9.47906 22 11C21.9978 12.5209 21.7089 13.9509 21.1332 15.29C20.5575 16.6291 19.774 17.7932 18.7825 18.7825C17.791 19.7718 16.6269 20.5557 15.29 21.1343C13.9531 21.7129 12.5231 22.0015 11 22Z" fill="currentColor" /></svg>
                                        </Button>
                                        <ReactTooltip id='managementUser' type='dark' effect="solid" className='text-center'>
                                            <span className='text-center'>{__t("lite.entity.edit.managementToolTip")}</span>
                                        </ReactTooltip>
                                        <Label className="form-label">
                                            {__t('form_labels.lite_entity.entity_setup.management_user')}
                                        </Label>
                                    </div>
                                    {
                                        errors.managementUser && touched.managementUser && <small className='error'>{errors.managementUser}</small>
                                    }
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={Select}
                                        name="financialYearStart"
                                        className="form-control selectbox"
                                        options={monthList.filter(option =>  option.value !== values.financialYearEnd)}
                                        onChange={opt => {
                                            setFieldValue(
                                                `financialYearStart`,
                                                opt.value
                                            );
                                        }}
                                        onBlur={handleBlur}
                                        isDisabled={!values.entityNewValue || this.props.isEditEntity ? true : false}
                                        value={monthList.filter(option =>
                                            option.value ===
                                            values.financialYearStart
                                        )}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        key={"financialYearStart"}
                                    />
                                    {
                                        errors.financialYearStart && touched.financialYearStart && <small className='error'>{errors.financialYearStart}</small>
                                    }
                                    <Label className="form-label">
                                        {__t('form_labels.lite_entity.entity_setup.financial_year_start')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={Select}
                                        name="financialYearEnd"
                                        className="form-control selectbox"
                                        options={monthList.filter(option =>  option.value !== values.financialYearStart)}
                                        onChange={opt => {
                                            setFieldValue(
                                                `financialYearEnd`,
                                                opt.value
                                            );
                                        }}
                                        onBlur={handleBlur}
                                        isDisabled={!values.entityNewValue || this.props.isEditEntity ? true : false}
                                        value={monthList.filter(option =>
                                            option.value ===
                                            values.financialYearEnd
                                        )}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        key={"financialYearEnd"}
                                    />
                                    {
                                        errors.financialYearEnd && touched.financialYearEnd && <small className='error'>{errors.financialYearEnd}</small>
                                    }
                                    <Label className="form-label">
                                        {__t('form_labels.lite_entity.entity_setup.financial_year_end')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className='col-lg-3'>
                                    <Field
                                        component={Select}
                                        name="entityType"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityTypeListDropDown?.filter((opt) => opt.value !== -1)
                                        }
                                        onChange={opt => {
                                            let entityTypeId =
                                                opt
                                                    ? opt?.value
                                                    : "";
                                            setFieldValue(
                                                'entityTypeId',
                                                entityTypeId
                                            );
                                            if (this.props.isEditEntity) {
                                                this.props.setEditValue({ ...values, entityTypeId: entityTypeId });
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={this.props.sortedEntityTypeListDropDown?.filter(
                                            option => values?.entityTypeId == option.value
                                        )}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        key="entityTypeId"
                                    />
                                    {
                                        errors.entityTypeId && touched.entityTypeId && <small className='error'>{errors.entityTypeId}</small>
                                    }
                                    <Label className="form-label">
                                        {__t('form_labels.lite_entity.entity_setup.entity_type')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-3'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="facilityType[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedFacilityTypeListDropDown?.filter((opt) => opt.value !== -1)
                                        }
                                        onChange={opt => {
                                            let facilityTypeId =
                                                opt
                                                    ? opt.map(
                                                        (x) =>
                                                            x.value
                                                    )
                                                    : [];
                                            setFieldValue(
                                                'facilityTypeId',
                                                facilityTypeId
                                            );
                                            if (this.props.isEditEntity) {
                                                this.props.setEditValue({ ...values, facilityTypeId: [...facilityTypeId] });
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={this.props.sortedFacilityTypeListDropDown?.filter(
                                            option => values?.facilityTypeId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                        key="facilityTypeId"
                                    />
                                    {
                                        errors.facilityTypeId && touched.facilityTypeId && <small className='error'>{errors.facilityTypeId}</small>
                                    }
                                    <Label className="form-label">
                                        {__t('form_labels.lite_entity.entity_setup.facility_type')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-3 mb-0'>
                                    <Field
                                        component={Select}
                                        name="industryId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedIndustryListDropdown?.filter((opt) => opt.value !== -1)
                                        }
                                        onChange={opt => {
                                            let industryId =
                                                opt
                                                    ? opt.value
                                                    : "";
                                            setFieldValue(
                                                'industryId',
                                                industryId
                                            );
                                            setFieldValue(
                                                'subIndustryId',
                                                []
                                            );
                                            if (industryId != '') {
                                                this.props.getSubIndustryListByIndustryRequest([industryId])
                                            } else {
                                                this.setState({
                                                    sortedSubIndustryListDropdown: []
                                                })
                                            }
                                            if (this.props.isEditEntity) {
                                                this.props.setEditValue({ ...values, industryId: industryId });
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={this.props.sortedIndustryListDropdown?.filter(
                                            option => values?.industryId == option.value
                                        )}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        key="industryId"
                                    />
                                    {
                                        errors.industryId && touched.industryId && <small className='error'>{errors.industryId}</small>
                                    }

                                    <Label className="form-label">
                                        {__t('form_labels.lite_entity.entity_setup.industry')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-3 mb-0'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="subIndustryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedSubIndustryListDropdown?.filter((opt) => opt.value !== -1)
                                        }
                                        onChange={opt => {
                                            let subIndustry = opt
                                                ? opt.map(
                                                    (x) =>
                                                        x.value
                                                )
                                                : [];
                                            setFieldValue(
                                                'subIndustryId',
                                                subIndustry
                                            );
                                            if (this.props.isEditEntity) {
                                                this.props.setEditValue({ ...values, subIndustryId: [...subIndustry] });
                                            }
                                        }}
                                        value={this.props.sortedSubIndustryListDropdown?.filter(
                                            option => values?.subIndustryId?.includes(option.value)
                                        )}
                                        isMulti
                                        onBlur={handleBlur}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                        key="subIndustryId"
                                    />
                                    {
                                        errors.subIndustryId && touched.subIndustryId && <small className='error'>{errors.subIndustryId}</small>
                                    }
                                    <Label className="form-label">
                                        {__t('form_labels.lite_entity.entity_setup.sub_industry')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-6 d-flex justify-content-end align-items-baseline mb-0">
                                    <Button
                                        type="reset"
                                        color="secondary"
                                        className='mr-3'
                                        onClick={() => {
                                            if (this.props.isEditEntity) {
                                                this.props.isEditEntityCancel();
                                            }
                                        }}
                                    >
                                        {__t("common.cancel")}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color='primary'
                                        className='d-block' disabled={this.props.entityAddLoader}>
                                        {this.props.entityAddLoader ? <Spinner color="white" size={"sm"} /> : this.props.isEditEntity ? __t("form_labels.lite_entity.update") : __t("form_labels.lite_entity.submit")}
                                    </Button>
                                </FormGroup>
                            </Row>
                        </Form>
                    }}
                </Formik >
            </CardBody>
        </>
    }
}

export default LiteEntityForm;