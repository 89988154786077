import React, { Component } from 'react';
import {
    Row,
    Col,
    CardBody,
    Card,
    Collapse,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Nav,
    Badge,
    Button,
} from 'reactstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE_LABEL,
    DANGER,
    DEFAULT_PAGE_LENGTH,
    INACTIVE_LABEL,
    NA,
    SUCCESS,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getLawsSearchListRequest,
    getLawComplianceSearchRequest,
    setComplianceForLiteRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { makeLawSelectField } from 'store/Masters/Law/selector';
import SearchForm from './SearchForm';
import ComplianceSearchForm from './ComplianceSearchForm';

import ShowLaw from './ShowLaw';
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import __t from 'i18n/translator';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import AuditLogModal from 'components/Application/AuditLogModal';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import { formatDateForApiSystem } from 'helpers/dateTimeHelper';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedReactTableWithPaginationAndRowSelection from 'components/Application/CustomisedReactTableWithPaginationAndRowSelection';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { ROLE_SUPER_ADMIN } from 'constants/databaseConstants';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';

const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});

const lawSearchListColumns = [
    {
        Header: 'Code',
        accessor: 'lawCode',
    },
    {
        Header: 'Country',
        TransHeader: 'tables.statutory.country',
        accessor: 'countryName',
    },
    {
        Header: 'State',
        TransHeader: 'tables.statutory.state',
        accessor: 'stateName',
        disableSortBy: true,
    },

    {
        Header: 'Law Category',
        TransHeader: 'tables.statutory.law_category',
        accessor: 'lawCategoryName',
    },
    {
        Header: 'Short Name Of Act/Legislation',
        TransHeader: 'tables.statutory.law_short_name',
        accessor: 'lawShortName',
    },
    {
        Header: 'Effective Date',
        TransHeader: 'tables.statutory.effective_date',
        accessor: 'effectiveDate',
    },
    {
        Header: 'Nature Of Applicability',
        TransHeader: 'tables.statutory.nature_applicability',
        accessor: 'natureApplicabilityName',
    },
    {
        Header: 'Action',
        TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];

const lawComplianceSearchListColumns = [
    {
        Header: 'Code',
        accessor: 'complianceCode',
    },
    {
        Header: 'Country',
        TransHeader: 'tables.statutory.country',
        accessor: 'countryName',
    },
    {
        Header: 'State',
        TransHeader: 'tables.statutory.state',
        accessor: 'stateName',
        disableSortBy: true,
    },
    {
        Header: 'City',
        TransHeader: 'tables.statutory.city',
        accessor: 'cityName',
        disableSortBy: true,
    },
    {
        Header: 'Short Name Of Act/Legislation',
        TransHeader: 'tables.statutory.law_short_name',
        accessor: 'lawShortName',
    },
    {
        Header: 'Compliance Title',
        accessor: 'complianceTitle',
    },
    {
        Header: 'Applicablity Details',
        accessor: 'complianceApplicability',
    },
    {
        Header: 'Due Date',
        TransHeader: 'tables.statutory.due_date',
        accessor: 'dueDateDescription',
    },
    {
        Header: 'Frequency',
        TransHeader: 'tables.statutory.frequency',
        accessor: 'periodicityName',
    },
    {
        Header: 'Action',
        TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];
const initialSearchDataState = () => {
    return {
        searchText: '',
        countryId: '',
        stateId: '',
        cityId: '',
        industryId: [],
        lawId: [],
        fromDate: '',
        toDate: '',
        entityId: [],
        complianceCategoryId: [],
        complianceTypeId: [],
        facilityTypeId: [],
        subIndustryId: [],
        lawCategoryId: [],
    };
};
export const initialSearchCompliancesDataState = () => {
    return {
        countryId: [],
        eventId: [],
        stateId: [],
        cityId: [],
        industryId: [],
        subIndustryId: [],
        lawCategoryId: [],
        lawId: [],
        entityId: [],
        status: 1,
        roleId: JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)))
    };
};
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.fetchIdRef = React.createRef(0);
        this.sortIdRef = React.createRef(0);
        this.myRef = React.createRef();
        this.state = {
            activeTab: 'lawsTab',
            viewDialog: true,
            searchLawList: [],
            viewLawSection: false,
            resetTableSection: false,
            lawId: '',
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            searchFormData: initialSearchDataState(),
            totalRecords: 0,
            isAuditModalOpen: false,
            auditId: '',
            lawCompliance: false,
            liteBulkCompliance: [],
            liteLawId: [],
            searchCompliancesFormData: initialSearchCompliancesDataState(),
            fromDashboard: {
                openTab: this.props.match.params.openTab
                    ? this.props.match.params.openTab
                    : '',
                filterType: this.props.match.params.filterType
                    ? this.props.match.params.filterType
                    : '',
                selectedId: this.props.match.params.selectedId
                    ? this.props.match.params.selectedId
                    : '',
                preSelectedId: this.props.match.params.preSelectedId
                    ? this.props.match.params.preSelectedId
                    : '',
            },
        };
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount() {
        if (
            this.state.fromDashboard.openTab == 'laws' ||
            this.state.fromDashboard.openTab == 'compliances'
        ) {
            let dataFiltered = { countryId: '', lawCategoryId: [], entityId: [] };
            let dataCmpFiltered = { countryId: [], lawCategoryId: [], entityId: [] };
            if (this.state.fromDashboard.openTab == 'laws') {
                if (this.state.fromDashboard.filterType == 'country') {
                    dataFiltered = {
                        countryId: Number(this.state.fromDashboard.selectedId),
                    };
                }
                if (this.state.fromDashboard.filterType == 'state') {
                    dataFiltered = {
                        countryId: Number(this.state.fromDashboard.preSelectedId),
                        stateId: Number(this.state.fromDashboard.selectedId),
                    };
                }
                if (this.state.fromDashboard.filterType == 'lawCategory') {
                    dataFiltered = {
                        lawCategoryId: [
                            Number(this.state.fromDashboard.selectedId),
                        ],
                    };
                }
                if (this.state.fromDashboard.filterType == 'entity') {
                    dataFiltered = {
                        entityId: [
                            Number(this.state.fromDashboard.selectedId),
                        ],
                    };
                }
            }
            if (this.state.fromDashboard.openTab == 'compliances') {
                if (this.state.fromDashboard.filterType == 'lawCategory') {
                    dataCmpFiltered = {
                        lawCategoryId: [
                            Number(this.state.fromDashboard.selectedId),
                        ],
                    };
                } else if (this.state.fromDashboard.filterType == 'entity') {
                    dataCmpFiltered = {
                        entityId: [
                            Number(this.state.fromDashboard.selectedId),
                        ],
                    };
                } else {
                    dataCmpFiltered = {
                        countryId: [Number(this.state.fromDashboard.selectedId)],
                    };
                }
            }
            let searchFormData = this.state.searchFormData;
            let searchCompliancesFormData =
                this.state.searchCompliancesFormData;
            this.setState(
                {
                    activeTab:
                        this.state.fromDashboard.openTab == 'laws'
                            ? 'compliancesTab'
                            : 'lawsTab',
                },
                () => {
                    if (this.state.fromDashboard.selectedId != '') {
                        this.setState(
                            {
                                searchFormData: {
                                    ...searchFormData,
                                    ...dataFiltered,
                                },
                                searchCompliancesFormData: {
                                    ...searchCompliancesFormData,
                                    ...dataCmpFiltered,
                                },
                                lawCompliance:
                                    this.state.fromDashboard.openTab == 'laws'
                                        ? false
                                        : true,
                            },
                            () => {
                                this.toggle(
                                    this.state.fromDashboard.openTab + 'Tab'
                                );
                            }
                        );
                    } else {
                        this.toggle(this.state.fromDashboard.openTab + 'Tab');
                    }
                }
            );
        } else {
            this.getLawsSearchList(
                this.state.pageSize,
                this.state.currentPage,
                this.state.sortBy,
                {}
            );
        }
    }
    getLawComplianceSearchList = (limit, page, sortBy, values = {}) => {

        let apiData = {
            limit: limit,
            page: page,
            filter: {
                ...values,
                fromDate: values.fromDate
                    ? formatDateForApiSystem(values.fromDate)
                    : '',
                toDate: values.toDate
                    ? formatDateForApiSystem(values.toDate)
                    : '',
            },
            orderBy: sortBy,
        };
        this.props.getLawComplianceSearchRequest(apiData, true);
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
                searchCompliancesFormData:
                    this.state.lawCompliance === true
                        ? values
                        : this.state.searchCompliancesFormData,
            },
            () => {
                this.state.lawCompliance === false
                    ? this.getLawsSearchList(
                        this.state.pageSize,
                        this.state.currentPage,
                        this.state.sortBy,
                        this.state.searchFormData
                    )
                    : this.getLawComplianceSearchList(
                        this.state.pageSize,
                        this.state.currentPage,
                        this.state.sortBy,
                        this.state.searchCompliancesFormData,
                    );
            }
        );
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                    viewLawSection: false,
                },
                () => {
                    switch (tab) {
                        case 'lawsTab':
                            this.getLawsSearchList(
                                DEFAULT_PAGE_LENGTH,
                                1,
                                {
                                    key: 'createdAt',
                                    value: 'DESC',
                                },
                                this.state.searchFormData
                            );
                            break;
                        case 'compliancesTab':
                            this.getLawComplianceSearchList(
                                DEFAULT_PAGE_LENGTH,
                                1,
                                {
                                    key: 'createdAt',
                                    value: 'DESC',
                                },
                                this.state.searchCompliancesFormData,
                            );
                            break;
                        default:
                            break;
                    }
                }
            );
        }
    }
    getLawsSearchList = (limit, page, sortBy, values = {}) => {
        values = {
            ...values,
            countryId: values.countryId ? [values.countryId] : [],
            stateId: values.stateId ? [values.stateId] : [],
            cityId: values.cityId ? [values.cityId] : [],
            fromDate: values.fromDate
                ? formatDateForApiSystem(values.fromDate)
                : '',
            toDate: values.toDate ? formatDateForApiSystem(values.toDate) : '',
        };
        let apiData = {
            limit: limit,
            page: page,
            filter: values,
            orderBy: sortBy,
        };
        this.props.getLawsSearchListRequest(apiData, true);
    };
    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    searchCompliancesFormData: {
                        ...this.state.searchCompliancesFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.state.activeTab == 'lawsTab'
                        ? this.getLawsSearchList(
                            data.pageSize,
                            data.pageIndex + 1,
                            this.state.sortBy,
                            this.state.searchFormData
                        )
                        : this.getLawComplianceSearchList(
                            data.pageSize,
                            data.pageIndex + 1,
                            this.state.sortBy,
                            this.state.searchCompliancesFormData,
                        );
                }
            );
        }
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    searchCompliancesFormData:
                        this.state.activeTab == 'lawsTab'
                            ? this.state.searchCompliancesFormData
                            : {
                                ...this.state.searchCompliancesFormData,
                                searchText: data.globalFilter,
                            },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.state.activeTab == 'lawsTab'
                        ? this.getLawsSearchList(
                            data.pageSize,
                            data.pageIndex + 1,
                            { key: data.sortBy[0]?.id, value: sortOrder },
                            this.state.searchFormData
                        )
                        : this.getLawComplianceSearchList(
                            data.pageSize,
                            data.pageIndex + 1,
                            { key: data.sortBy[0]?.id, value: sortOrder },
                            this.state.searchCompliancesFormData,
                        );
                }
            );
        }
    }
    handleSearch(data) {
        let pageNum =
            data?.globalFilter == this.state.searchFormData?.searchText
                ? data.pageIndex + 1
                : 1;
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
                searchCompliancesFormData:
                    this.state.activeTab == 'lawsTab'
                        ? this.state.searchCompliancesFormData
                        : {
                            ...this.state.searchCompliancesFormData,
                            searchText: data.globalFilter,
                        },
            },
            () => {
                this.state.activeTab == 'lawsTab'
                    ? this.getLawsSearchList(
                        this.state.pageSize,
                        pageNum,
                        this.state.sortBy,
                        this.state.searchFormData
                    )
                    : this.getLawComplianceSearchList(
                        this.state.pageSize,
                        pageNum,
                        this.state.sortBy,
                        this.state.searchCompliancesFormData,
                    );
            }
        );
    }
    resetForm = () => {
        if (
            this.state.fromDashboard.openTab == 'laws' ||
            this.state.fromDashboard.openTab == 'compliances'
        ) {
            this.props.history.push('/statutory-compliances');
        }
        this.state.lawCompliance === false
            ? this.setState(
                {
                    searchFormData: initialSearchDataState(),
                    currentPage: 1,
                    resetTableSection: true,
                },
                () => {
                    this.getLawsSearchList(this.state.pageSize, 1, {
                        key: 'createdAt',
                        value: 'DESC',
                    }, {});
                }
            )
            : this.setState(
                {
                    searchCompliancesFormData:
                        initialSearchCompliancesDataState(),
                    currentPage: 1,
                    resetTableSection: true,
                },
                () => {
                    this.getLawComplianceSearchList(
                        this.state.pageSize,
                        1,
                        {
                            key: 'createdAt',
                            value: 'DESC',
                        },
                        {},
                    );
                }
            );
    };
    viewLawDetails = (record) => {
        this.setState({
            viewLawSection: true,
            lawId: record.id,
        });
    };
    closeViewLawSection = (record) => {
        this.setState({
            viewLawSection: false,
            lawId: '',
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let actsRecords = [];
        actsRecords = records.map((lawDataRecord, i) => {
            let actions = (
                <div className="text-center d-flex">
                    <a
                        href={() => false}
                        title="View Details"
                        className="btn btn-link tbl-action text-success"
                        onClick={
                            () => this.viewLawDetails(lawDataRecord)
                        }
                    >
                        <i className="far fa-eye"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(lawDataRecord.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                    {lawDataRecord?.legalUpdateCount > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.props.history.push(
                                    '/statutory-compliances/edit-law-details/' +
                                    lawDataRecord.id,
                                    '_blank'
                                );
                            }}
                        >
                            <i className="fas fa-edit"></i>
                        </a>
                    )}
                </div>
            );
            return {
                lawCode: lawDataRecord?.lawCode,
                countryName: lawDataRecord.country?.countryName,
                stateName: lawDataRecord.state
                    ? lawDataRecord.state?.stateName
                    : NA,
                lawCategoryName: lawDataRecord.lawCategory?.lawCategoryName,
                lawShortName: lawDataRecord.lawShortName,
                effectiveDate: lawDataRecord.effectiveDate,
                natureApplicabilityName: lawDataRecord.natureApplicabilityName,
                actions: actions,
            };
        });
        return actsRecords;
    };
    checkApplicationLevel = (law) => {
        let ApplicationLevel = '-';
        if (law.countryId != '') {
            if (
                (law.stateId == '' && law.cityId == '') ||
                (law.cityId == '-1' && law.stateId == '-1')
            ) {
                ApplicationLevel = 'Central';
            } else if (
                law.stateId != '' &&
                (law.cityId == '' || law.cityId == '-1')
            ) {
                ApplicationLevel = 'State';
            } else if (law.stateId != '' && law.cityId != '') {
                ApplicationLevel = 'Local';
            }
        }
        return ApplicationLevel;
    };
    generateTable2 = (records) => {
        let actsRecords = [];
        actsRecords = records?.map((lawDataRecord, i) => {
            const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)))

            let actions = (
                <div className="text-center table-action">
                    {(lawDataRecord?.isActive == true) ? (
                        <Badge color='success' pill>{ACTIVE_LABEL}</Badge>
                    ) : (
                        <Badge color='danger' pill>{INACTIVE_LABEL}</Badge>
                    )}
                    {
                        currentRole[0].roleId !== ROLE_SUPER_ADMIN ? null : lawDataRecord.isLite ? <a title={__t('common.sent_to_lite')} onClick={() => {
                            // this.props.setComplianceForLiteRequest({
                            //     lawId: [lawDataRecord?.law?.id],
                            //     complianceId: [lawDataRecord?.id],
                            //     isLite: 0,
                            // })
                        }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.358 1.36601C7.79853 1.12584 8.29226 1 8.794 1C9.29574 1 9.78947 1.12584 10.23 1.36601L15.024 3.98001C15.4973 4.23804 15.8923 4.61883 16.1675 5.08234C16.4428 5.54585 16.588 6.07495 16.588 6.61401V11.552C16.588 12.0911 16.4428 12.6202 16.1675 13.0837C15.8923 13.5472 15.4973 13.928 15.024 14.186L10.23 16.8C9.78947 17.0402 9.29574 17.166 8.794 17.166C8.29226 17.166 7.79853 17.0402 7.358 16.8L2.563 14.186C2.08989 13.9278 1.69507 13.547 1.42003 13.0835C1.145 12.62 0.9999 12.091 1 11.552V6.61301C0.9999 6.07405 1.145 5.54502 1.42003 5.08152C1.69507 4.61802 2.08989 4.23717 2.563 3.97901L7.358 1.36601Z" fill="#676767" fill-opacity="0.1" stroke="#676767" stroke-opacity="0.7" />
                                <path d="M7.86144 6.36393C8.36806 5.45464 8.62137 5 9 5C9.37863 5 9.63194 5.45464 10.1386 6.36393L10.2692 6.59859C10.4132 6.85724 10.4852 6.98524 10.5972 7.0719C10.7105 7.15589 10.8505 7.18789 11.1305 7.25189L11.3838 7.30922L11.3894 7.31049C12.3695 7.5323 12.8599 7.64325 12.977 8.01985C13.0941 8.39527 12.7593 8.78663 12.0908 9.56822L12.0877 9.57178L11.9144 9.77444L11.9013 9.78976C11.7193 10.0035 11.6268 10.1123 11.5851 10.2477C11.5431 10.3842 11.5566 10.5284 11.5838 10.8174L11.5851 10.8317L11.6118 11.1024C11.7131 12.1477 11.7637 12.6716 11.4584 12.9036C11.1518 13.1369 10.6905 12.9249 9.76926 12.501L9.53195 12.3903L9.52821 12.3886C9.26806 12.2697 9.13799 12.2103 9 12.2103C8.86201 12.2103 8.73193 12.2697 8.47179 12.3886L8.46805 12.3903L8.23074 12.501C7.30949 12.9249 6.8482 13.1369 6.54156 12.9036C6.23604 12.6725 6.28655 12.1515 6.38714 11.1138L6.38824 11.1024L6.41491 10.8317L6.41607 10.8193C6.4433 10.5289 6.45698 10.3832 6.41491 10.2477C6.37323 10.1123 6.28066 10.0035 6.09865 9.78976C6.09435 9.78471 6.09001 9.7796 6.08561 9.77444L5.91229 9.57178L5.90924 9.56822C5.24067 8.78663 4.90589 8.39527 5.02304 8.01985C5.14014 7.64325 5.63045 7.5323 6.61064 7.31049L6.61622 7.30922L6.86953 7.25189C7.14951 7.18789 7.28949 7.15589 7.40282 7.0719C7.51481 6.98657 7.5868 6.85724 7.73079 6.59859L7.86144 6.36393Z" fill="#676767" fill-opacity="0.7" />
                                <path d="M7.86144 6.36393C8.36806 5.45464 8.62137 5 9 5C9.37863 5 9.63194 5.45464 10.1386 6.36393L10.2692 6.59859C10.4132 6.85724 10.4852 6.98524 10.5972 7.0719C10.7105 7.15589 10.8505 7.18789 11.1305 7.25189L11.3838 7.30922L11.3894 7.31049C12.3695 7.5323 12.8599 7.64325 12.977 8.01985C13.0941 8.39527 12.7593 8.78663 12.0908 9.56822L12.0877 9.57178L11.9144 9.77444L11.9013 9.78976C11.7193 10.0035 11.6268 10.1123 11.5851 10.2477C11.5431 10.3842 11.5566 10.5284 11.5838 10.8174L11.5851 10.8317L11.6118 11.1024C11.7131 12.1477 11.7637 12.6716 11.4584 12.9036C11.1518 13.1369 10.6905 12.9249 9.76926 12.501L9.53195 12.3903L9.52821 12.3886C9.26806 12.2697 9.13799 12.2103 9 12.2103C8.86201 12.2103 8.73193 12.2697 8.47179 12.3886L8.46805 12.3903L8.23074 12.501C7.30949 12.9249 6.8482 13.1369 6.54156 12.9036C6.23604 12.6725 6.28655 12.1515 6.38714 11.1138L6.38824 11.1024L6.41491 10.8317L6.41607 10.8193C6.4433 10.5289 6.45698 10.3832 6.41491 10.2477C6.37323 10.1123 6.28066 10.0035 6.09865 9.78976C6.09435 9.78471 6.09001 9.7796 6.08561 9.77444L5.91229 9.57178L5.90924 9.56822C5.24067 8.78663 4.90589 8.39527 5.02304 8.01985C5.14014 7.64325 5.63045 7.5323 6.61064 7.31049L6.61622 7.30922L6.86953 7.25189C7.14951 7.18789 7.28949 7.15589 7.40282 7.0719C7.51481 6.98657 7.5868 6.85724 7.73079 6.59859L7.86144 6.36393Z" stroke="#676767" />
                            </svg>
                        </a> : <a title={__t('common.send_to_lite')} style={{ cursor: "pointer" }} onClick={async() => {
                             const response = await commonConfirmBox(
                                __t('common.are_you_sure'),
                                __t('common.are_you_sure_compliance_lite')
                            );
                            if (response) {
                                this.props.setComplianceForLiteRequest({
                                    lawId: [lawDataRecord?.law?.id],
                                    complianceId: [lawDataRecord?.id],
                                    isLite: 1,
                                })
                            }
                        }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10Z" fill="url(#paint0_linear_3096_26)" />
                                <path d="M8.09557 6.39764L8.29237 6.04483C9.05198 4.68161 9.43239 4 10 4C10.5676 4 10.948 4.68161 11.7076 6.04483L11.9044 6.39764C12.1204 6.78525 12.2284 6.97845 12.3964 7.10565C12.5644 7.23405 12.7756 7.28205 13.1945 7.37686L13.5761 7.46326C15.0521 7.79686 15.7901 7.96367 15.9653 8.52888C16.1417 9.09288 15.6377 9.68089 14.6321 10.8581L14.3717 11.1629C14.0861 11.4965 13.9433 11.6645 13.8797 11.8709C13.8137 12.0773 13.8353 12.3005 13.8797 12.7469L13.9181 13.1526C14.0705 14.7222 14.1461 15.507 13.6865 15.8562C13.2269 16.2042 12.5368 15.8874 11.1544 15.2514L10.7968 15.0858C10.4044 14.9058 10.2088 14.8146 10 14.8146C9.7924 14.8146 9.59559 14.9058 9.20319 15.0858L8.84558 15.2514C7.46316 15.8874 6.77315 16.2054 6.31354 15.8562C5.85393 15.5082 5.92953 14.7222 6.08193 13.1526L6.12034 12.7469C6.16474 12.3005 6.18634 12.0773 6.12034 11.8709C6.05673 11.6645 5.91393 11.4965 5.62833 11.1629L5.36792 10.8581C4.36231 9.68209 3.8583 9.09408 4.0347 8.52888C4.2099 7.96487 4.94672 7.79686 6.42274 7.46326L6.80555 7.37686C7.22555 7.28085 7.43436 7.23405 7.60356 7.10685C7.77156 6.97845 7.87956 6.78405 8.09557 6.39764Z" fill="white" />
                                <defs><linearGradient id="paint0_linear_3096_26" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse"><stop stop-color="#FFD57D" /><stop offset="0.77" stop-color="#F8B425" /></linearGradient></defs>
                            </svg>
                        </a>
                    }

                    <a
                        href={() => false}
                        title="View Details"
                        className="btn btn-link tbl-action text-success"
                        onClick={() => {

                            window.open(
                                '/statutory-compliances/view-details/' +
                                lawDataRecord.id,
                                '_blank'
                            );
                        }}
                    >
                        <i className="far fa-eye"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(lawDataRecord.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>


                    {lawDataRecord?.legalUpdateCount > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.props.history.push(
                                    '/statutory-compliances/edit-details/' +
                                    lawDataRecord.id,
                                    '_blank'
                                );
                            }}
                        >
                            <i className="fas fa-edit"></i>
                        </a>
                    )}
                </div>
            );
            const returnValue = {
                countryName: lawDataRecord?.law?.country?.countryName,
                stateName: lawDataRecord?.law?.state
                    ? lawDataRecord?.law?.state?.stateName
                    : NA,
                cityName: lawDataRecord?.law?.cityName,
                lawId: lawDataRecord?.law?.id,
                complianceTitle: lawDataRecord?.complianceTitle,
                complianceId: lawDataRecord?.id,
                complianceCode: lawDataRecord?.complianceCode,
                sectionReference: lawDataRecord?.sectionReference,
                ruleReference: lawDataRecord?.ruleReference,
                complianceApplicability:
                    lawDataRecord.complianceApplicability !== null
                        ? lawDataRecord.complianceApplicability
                        : '',
                complianceClassification:
                    lawDataRecord?.complianceCategory?.complianceCategoryName,
                complianceClass:
                    lawDataRecord?.complianceType?.complianceTypeName,
                lawShortName: lawDataRecord?.law?.lawShortName,
                periodicityName: lawDataRecord?.periodicity?.periodicityName,
                dueDateDescription: lawDataRecord?.dueDateDescription,
                status: (lawDataRecord?.isActive == true) ? ACTIVE_LABEL : INACTIVE_LABEL,
                actions: actions,
            }
            if (currentRole[0].roleId === ROLE_SUPER_ADMIN) {
                returnValue.checkbox = !lawDataRecord.isLite;
            }
            return {
                ...returnValue
            };
        });
        return actsRecords;
    };

    setComplianceForLite = async() => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('common.are_you_sure_compliances_lite')
        );
        if(response){
            this.props.setComplianceForLiteRequest({
                lawId: this.state.liteLawId,
                complianceId: this.state.liteBulkCompliance,
                isLite: 1,
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawSearchApiResponse &&
            this.props.lawSearchApiResponse !== nextprops.lawSearchApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                lawCompliance: false,
                sortBy: nextprops.lawSearchApiResponse.data.orderBy,
                pageCount: nextprops.lawSearchApiResponse.data.totalPages,
                currentPage: nextprops.lawSearchApiResponse.data.currentPage,
                totalRecords: nextprops.lawSearchApiResponse.data.totalRecords,
                searchLawList: this.generateTable(
                    nextprops.lawSearchApiResponse.data.laws
                ),
            });
        }
        if (
            nextprops.lawComplianceSearchApiResponse &&
            this.props.lawComplianceSearchApiResponse !==
            nextprops.lawComplianceSearchApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                lawCompliance: true,
                sortBy: nextprops.lawComplianceSearchApiResponse.data.orderBy,
                pageCount:
                    nextprops.lawComplianceSearchApiResponse.data.totalPages,
                currentPage:
                    nextprops.lawComplianceSearchApiResponse.data.currentPage,
                totalRecords:
                    nextprops.lawComplianceSearchApiResponse.data.totalRecords,
                searchLawList: this.generateTable2(
                    nextprops.lawComplianceSearchApiResponse.data.lawCompliances
                ),
                liteBulkCompliance: nextprops.liteBulkCompliance
            });
        }
        if (nextprops.setForLiteCompliance && nextprops.setForLiteCompliance !== this.props.setForLiteCompliance) {
            toastrMessage(nextprops.setForLiteCompliance.message, nextprops.setForLiteCompliance.responseType ? SUCCESS : DANGER)
            this.getLawComplianceSearchList(
                DEFAULT_PAGE_LENGTH,
                this.state.currentPage,
                {
                    key: 'createdAt',
                    value: 'DESC',
                },
                this.state.searchCompliancesFormData,
            );
        }
    }
    render() {
        const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)))
        const data = {
            columns: lawSearchListColumns,
            rows: this.state.searchLawList,
        };
        const data2 = {
            columns: lawComplianceSearchListColumns,
            rows: this.state.searchLawList,
        };
        return (
            <React.Fragment>
                <Row id={"TabelView"}>
                    <Col lg="12">
                        <CommonBreadcrum type={'statutory-compliances'} />
                    </Col>
                    <Col lg={12} className="viewlaws_tabs tabs-main fix-header-table">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab === 'lawsTab',
                                    })}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                searchFormData:
                                                    initialSearchDataState(),
                                            },
                                            () => {
                                                this.toggle('lawsTab');
                                            }
                                        );
                                    }}
                                >
                                    Laws
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            'compliancesTab',
                                    })}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                searchFormData:
                                                    initialSearchDataState(),
                                            },
                                            () => {
                                                this.toggle('compliancesTab');
                                            }
                                        );
                                    }}
                                >
                                    Compliances
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane className="laws_tab" tabId="lawsTab">
                                {this.state.lawCompliance === false ? (
                                    <Row>
                                        {!this.state.viewLawSection && (
                                            <Col lg="12">
                                                <CustomisedCollapseForOne
                                                    className="accordion"
                                                    viewDialog={false}
                                                    dialogName={__t(
                                                        'page_headers.advance_filters'
                                                    )}
                                                >
                                                    <CardBody className="px-4 pt-4 pb-2">
                                                        <Collapse
                                                            isOpen={
                                                                this.state
                                                                    .viewDialog
                                                            }
                                                        >
                                                            <SearchForm
                                                                searchFormData={
                                                                    this.state
                                                                        .searchFormData
                                                                }
                                                                onFormSubmit={(
                                                                    searchFormData
                                                                ) => {
                                                                    this.formSubmit(
                                                                        searchFormData
                                                                    );
                                                                }}
                                                                onResetForm={() => {
                                                                    this.resetForm();
                                                                }}
                                                            />
                                                        </Collapse>
                                                    </CardBody>
                                                </CustomisedCollapseForOne>
                                            </Col>
                                        )}
                                        {!this.state.viewLawSection &&
                                            !this.state.resetTableSection && (
                                                <Col lg="12">
                                                    <Card>
                                                        <CardBody className="custom_table table-responsive">
                                                            <CustomisedReactTableWithPagination
                                                                className="table table-bordered table-striped"
                                                                displayEntries={
                                                                    true
                                                                }
                                                                searching={true}
                                                                currentPage={
                                                                    this.state
                                                                        .currentPage
                                                                }
                                                                pageCount={
                                                                    this.state
                                                                        .pageCount
                                                                }
                                                                handleFetchData={
                                                                    this
                                                                        .handleFetchData
                                                                }
                                                                handleSort={
                                                                    this
                                                                        .handleSort
                                                                }
                                                                handleSearch={
                                                                    this
                                                                        .handleSearch
                                                                }
                                                                preFilledSearchText={
                                                                    this.state
                                                                        .searchFormData
                                                                        ?.searchText
                                                                }
                                                                tableData={data}
                                                                totalRecords={
                                                                    this.state
                                                                        .totalRecords
                                                                }
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )}
                                        {this.state.viewLawSection && (
                                            <ShowLaw
                                                lawId={this.state.lawId}
                                                onCloseViewLawDetails={() => {
                                                    this.closeViewLawSection();
                                                }}
                                            />
                                        )}
                                    </Row>
                                ) : (
                                    ''
                                )}
                            </TabPane>
                            <TabPane
                                className="laws_tab"
                                tabId="compliancesTab"
                            >
                                {this.state.lawCompliance === true ? (
                                    <Row>
                                        <Col lg="12">
                                            <CustomisedCollapseForOne
                                                className="accordion"
                                                viewDialog={false}
                                                dialogName={__t(
                                                    'page_headers.advance_filters'
                                                )}

                                            >
                                                <CardBody className="px-4 pt-4 pb-2">
                                                    <Collapse
                                                        isOpen={
                                                            this.state
                                                                .viewDialog
                                                        }
                                                    >
                                                        <ComplianceSearchForm
                                                            searchCompliancesFormData={
                                                                this.state
                                                                    .searchCompliancesFormData
                                                            }
                                                            onFormSubmit={(
                                                                searchFormData
                                                            ) => {
                                                                this.formSubmit(
                                                                    searchFormData
                                                                );
                                                            }}
                                                            onResetForm={() => {
                                                                this.resetForm();
                                                            }}
                                                        />
                                                    </Collapse>
                                                </CardBody>
                                            </CustomisedCollapseForOne>
                                        </Col>
                                        {!this.state.resetTableSection && (
                                            <Col lg="12">
                                                <Card>
                                                    <CardBody className="custom_table table-responsive table-top-action">
                                                        {/* <Col lg="12" className="mt-2 mb-3 text-right"> */}
                                                        {
                                                            currentRole[0].roleId === ROLE_SUPER_ADMIN ? <Button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    this.setComplianceForLite()
                                                                }}
                                                                style={{
                                                                    right: "12%",
                                                                    zIndex: 1
                                                                }}
                                                            >
                                                                Bulk Lite Compliance
                                                            </Button> : null
                                                        }

                                                        {/* </Col> */}
                                                        {
                                                            currentRole[0].roleId === ROLE_SUPER_ADMIN ? <CustomisedReactTableWithPaginationAndRowSelection
                                                                className="table table-bordered table-striped"
                                                                displayEntries={
                                                                    true
                                                                }
                                                                onchangeCheckbox={(row, checked) => {
                                                                    const getId = row && row.length > 0 && row.map((element) => {
                                                                        return element.complianceId
                                                                    })
                                                                    const LawId = row && row.length > 0 && row.map((element) => {
                                                                        return element.lawId
                                                                    })


                                                                    this.setState({
                                                                        liteLawId: LawId && LawId.length > 0 ? [...new Set(LawId)] : [],
                                                                        liteBulkCompliance: getId && getId.length > 0 ? getId : []
                                                                    })
                                                                }}
                                                                paging={true}
                                                                searching={true}
                                                                currentPage={
                                                                    this.state
                                                                        .currentPage
                                                                }
                                                                pageCount={
                                                                    this.state
                                                                        .pageCount
                                                                }
                                                                handleFetchData={
                                                                    this
                                                                        .handleFetchData
                                                                }
                                                                handleSort={
                                                                    this.handleSort
                                                                }
                                                                handleSearch={
                                                                    this
                                                                        .handleSearch
                                                                }
                                                                preFilledSearchText={
                                                                    this.state
                                                                        .searchCompliancesFormData
                                                                        ?.searchText
                                                                }
                                                                tableData={data2}
                                                                totalRecords={
                                                                    this.state
                                                                        .totalRecords
                                                                }
                                                            /> : <CustomisedReactTableWithPagination
                                                                className="table table-bordered table-striped"
                                                                displayEntries={
                                                                    true
                                                                }
                                                                searching={true}
                                                                currentPage={
                                                                    this.state
                                                                        .currentPage
                                                                }
                                                                pageCount={
                                                                    this.state
                                                                        .pageCount
                                                                }
                                                                handleFetchData={
                                                                    this
                                                                        .handleFetchData
                                                                }
                                                                handleSort={
                                                                    this.handleSort
                                                                }
                                                                handleSearch={
                                                                    this
                                                                        .handleSearch
                                                                }
                                                                preFilledSearchText={
                                                                    this.state
                                                                        .searchCompliancesFormData
                                                                        ?.searchText
                                                                }
                                                                tableData={data2}
                                                                totalRecords={
                                                                    this.state
                                                                        .totalRecords
                                                                } />
                                                        }

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                ) : (
                                    ''
                                )}
                            </TabPane>
                        </TabContent>

                        <AuditLogModal
                            exportBtn={true}
                            isModalOpen={this.state.isAuditModalOpen}
                            modalName={__t('page_headers.audit_trails')}
                            auditType={
                                this.state.lawCompliance === false
                                    ? 'law'
                                    : 'lawCompliance'
                            }
                            auditId={this.state.auditId}
                            onModalDismiss={() => this.closeAuditLogModal()}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    lawSearchApiResponse: makeLawSelectField('lawSearchApiResponse'),
    lawComplianceSearchApiResponse: makeLawComplianceSelectField(
        'lawComplianceSearchApiResponse'
    ),
    setForLiteCompliance: makeLawComplianceSelectField("setForLiteCompliance")
});

const withConnect = connect(mapStatetoProps, {
    getLawsSearchListRequest,
    getLawComplianceSearchRequest,
    setComplianceForLiteRequest
});
export default compose(
    withConnect,
    withLawReducer,
    withLawSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(Index);
