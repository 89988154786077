export const LITE_ACCOUNT_DETAILS_REQUEST = "lite-account-details-request";
export const LITE_ACCOUNT_DETAILS_RESPONSE = "lite-account-details-response";

export const LITE_ACCOUNT_DETAILS_ENTITY_REQUEST = "lite-account-details-entity-request";
export const LITE_ACCOUNT_DETAILS_ENTITY_RESPONSE = "lite-account-details-entity-response";

export const LITE_COUNTRY_LIST_WISE_ENTITY_REQUEST = "lite-country-list-wise-entity-request";
export const LITE_COUNTRY_LIST_WISE_ENTITY_RESPONSE = "lite-country-list-wise-entity-response";

export const LITE_ACCOUNT_ENTITY_NAME_UPDATE_REQUEST = "lite-account-entity-name-update-request";

export const LITE_ACCOUNT_ENTITY_NAME_UPDATE_RESPONSE = "lite-account-entity-name-update-response";

export const LITE_ENTITY_STATE_LIST_REQUEST = "lite-entity-state-list-request";

export const LITE_ENTITY_STATE_LIST_RESPONSE = "lite-entity-state-list-response";


export const LITE_ACCOUNT_UPDATE_REQUEST = "lite-account-update-request";

export const LITE_ACCOUNT_UPDATE_RESPONSE = "lite-account-update-response";

export const LITE_ENTITY_DETAILS_BY_ENTITY_ID_REQUEST = "lite-entity-details-by-entity-id-request";

export const LITE_ENTITY_DETAILS_BY_ENTITY_ID_RESPONSE = "lite-entity-details-by-entity-id-response";

export const LITE_ENTITY_UPDATE_BY_ID_REQUEST = "lite-entity-update-by-id-request";

export const LITE_ENTITY_UPDATE_BY_ID_RESPONSE = "lite-entity-update-by-id-response";

export const LITE_ENTITY_WISE_QUESTION_LIST_REQUEST = "lite-entity-wise-question-list-request";

export const LITE_ENTITY_WISE_QUESTION_LIST_RESPONSE = "lite-entity-wise-question-list-response";

export const LITE_SUSPEND_ACCOUNT_REQUEST = "lite-suspend-account-request";

export const LITE_SUSPEND_ACCOUNT_RESPONSE = "lite-suspend-account-response";