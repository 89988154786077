import React from 'react';
import { Card, CardHeader, Collapse } from 'reactstrap';

class StateAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDialog: this.props.viewDialog,
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle = () => {
        this.setState({
            viewDialog: !this.state.viewDialog,
        }, () => {
            if (this.props?.dataEvent) {
                this.props.toggleCollapse(this.props.dataEvent)
            }
        })
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.viewDialog !== this.props.viewDialog) {
            this.setState({
                viewDialog: nextprops.viewDialog
            })
        }
    }
    render() {
        return (
            <Card className='accordion-main'>
                <CardHeader className='p-0'>
                    <p
                        onClick={() =>
                            this.toggle()
                        }
                        className="page-header accordion_header pl-3 py-2 m-0"
                    >
                        {this.props?.headerComp}
                    </p>
                </CardHeader>
                <Collapse isOpen={this.state.viewDialog}>
                    {this.props.children}
                </Collapse>
            </Card>
        );
    }
}

export default StateAccordion;
