import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { CLIENT_REGISTRATION_LIST_REQUEST, CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST, CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST, LITE_ACTIVE_ENTITY_REQUEST, LITE_CITY_LIST_REQUEST, LITE_CLIENT_RESPONSE_QUESTION_REQUEST, LITE_COMPLIANCE_GENERATE_REQUEST, LITE_COMPLIANCE_LIST_REQUEST, LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST, LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST, LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST, LITE_ENTITY_ADD_SETUP_REQUEST, LITE_ENTITY_DETAILS_REQUEST, LITE_ENTITY_SETUP_STATE_CITY_REQUEST, LITE_ENTITY_UPDATE_REQUEST, LITE_MANAGEMENT_USER_LIST_REQUEST, LITE_QUESTION_ADD_REQUEST, LITE_QUESTION_INACTIVE_REQUEST, LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_REQUEST, LITE_QUESTION_LIST_REQUEST, LITE_QUESTION_RESET_REQUEST, LITE_QUESTION_SINGLE_REQUEST, LITE_QUESTION_UPDATE_REQUEST } from "./actionTypes";
import { addLiteEntityResponse, generateLiteComplianceResponse, getClientRegistrationResponse, getEntityDetailsResponse, getEntitySetupStateAndCityResponse, getListManagementUserListResponse, getLiteCityListResponse, getLiteComplianceListResponse, getLiteCountriesEntityResponse, getLiteCountriesListResponse, getLiteQuestionResponse, getQuestionMasterResponse, getSingleQuestionMasterResponse, postClientQuestionResponse, postInActiveLiteEntityResponse, postQuestionMasterResponse, putLiteEntityResponse, putQuestionMasterInactiveResponse, putQuestionMasterUpdateResponse, putResendEmailResponse, putStatusUpdateClientResponse, setResetQuestionnaireResponse } from "./actions";
import { handleApiCallException } from "store/Shared/actions";
import { invokeApi } from "helpers/axiosHelper";
import { LITE_CITY_API_URL, LITE_COMPLIANCE_API_URL, LITE_COMPLIANCE_LIST_API, LITE_COUNTRIES_LIST_API, LITE_ENTITY_ADD_API, LITE_ENTITY_ADD_SETUP_API, LITE_ENTITY_DETAILS_API, LITE_ENTITY_LIST_API, LITE_ENTITY_SETUP_STATE_CITY_API_URL, LITE_INACTIVE_LITE_ENTITY_API, LITE_MANAGEMENT_LIST_API, LITE_QUESTION_ADD_API, LITE_QUESTION_API, LITE_QUESTION_CLIENT_RESPONSE_API_URL, LITE_QUESTION_INACTIVE_API, LITE_QUESTION_LIST_API, LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_API_URL, LITE_QUESTION_RESET_API, REGISTER_CLIENT_LIST, RESEND_EMAIL_LITE, STATUS_UPDATE_CLIENT } from "helpers/apiConstants";
import { DANGER, SUCCESS } from "constants/commonConstants";

function* getClientRegistration({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', REGISTER_CLIENT_LIST, data);
        if (response.status) {
            yield put(getClientRegistrationResponse(response.data));
        } else {
            yield put(getClientRegistrationResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* putResendEmail({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${RESEND_EMAIL_LITE}/${data.user_id}/verification-type/${data.verification_type}`, data);
        if (response.status) {
            yield put(putResendEmailResponse(response));
        } else {
            yield put(putResendEmailResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* PUTStatusUpdateClient({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${STATUS_UPDATE_CLIENT}/${data.user_id}/status/${data.status}`, data);
        if (response.status) {
            yield put(putStatusUpdateClientResponse(response));
        } else {
            yield put(putStatusUpdateClientResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLiteCountriesList() {
    try {
        const response = yield call(invokeApi, 'get', LITE_COUNTRIES_LIST_API, {});
        if (response.status) {
            yield put(getLiteCountriesListResponse(response));
        } else {
            yield put(getLiteCountriesListResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLiteEntityList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_ENTITY_LIST_API}/${data.countryId}`, {});
        if (response.status) {
            yield put(getLiteCountriesEntityResponse(response.data));
        } else {
            yield put(getLiteCountriesEntityResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* postEntityCountry({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', LITE_ENTITY_ADD_API, data);
        if (response.status) {
            yield put(addLiteEntityResponse(response));
        } else {
            yield put(addLiteEntityResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* postInactiveLiteEntity({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_ENTITY_ADD_API}/${data.id}/status/${data.status}`, data);
        if (response.status) {
            yield put(postInActiveLiteEntityResponse(response));
        } else {
            yield put(postInActiveLiteEntityResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* getManagementUserApi({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_MANAGEMENT_LIST_API}/${data.countryId}`, {});
        if (response.status) {
            yield put(getListManagementUserListResponse(response.data));
        } else {
            yield put(getListManagementUserListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* postAddLiteEntitySetup({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_ENTITY_ADD_SETUP_API}/${data.entityId}`, data);
        if (response.status) {
            yield put(addLiteEntityResponse(response));
        } else {
            yield put(addLiteEntityResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEntityDetails({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_ENTITY_DETAILS_API}/${data.countryId}/configured-entity/${data.entitySetupId}`, {});
        if (response.status) {
            yield put(getEntityDetailsResponse(response));
        } else {
            yield put(getEntityDetailsResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* putEntityUpdateApi({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_ENTITY_ADD_API}/${data.entityId}/lite-entity-setup/${data.entitySetupId}`, data);
        if (response.status) {
            yield put(putLiteEntityResponse(response));
        } else {
            yield put(putLiteEntityResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLiteQuestionList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_QUESTION_LIST_API}`, data);
        if (response.status) {
            yield put(getQuestionMasterResponse(response.data));
        } else {
            yield put(getQuestionMasterResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* postQuestionAddApiCall({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_QUESTION_API}`, data);
        if (response.status) {
            yield put(postQuestionMasterResponse(response));
        } else {
            yield put(postQuestionMasterResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* putQuestionInactive({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_QUESTION_API}/${data.questionId}/status/${data.status}`, data);
        if (response.status) {
            yield put(putQuestionMasterInactiveResponse(response));
        } else {
            yield put(putQuestionMasterInactiveResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* putQuestionUpdate({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_QUESTION_API}/${data.id}`, data);
        if (response.status) {
            yield put(putQuestionMasterUpdateResponse(response));
        } else {
            yield put(putQuestionMasterUpdateResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getSingleQuestionDetails({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_QUESTION_API}/${data.question_id}`);
        if (response.status) {
            yield put(getSingleQuestionMasterResponse(response.data));
        } else {
            yield put(getSingleQuestionMasterResponse(response.data));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* setResetQuestionnaires({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', `${LITE_QUESTION_RESET_API}/${data.entityId}/${data.entity_setup_id}`);
        if (response.status) {
            yield put(setResetQuestionnaireResponse(response));
        } else {
            yield put(setResetQuestionnaireResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* postLiteComplianceList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_COMPLIANCE_LIST_API}`, data);
        if (response.status) {
            yield put(getLiteComplianceListResponse(response));
        } else {
            yield put(getLiteComplianceListResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* getLiteCityApiResponse({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_CITY_API_URL}`, data);
        if (response.status) {
            yield put(getLiteCityListResponse(response.data));
        } else {
            yield put(getLiteCityListResponse(response.data));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLiteQuestionListAsPerPracticeArea({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_API_URL}`, data);
        if (response.status) {
            yield put(getLiteQuestionResponse(response.data));
        } else {
            yield put(getLiteQuestionResponse(response.data));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* postLiteQuestionResponseApi({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_QUESTION_CLIENT_RESPONSE_API_URL}`, data);
        if (response.status) {
            yield put(postClientQuestionResponse(response));
        } else {
            yield put(postClientQuestionResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEntitySetupCityList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'get', `${LITE_ENTITY_SETUP_STATE_CITY_API_URL}/${data.entitySetupId}/${data.stateId}`);
        if (response.status) {
            yield put(getEntitySetupStateAndCityResponse(response.data));
        } else {
            yield put(getEntitySetupStateAndCityResponse(response.data));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* postComplianceGenerateApi({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', `${LITE_COMPLIANCE_API_URL}`, data);
        if (response.status) {
            yield put(generateLiteComplianceResponse(response));
        } else {
            yield put(generateLiteComplianceResponse(response));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchClientRegistration() {
    yield takeEvery(CLIENT_REGISTRATION_LIST_REQUEST, getClientRegistration);
    yield takeEvery(CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST, putResendEmail);
    yield takeEvery(CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST, PUTStatusUpdateClient);
    yield takeEvery(LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST, getLiteCountriesList);
    yield takeEvery(LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST, getLiteEntityList);
    yield takeEvery(LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST, postEntityCountry);
    yield takeEvery(LITE_MANAGEMENT_USER_LIST_REQUEST, getManagementUserApi);
    yield takeEvery(LITE_ACTIVE_ENTITY_REQUEST, postInactiveLiteEntity);
    yield takeEvery(LITE_ENTITY_ADD_SETUP_REQUEST, postAddLiteEntitySetup);
    yield takeEvery(LITE_ENTITY_DETAILS_REQUEST, getEntityDetails);
    yield takeEvery(LITE_ENTITY_UPDATE_REQUEST, putEntityUpdateApi);
    yield takeEvery(LITE_QUESTION_LIST_REQUEST, getLiteQuestionList);
    yield takeEvery(LITE_QUESTION_ADD_REQUEST, postQuestionAddApiCall);
    yield takeEvery(LITE_QUESTION_INACTIVE_REQUEST, putQuestionInactive);
    yield takeEvery(LITE_QUESTION_UPDATE_REQUEST, putQuestionUpdate);
    yield takeEvery(LITE_QUESTION_SINGLE_REQUEST, getSingleQuestionDetails);
    yield takeEvery(LITE_QUESTION_RESET_REQUEST, setResetQuestionnaires);
    yield takeEvery(LITE_COMPLIANCE_LIST_REQUEST, postLiteComplianceList);
    yield takeEvery(LITE_CITY_LIST_REQUEST, getLiteCityApiResponse);
    yield takeEvery(LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_REQUEST, getLiteQuestionListAsPerPracticeArea);
    yield takeEvery(LITE_CLIENT_RESPONSE_QUESTION_REQUEST, postLiteQuestionResponseApi);
    yield takeEvery(LITE_ENTITY_SETUP_STATE_CITY_REQUEST, getEntitySetupCityList);
    yield takeEvery(LITE_COMPLIANCE_GENERATE_REQUEST, postComplianceGenerateApi);

}
function* LiteRegistrationSaga() {
    yield all([fork(watchClientRegistration)]);
}
export default LiteRegistrationSaga;