import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getLiteCountriesListRequest } from 'store/Lite/actions';
import LiteRegistrationSaga from 'store/Lite/saga';
import { Link } from "react-router-dom";
import { makeLiteClientRegistrationSelectField } from 'store/Lite/selector';
import registeredAccountReducer from 'store/Lite/reducer';
import * as authConstants from 'constants/authConstants';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import { LITE_PLAN_PREMIUM, ROLE_COMPANY_GROUP_ADMIN } from 'constants/databaseConstants';
import SkeletonLoader from 'components/Layout/SkeletonLoader';
import __t from 'i18n/translator';

const countriesList = 'RegisteredAccount';
const withLiteCountriesListReducer = injectReducer({
    key: countriesList,
    reducer: registeredAccountReducer,
});
const withLiteCountriesListSaga = injectSaga({ key: countriesList, saga: LiteRegistrationSaga });
class LiteCountryListModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            noOfEntityPerCountry: 0
        }
    }
    componentDidMount() {
        const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)));
        const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
        if (isLite.isLite == 1) {
            if (currentRole[0].roleId !== ROLE_COMPANY_GROUP_ADMIN) {
                window.location.pathname = '/home';
            }
        } else {
            window.location.pathname = '/home';
        }
        this.props.getLiteCountriesListRequest();
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.liteCountriesList?.data && nextprops.liteCountriesList?.data !== this.props.liteCountriesList?.data) {
            this.setState({
                countryList: nextprops.liteCountriesList?.data.list,
                noOfEntityPerCountry: nextprops.liteCountriesList?.data?.noOfEntityPerCountry
            })
        }
    }
    render() {
        const currentRole = getValueByKey(authConstants.USER_ROLES) ? JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES))) : null;
        const IsLitePlan = getValueByKey(authConstants.LITE_PLAN) && getValueByKey(authConstants.LITE_PLAN) !== "null" ? JSON.parse(decryptData(getValueByKey(authConstants.LITE_PLAN))) : null;
        if (!currentRole) {
            window.location.pathname = '/logout';
        } else {
            if (!IsLitePlan) {
                window.location.pathname = '/logout';
            } else {
                if (currentRole[0].roleId !== ROLE_COMPANY_GROUP_ADMIN) {
                    window.location.pathname = '/home';
                }
            }
        }
        return <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'countries-list'} />
                    </Col>
                </Row>
                <div className="page-header">
                    <h5 className="with-back-btn mt-0 d-flex">
                        <Link
                            to={"/home"}
                            className="dripicons-arrow-thin-left"
                        // onClick={() => {
                        //     this.props.history.push('/home');
                        // }}
                        >
                            { }
                        </Link>{' '}
                        {__t("lite.countries")}
                    </h5>
                </div>
                <ListGroup horizontal className='country_list'>
                    {
                        this.state?.countryList && this.state?.countryList?.length > 0 ? this.state?.countryList.map((element, index) => {
                            return <ListGroupItem className='p-0 text-center' key={index}>
                                <Link to={`/countries-list/${element.id}`} className="p-3 px-4 d-inline-block">
                                    <img src={`/data/flags/${element.isoCode2}.svg`} alt={element.countryName} />
                                    <h5 className='my-2 font-weight-normal text-primary'>{element.countryName}</h5>
                                    <p className='m-0'><span>{element?.entityCount ?? 0}/{this.state?.noOfEntityPerCountry ?? 0}</span> {__t("lite.entities")}</p>
                                </Link>
                            </ListGroupItem>
                        }) : <SkeletonLoader width={260} height={190} containerClassName={"skeletonFlex"} />
                    }
                    {/* <ListGroupItem className='p-0 text-center'>
                        <Link to="/countries-list/entity/11" className="p-3 px-4 d-inline-block">
                            <h5 className='my-2 font-weight-normal text-primary'>Spain</h5>
                            <p className='m-0'><span>5/5</span> Entities</p>
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem className='p-0 text-center'>
                        <Link to="/countries-list/entity/11" className="p-3 px-4 d-inline-block">
                            <h5 className='my-2 font-weight-normal text-primary'>Spain</h5>
                            <p className='m-0'><span>5/5</span> Entities</p>
                        </Link>
                    </ListGroupItem> */}
                    {
                      this.state?.countryList && this.state?.countryList?.length > 0 &&  IsLitePlan?.litePlan?.id == LITE_PLAN_PREMIUM ? <ListGroupItem className='p-0 text-center'>
                            <div className="p-3 px-4 w-100 h-100" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <h5 className='my-2 font-weight-normal text-primary'>Coming Soon</h5>
                            </div>
                        </ListGroupItem> : null
                    }
                </ListGroup>
            </Container>
        </>
    }
}

const mapStatetoProps = createStructuredSelector({
    liteCountriesList: makeLiteClientRegistrationSelectField("liteCountriesList")
});
const withConnect = connect(mapStatetoProps, {
    getLiteCountriesListRequest
});
export default compose(
    withConnect,
    withLiteCountriesListReducer,
    withLiteCountriesListSaga,
    withRouter
)(LiteCountryListModule);