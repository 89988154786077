import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


class SkeletonLoader extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
            <p className="d-flex">
                <Skeleton {...this.props} count={3} />
            </p>
        </SkeletonTheme> // Simple, single-line loading skeleton
    }
}
export default SkeletonLoader;