import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react'
import { Button, Card, CardBody, CardHeader, FormGroup, Label, Row } from 'reactstrap';
import Select from "react-select";
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { ACTIVE, CLEAR, SEARCH } from 'constants/commonConstants';
import __t from 'i18n/translator';
import { Option, animatedComponents, MultiValue } from 'helpers/projectUtils';

class QuestionnairesFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedStateListDropdown: [],
            sortedCityListDropdown: [],
            sortedSubIndustryListDropdown: [],
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.sortedStateListDropdown &&
            this.props.sortedStateListDropdown !==
            nextprops.sortedStateListDropdown
        ) {
            this.setState({ sortedStateListDropdown: nextprops.sortedStateListDropdown });
        }
        if (
            nextprops.sortedCityListDropdown &&
            this.props.sortedCityListDropdown !==
            nextprops.sortedCityListDropdown
        ) {
            this.setState({ sortedCityListDropdown: nextprops.sortedCityListDropdown });
        }
        if (
            nextprops.sortedSubIndustryListDropdown &&
            this.props.sortedSubIndustryListDropdown !==
            nextprops.sortedSubIndustryListDropdown
        ) {
            this.setState({ sortedSubIndustryListDropdown: nextprops.sortedSubIndustryListDropdown });
        }
    }

    getStateListByCountry(countryId) {
        this.props.getStateListByCountryRequest(countryId);
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest(stateId);
    }
    getSubIndustryListByIndustry(industryId) {
        this.props.getSubIndustryListByIndustryRequest(industryId);
    }
    render() {
        return <Formik
            initialValues={{
                countryId: [],
                stateId: [],
                cityId: [],
                industryId: [],
                subIndustryId: [],
                lawCategoryId: [],
                facilityTypeId: [],
                entityTypeId: []
            }}
            onSubmit={this.props.FilterFormSubmit}
            onReset={this.props.handleReset}
        >
            {({ values, setFieldValue }) => (
                <Form className="px-4 pt-4 pb-2">
                    <Row className="filter-form">
                        <React.Fragment>
                            <FormGroup className="col-lg-4">
                                <Field
                                    component={Select}
                                    name="countryId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedCountryListDropdown
                                    }
                                    styles={{
                                        menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                    onChange={(opt) => {
                                        let selectedValues = opt
                                            ? opt.map((x) => x.value)
                                            : [];
                                        setFieldValue(
                                            'countryId',
                                            selectedValues
                                        );
                                        setFieldValue('stateId', []);
                                        setFieldValue('cityId', []);
                                        this.getStateListByCountry(
                                            selectedValues
                                        );
                                    }}
                                    value={this.props.sortedCountryListDropdown?.filter(
                                        (option) =>
                                            values.countryId?.includes(
                                                option.value
                                            )
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                />
                                <Label className="form-label">
                                    {__t(
                                        'form_labels.allocation.country'
                                    )}{' '}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <Field
                                    component={CustomMultiSelect}
                                    name="stateId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.state
                                            .sortedStateListDropdown.filter((opt) => opt.value !== -1)
                                    }
                                    onChange={(opt) => {
                                        let selectedValues = opt
                                            ? opt.map((x) => x.value)
                                            : [];
                                        setFieldValue(
                                            'stateId',
                                            selectedValues
                                        );
                                        setFieldValue('cityId', []);
                                        if (selectedValues.length > 0) {
                                            this.getCityListByState(
                                                selectedValues
                                            );
                                        } else {
                                            this.setState({
                                                sortedCityListDropdown: []
                                            })
                                        }
                                    }}
                                    value={this.state.sortedStateListDropdown?.filter(
                                        (option) =>
                                            values.stateId?.includes(
                                                option.value
                                            )
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{
                                        Option,
                                        MultiValue,
                                        animatedComponents,
                                    }}
                                    key="stateId"
                                />
                                <Label className="form-label">
                                    {__t(
                                        'form_labels.allocation.state'
                                    )}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <Field
                                    component={CustomMultiSelect}
                                    name="cityId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.state
                                            .sortedCityListDropdown.filter((opt) => opt.value !== -1)
                                    }
                                    onChange={(opt) => {
                                        let selectedValues = opt
                                            ? opt.map((x) => x.value)
                                            : [];
                                        setFieldValue(
                                            'cityId',
                                            selectedValues
                                        );
                                    }}
                                    value={this.state.sortedCityListDropdown?.filter(
                                        (option) =>
                                            values.cityId?.includes(
                                                option.value
                                            )
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{
                                        Option,
                                        MultiValue,
                                        animatedComponents,
                                    }}
                                    key="cityId"
                                />
                                <Label className="form-label">
                                    {__t('form_labels.allocation.city')}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <Field
                                    component={Select}
                                    name="industryId"
                                    className="form-control selectbox"
                                    options={
                                        this.props
                                            .sortedIndustryListDropdown?.filter((opt) => opt.value !== -1)
                                    }
                                    onChange={(opt) => {
                                        let industry = opt
                                            ? opt.map((x) => x.value)
                                            : [];
                                        setFieldValue(
                                            'industryId',
                                            industry
                                        );
                                        setFieldValue(
                                            'subIndustryId',
                                            []
                                        );
                                        if (industry.length > 0) {
                                            this.props.getSubIndustryListByIndustryRequest(
                                                industry
                                            );
                                        } else {
                                            this.setState({
                                                sortedSubIndustryListDropdown: []
                                            })
                                        }
                                    }}
                                    value={this.props.sortedIndustryListDropdown?.filter(
                                        (option) =>
                                            values.industryId.includes(option.value)
                                    )}
                                    isClearable={true}
                                    isMulti
                                    closeMenuOnSelect={false}
                                />
                                <Label className="form-label">
                                    {__t('form_labels.statutory.industry')}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <Field
                                    component={Select}
                                    name="subIndustryId"
                                    className="form-control selectbox"
                                    options={
                                        this.state
                                            .sortedSubIndustryListDropdown
                                    }
                                    onChange={(opt) => {
                                        let subIndustry = opt
                                            ? opt.map((x) => x.value)
                                            : [];
                                        setFieldValue(
                                            'subIndustryId',
                                            subIndustry
                                        );
                                    }}
                                    value={this.state.sortedSubIndustryListDropdown?.filter(
                                        (option) =>
                                            values.subIndustryId.includes(option.value)
                                    )}
                                    isClearable={true}
                                    isMulti
                                    closeMenuOnSelect={false}
                                />
                                <Label className="form-label">
                                    {__t('form_labels.statutory.sub_industry')}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <Field
                                    component={CustomMultiSelect}
                                    name="lawCategoryId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.props
                                            .sortedLawCategoryListDropdown
                                    }
                                    onChange={(opt) => {
                                        let selectedValues = opt
                                            ? opt.map((x) => x.value)
                                            : [];
                                        setFieldValue(
                                            'lawCategoryId',
                                            selectedValues
                                        );
                                    }}
                                    value={this.props.sortedLawCategoryListDropdown?.filter(
                                        (option) =>
                                            values.lawCategoryId?.includes(
                                                option.value
                                            )
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    components={{
                                        Option,
                                        MultiValue,
                                        animatedComponents,
                                    }}
                                    key="lawCategoryId"
                                />
                                <Label className="form-label">
                                    {__t(
                                        'form_labels.allocation.law_category'
                                    )}{' '}
                                </Label>
                            </FormGroup>
                            <FormGroup className='col-lg-4'>
                                <Field
                                    component={Select}
                                    name="facilityTypeId[]"
                                    className="form-control selectbox"
                                    options={
                                        this.props.sortedFacilityTypeListDropDown?.filter((opt) => opt.value !== -1)
                                    }
                                    onChange={(opt) => {
                                        let selectedValues = opt
                                            ? opt.map((x) => x.value)
                                            : [];
                                        setFieldValue(
                                            'facilityTypeId',
                                            selectedValues
                                        );
                                    }}
                                    value={this.props.sortedFacilityTypeListDropDown?.filter(
                                        option => values.facilityTypeId?.includes(option.value)
                                    )}
                                    isMulti
                                    closeMenuOnSelect={false}
                                />
                                <Label className="form-label">
                                    {__t(
                                        'form_labels.laws.facility_types'
                                    )}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <Field
                                    component={
                                        CustomMultiSelect
                                    }
                                    name="entityTypeId[]"
                                    className="form-control selectbox"
                                    options={this.props.sortedEntityTypeListDropDown.filter((opt) => opt.value !== -1)}
                                    onChange={(opt) => {
                                        let entityTypeId =
                                            opt
                                                ? opt.map(
                                                    (x) =>
                                                        x.value
                                                )
                                                : [];
                                        setFieldValue(
                                            'entityTypeId',
                                            entityTypeId
                                        );
                                    }}
                                    value={this.props.sortedEntityTypeListDropDown?.filter(
                                        (option) =>
                                            values.entityTypeId?.includes(
                                                option.value
                                            )
                                    )}
                                    isMulti
                                    closeMenuOnSelect={
                                        false
                                    }
                                    hideSelectedOptions={
                                        false
                                    }
                                    allowSelectAll={true}
                                    components={{
                                        Option,
                                        MultiValue,
                                        animatedComponents,
                                    }}
                                />
                                <Label className="form-label">
                                    {__t(
                                        'form_labels.laws.entity_types'
                                    )}{' '}
                                </Label>
                            </FormGroup>
                            <FormGroup className="col-lg-4 mb-0 d-flex justify-content-end align-items-center">
                                <div className="d-inline-block">
                                    <Button
                                        type="reset"
                                        color="secondary"
                                        className="mx-1"
                                        onClick={this.resetForm}
                                    >
                                        {CLEAR}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className='mx-1'
                                    >
                                        {SEARCH}
                                    </Button>
                                </div>
                            </FormGroup>
                        </React.Fragment>
                    </Row>
                </Form>
            )}
        </Formik>
    }
}

export default QuestionnairesFilter;